<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 134px)">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                { titulo: 'Estadísticas', ruta: 'admin.nomina.estadisticas' },
                { titulo: 'Empresas Asociadas', ruta: 'admin.nomina.empresas-asociadas' },
                { titulo: 'Liquidaciones', ruta: 'admin.nomina.liquidaciones', can: 'tab_nomina_liquidaciones' }
            ]
        }
    }
}
</script>

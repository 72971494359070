import axios from 'axios'

const API = 'nomina/solicitudes-afiliacion'

const ENDPOINTS = {
    getSolicitudesAfiliados(id, params){
        return axios.get(`${API}/${id}/listado`, {params})
    },
    putAceptarSolicitud(params){
        return axios.post(`${API}/aceptar`,params)
    },
    putRechazarSolicitud(id){
        return axios.put(`${API}/${id}/rechazar`)
    },
}

export default ENDPOINTS
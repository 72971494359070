<template>
    <section class="px-4 pt-3">
        <div class="row mx-0 bg-white br-8 py-2 px-2 mb-3">
            <div class="col-12 px-0 d-middle">
                <i class="icon-back f-20 text-general cr-pointer" @click="$router.back()" />
                <img :src="datosAfiliado.avatar != null ? datosAfiliado.avatar : '/img/no-imagen/sin_user.png'" class="rounded-circle obj-cover ml-3" width="56" height="56" />
                <div class="col-2 pl-2">
                    <p class="text-general f-18">
                        {{ _.get(datosAfiliado, 'nombre_cliente') }}
                    </p>
                    <div class="row mx-0 mt-1 align-items-end lh-18">
                        <div class="col-auto px-0 text-general f-16">
                            Código: {{ $route.params.id_cliente }}
                        </div>
                        <div class="col-auto px-0 ml-auto">
                            <span class="f-600 text-general f-18">{{ _.get(datosAfiliado, 'calificacion', '0') }}</span>
                            <i class="icon-star f-20 text-orange" />
                        </div>
                    </div>
                </div>
                <div class="col-2" />
                <div class="col-auto">
                    <p class="text-general f-14"> Deuda </p>
                    <div class="d-middle text-general">
                        <i class="icon-wallet f-17" />
                        <span class="f-16 f-600 ml-2">
                            {{ convertMoney(_.get(datosAfiliado, 'deuda', 0)) }}
                        </span>
                    </div>
                </div>
                <div class="col-auto">
                    <p class="text-general f-14"> Cupo </p>
                    <div class="d-middle text-general">
                        <i class="icon-cash f-14 mr-2" />
                        <span class="f-16 f-600 ml-2">
                            {{ datosAfiliado.cupo != 0 ? convertMoney(_.get(datosAfiliado, 'cupo', '0')) : 'Ilimitado' }}
                        </span>
                    </div>
                </div>
                <div class="col-auto">
                    <p class="text-general f-14"> Salario </p>
                    <div class="d-middle text-general">
                        <i class="icon-cash f-14 mr-2" />
                        <span class="f-16 f-600 ml-2">
                            {{ convertMoney(_.get(datosAfiliado, 'salario', '0')) }}
                        </span>
                    </div>
                </div>
                <div class="col-auto ml-auto px-0">
                    <el-popover
                    placement="bottom"
                    trigger="click"
                    width="124"
                    popper-class="p-0 br-none"
                    >
                        <div v-if="$can('botones_nomina_editar_desvincular_afiliado') || $usuario.liquidador" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="editarAfiliado()">
                            Editar
                        </div>
                        <div v-if="$can('botones_nomina_editar_desvincular_afiliado') || $usuario.liquidador" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="desvincular">
                            Desvincular
                        </div>
                        <i slot="reference" class="icon-dots-vertical f-20 text-general cr-pointer" />
                    </el-popover>
                </div>
            </div>
            <div class="col-12 px-0 d-middle mt-3">
                <div class="col-auto px-2" />
                <img :src="_.get(datosAfiliado, 'logo', '/img/no-imagen/no-imagen-empresa.png')" class="br-4 border obj-cover ml-4" width="56" height="56" />
                <div class="col-2 pl-2  ">
                    <p class="text-general f-16 f-500">
                        {{ _.get(datosAfiliado, 'convenio_nombre') }}
                    </p>
                    <div class="row mx-0 mt-1 align-items-end lh-18">
                        <i class="icon-account-check text-green f-18 mr-2" />
                        <div class="col-auto px-0 text-general f-16">
                            {{ estadosAfiliacion }}
                        </div>
                    </div>
                </div>
                <div class="col-2" />
                <div class="col-auto">
                    <p class="text-general f-14"> Cliente desde </p>
                    <div class="d-middle text-general">
                        <i class="icon-calendar f-17" />
                        <span class="f-16 f-600 ml-2">
                            {{ formatearFecha(datosAfiliado.cliente_desde) }}
                        </span>
                    </div>
                </div>
                <div class="col-auto">
                    <p class="text-general f-14"> Documento </p>
                    <div class="d-middle text-general">
                        <i class="icon-identification f-14 mr-2" />
                        <span class="f-16 f-600 ml-2">
                            {{ _.get(datosAfiliado, 'cedula', '-') }}
                        </span>
                    </div>
                </div>
                <div v-if="datosAfiliado.documento" class="button-cetificado px-3 d-middle-center cr-pointer" @click="mostrarCertificado">
                    <i class="icon-documento f-18" />
                    <span class="f-14 ml-1">
                        {{ _.get(datosAfiliado, 'documento_label', '-') }}
                    </span>
                </div>
            </div>
        </div>
        <div class="row mx-0 bg-white br-t-8">
            <div class="col px-3 custom-scroll overflow-auto" style="height:calc(100vh - 235px)">
                <panel-left :estadistica="estadistica" />
            </div>
            <div class="col px-0 border-left">
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 235px)">
                    <panel-right />
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-certificado ref="modalCertificado" :titulo="_.get(datosAfiliado, 'documento_label', '-')" :img="datosAfiliado.documento" />
        <modal-desvincular-afiliado
        ref="modalDesvincular"
        :avatar="datosAfiliado.avatar"
        :nombre="datosAfiliado.nombre_cliente"
        @updated="init()"
        />
        <modal-editar-afiliado ref="modalEditarAfiliado" @updated="detalleAfiliadoData()" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import estadistica from '~/services/clientes'
import detalleAfiliado from '~/services/nomina/detalleAfiliado'


export default {
    components: {
        panelLeft: () => import('../components/panelLeft'),
        panelRight: () => import('../components/panelRight'),
        modalCertificado: () => import('../partials/modalCertificado'),
        modalDesvincularAfiliado: () => import('../partials/modalDesvincularAfiliado'),
        modalEditarAfiliado: () => import('../partials/modalEditarAfiliado.vue')
    },
    data(){
        return {
            loading: true,
            estadistica: {},
            datosAfiliado: {}
        }
    },

    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
        estadosAfiliacion(){
            switch (this.datosAfiliado.estado){
            case 1:
            case 4:
                return 'En espera'
            case 2:
            case 3:
                return 'Afiliado'
            case 5:
            case 6:
                return 'Desvinculado'

            default:
                return 'En espera'
            }
        },
        idConvenio(){
            return Number(this.$route.params.id_convenio)
        },
        idAfiliado(){
            return Number(this.$route.params.id_afiliado)
        },

    },
    watch:{
        id_moneda(val, oldVal){
            if(oldVal == null) return
            setTimeout(async() => {
                await this.init()
            }, 500);

        }
    },
    mounted(){
        setTimeout(async() => {
            await this.init()
        }, 500);

    },
    methods: {
        async init(){
            await this.detalleAfiliadoData()
            await this.fetchData()
        },
        editarAfiliado(){
            const params = {
                avatar: this.datosAfiliado.avatar,
                id: this.datosAfiliado.id_nomina,
                documento: this.datosAfiliado.cedula,
                fecha: this.datosAfiliado.fecha_vinculacion,
                cupo: this.datosAfiliado.cupo,
                salario: this.datosAfiliado.salario,
                nombre_cliente: this.datosAfiliado.nombre_cliente,
                documentoLabel: this.datosAfiliado.documento_label,
                archivo: this.datosAfiliado.documento,

            }
            this.$refs.modalEditarAfiliado.toggle(params);
        },
        mostrarCertificado(){
            if(!this.datosAfiliado.documento) return
            if(this.datosAfiliado.documento_tipo == 2){
                window.open(this.datosAfiliado.documento, '_blank')
            } else {
                this.$refs.modalCertificado.toggle();
            }
        },
        desvincular(){
            this.$refs.modalDesvincular.toggle();
        },
        async detalleAfiliadoData(){
            try {
                let id = this.$route.params.id_cliente

                const { data } = await detalleAfiliado.header(id);
                console.log(data);
                this.datosAfiliado = data.data
            } catch (error){
                this.error_catch(error)
            }
        },

        async fetchData(){
            try {
                this.loading = true
                const params = {
                    id: this.$route.params.id_cliente,
                    moneda: this.id_moneda
                }
                const { data } = await estadistica.estadistica(params)
                this.estadistica = data
                // this.compras_pedidos.dataset.source = data.semanas
            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false
            }
        },
        countAcceso(tipo = 1){
            if(_.isEmpty(this.estadistica.userAccesos)) return 0
            let {count = 0} = this.estadistica.userAccesos.find(el => el.tipo == tipo) ?? {}
            return count

        },
        percent(count){
            return (count * 100) / this.maxAccesos
        },

    }
}
</script>

<style lang="scss" scoped>
.button-cetificado{
    height: 36px;
    border-radius: 8px;
    border: 1px solid #DFE4E8;
    color: #000000;
}

.item-option{
    height: 32px;
    border-radius: 4px;
    color: 13px;
    &:hover{
        background-color: #F6F9FB;
    }
}
</style>

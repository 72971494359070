import ServicioApi from '~/services/faqs/faqs';
import moment from 'moment';

export default {
    data(){
        return {
            datos: {
                faqs: []
            }
        }
    },
    computed: {
        anclas(){
            return this.datos.faqs.map(x => x.titulo);
        },
        diferenciaMeses(){
            return this.obtenerDiferenciaFecha(this.datos.actualizado)(true)
        },
        diferenciaDias(){
            return this.obtenerDiferenciaFecha(this.datos.actualizado)(false)
        },
        hayFechaActualizacion(){
            return !_.isNaN(this.diferenciaMeses) && !_.isNaN(this.diferenciaDias)
        },
        tiempoActualizacion(){
            return this.diferenciaMeses > 0
                ? `hace ${this.obtenerPluralSingular(this.diferenciaMeses)('mes')('es')}`
                : this.diferenciaDias > 0 ? `hace ${this.obtenerPluralSingular(this.diferenciaDias)('día')('s')}` : 'hoy'
        },
    },
    methods: {
        async consultarFaqs(payload){
            try {
                const { data } = await ServicioApi.consultarFaqs(payload);
                this.datos.faqs = (payload.tipo === 1)
                    ? this.mapearPreguntasFrecuentes(data.data)
                    : data.data.faqs;
            } catch (error){
                this.error_catch(error);
            }
        },
        regresarLogin(){
            this.$router.push({
                name: 'login.form.admin'
            });
        },
        mapearPreguntasFrecuentes: array => array.map(x => ({
            ...x,
            editando: false,
            preguntas: x.preguntas.map(p => ({ ...p, colapsado: true }))
        })),
        obtenerDiferenciaFecha: fecha => tipoMes => moment().diff(fecha, tipoMes ? 'month' : 'days'),
        obtenerPluralSingular: contador => singular => (sufijo = 's') => `${contador} ${singular}${contador !== 1 ? sufijo : ''}`,
        esElPrimero: (array, item) => array.findIndex(x => x === item) === 0,
        colapsar: item => { item.colapsado = !item.colapsado },
        colocarIconoColapsado: estaColapsado => estaColapsado ? 'icon-angle-down' : 'icon-angle-up',
        textoEsCoincidente: x => busqueda => x.toLowerCase().trim().includes(busqueda.toLowerCase().trim()),

    }
}

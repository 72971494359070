<template>
    <section class="mx-3 mt-2 border" style="border-radius:12px 12px 0px 0px;height:calc(100vh - 66px); position:relative;">
        <cargando v-if="loading" />

        <div class="row align-items-center mx-0 border-radius py-3 px-3 border-bottom">
            <div class="rounded-circle bg-general3 d-middle-center" style="width:25px;height:25px;">
                <i class="icon-left f-18 text-white cr-pointer" @click="$router.push({ name: 'admin.clientes-estadisticas.clientes' })" />
            </div>
            <img :src="_.get(estadistica, 'cliente.avatar', '')" class="obj-cover border rounded-circle ml-2" width="36" height="36" />
            <div class="col-auto px-0 tres-puntos text-general f-18 f-600 mx-2">
                {{ _.get(estadistica, 'cliente.nombre', '') }}
            </div>
            <div class="col text-center tres-puntos f-18 text-general">
                <b>Tipo:</b> {{ _.get(estadistica, 'cliente.tipo_nombre', 'No registra') }}
            </div>
            <div class="col text-center tres-puntos f-18 text-general">
                <b>Usuario desde:</b> {{ formatearFecha(_.get(estadistica, 'cliente.created_at')) }}
            </div>
            <div class="col text-right px-0 text-general2">
                {{ _.get(estadistica, 'cliente.calificacion', '0.0') }} <i class="icon-star text-orange f-20" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-6 custom-scroll overflow-auto border-right px-3 pt-3" style="height:calc(100vh - 138px);">
                <div class="row mx-0 bg-light-f5 border br-8 py-2">
                    <div class="col-auto text-center">
                        <img src="/img/modales/pedidos.svg" width="40px" height="40" class="obj-cover" />
                        <p class="mt-1">Pedidos</p>
                    </div>
                    <div class="col pl-0">
                        <div class="row mx-0">
                            <p class="col text-general f-17 f-600">
                                {{ separadorNumero(_.get(estadistica, 'primerPedido.valor_final', '0.0')) }}
                            </p>
                            <p class="col text-general f-17 f-600">
                                {{ separadorNumero(_.get(estadistica, 'ultimoPedido.valor_final', '0.0')) }}
                            </p>
                            <p class="col text-general f-17 f-600">
                                {{ separadorNumero(_.get(estadistica, 'comprasTotales', '0.0')) }}
                            </p>
                        </div>
                        <div class="row mx-0 border-bottom py-2">
                            <p class="col text-general f-14">
                                <span class="">{{ formatearFecha(_.get(estadistica, 'primerPedido.entrega_fecha')) }}</span>
                                <span class="text-general2"> 1er. pedido</span>
                            </p>
                            <p class="col text-general f-14 ">
                                <span class="">{{ formatearFecha(_.get(estadistica, 'ultimoPedido.entrega_fecha')) }}</span>
                                <span class="text-general2 ml-2">Últ. pedido</span>
                            </p>
                            <p class="col text-general f-14">
                                Compras totales
                            </p>
                        </div>
                        <div class="row mx-0 py-2">
                            <div class="col-auto d-middle">
                                <i class="icon-leechero-cap f-18 text-general" />
                                <span class="mx-2 text-general">{{ _.get(estadistica, 'tienda.propietario_nombre', 'No registra') }}</span>
                                <el-tooltip v-if="_.get(estadistica, 'cantidadTienda', 0) > 1" class="item" effect="light" content="Cantidad de leecheros asociados" placement="bottom">
                                    <div class="bg-general3 text-white rounded-circle f-13 p-1">
                                        {{ _.get(estadistica, 'cantidadTienda', 0) }}
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="col-auto d-middle">
                                <i class="icon-cedis f-18 text-general" />
                                <span class="mx-2 text-general">{{ _.get(estadistica, 'tienda.cedis_nombre', 'No registra') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-2" />
                <div class="row mx-0 bg-light-f5 border br-8 py-2">
                    <div class="col-auto text-center">
                        <img src="/img/modales/duplicar_cupon.svg" width="28" height="28" alt="" />
                        <p class="text-general2 f-14 py-1 pt-2">Cupones</p>
                    </div>
                    <div class="col-3">
                        <p class="text-general f-17 f-600 py-1">{{ agregarSeparadoresNumero(_.get(estadistica, 'cuponesCount', 0)) }}</p>
                        <p class="text-general f-13 pt-1">Aplicados</p>
                    </div>
                    <div class="col-3">
                        <p class="text-general f-17 f-600 py-1">{{ agregarSeparadoresNumero(_.get(estadistica, 'cuponesDisponibles', 0)) }}</p>
                        <p class="text-general f-13 pt-1">Disponibles</p>
                    </div>
                    <div class="col-3">
                        <p class="text-general f-17 f-600 py-1">{{ separadorNumero(_.get(estadistica, 'cuponesSum', 0)) }}</p>
                        <p class="text-general f-13 pt-1">Compras con cupón</p>
                    </div>
                </div>
                <div class="row mx-0 my-4" />
                <div class="row mx-0">
                    <div class="col text-general f-17 f-600">
                        Interacciones por plataforma
                    </div>
                    <div class="col f-15 text-general text-right">
                        Último ingreso: {{ formatearFecha(_.get(estadistica, 'ultimoAcceso')) }}
                    </div>
                </div>
                <div class="row px-3 mx-0 br-6 mt-2" style="height:30px;">
                    <div class="h-100 bg-general3 br-6" :style="`width:${porcentajesBordes(percent(countAcceso(1)), 1)}%`" />
                    <div class="h-100 bg-gris br-r-6" :style="`width:${porcentajesBordes(percent(countAcceso(2)), 1)}%`" />
                    <div class="h-100 bg-light-f5 br-r-6" :style="`width:${porcentajesBordes(percent(countAcceso(3)), 1)}%`" />
                </div>
                <div class="row mx-0 my-2" />
                <div class="row mx-0">
                    <div class="col px-2 d-flex">
                        <div class="bg-general3 br-10" style="width:5px;height:55px;" />
                        <div class="border w-100 br-r-4">
                            <div class="row mx-0 align-items-center">
                                <i class="icon-web f-22 text-general f-18" />
                                <p class="col text-general f-15 f-600">
                                    Web {{ Math.round(percent(countAcceso(1))) }}%
                                </p>
                            </div>
                            <div class="row mx-0">
                                <div class="col-auto" />
                                <div class="col text-general text-muted3 f-14">
                                    {{ agregarSeparadoresNumero(countAcceso(1)) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col px-2 d-flex">
                        <div class="bg-general2 br-10" style="width:5px;height:55px;" />
                        <div class="border w-100 br-r-4">
                            <div class="row mx-0 align-items-center">
                                <i class="icon-android f-22 text-general f-18" />
                                <p class="col text-general f-15 f-600">
                                    Android {{ Math.round(percent(countAcceso(2))) }}%
                                </p>
                            </div>
                            <div class="row mx-0">
                                <div class="col-auto" />
                                <div class="col text-general text-muted3 f-14">
                                    {{ agregarSeparadoresNumero(countAcceso(2)) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col px-2 d-flex">
                        <div class="bg-light-f5 br-10" style="width:5px;height:55px;" />
                        <div class="border w-100 br-r-4">
                            <div class="row mx-0 align-items-center">
                                <i class="icon-apple f-22 text-general f-18" />
                                <p class="col text-general f-15 f-600">
                                    IOS {{ Math.round(percent(countAcceso(3))) }}%
                                </p>
                            </div>
                            <div class="row mx-0">
                                <div class="col-auto" />
                                <div class="col text-general text-muted3 f-14">
                                    {{ agregarSeparadoresNumero(countAcceso(3)) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-4" />
                <div class="row mx-0">
                    <div class="col-12 text-general f-17 f-600">
                        Compras y pedidos de las últimas 20 semanas
                    </div>
                    <div class="col-12 mt-2">
                        <echart :options="compras_pedidos" />
                    </div>
                </div>
            </div>
            <div class="col px-0">
                <div class="row mx-0 border-bottom">
                    <router-link :to="{name: 'admin.clientes.ver.pedidos-realizados'}" :class="`col py-2 text-center ${$route.name == 'admin.clientes.ver.pedidos-realizados' ? 'border-b-red text-general-red' : 'text-general2'}`">
                        Pedidos realizados
                    </router-link>
                    <router-link :to="{name: 'admin.clientes.ver.productos-comprados'}" :class="`col py-2 text-center ${$route.name == 'admin.clientes.ver.productos-comprados' ? 'border-b-red text-general-red' : 'text-general2'}`">
                        Productos comprados
                    </router-link>
                </div>
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import echarts from 'echarts'
Vue.component('echarts',echarts);

import estadistica from '~/services/clientes'

export default {
    data(){
        return{
            compras_pedidos: {
                legend: {
                    top: 20,
                    itemGap: 15,
                    data: ['Ventas', 'Pedidos'],
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    padding: 10,
                },
                dataset:{
                    dimensions:['semana', 'cantidad', 'valor_final'],
                    source: []
                },
                xAxis: {
                    axisLabel: {
                        show: false,
                        margin: 20,
                        color: '#595959',
                        fontSize: 14,
                    },
                    type: 'category',
                    nameLocation: 'center',
                    nameGap: 50,
                    axisLine: {
                        lineStyle: {
                            type: 'dashed',
                            color: '#ECECEC',
                        },
                    },
                },
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#222',
                            fontSize: 14,
                        },
                        axisLabel: {
                            margin: 20,
                            color: '#595959',
                            fontSize: 14,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#ECECEC',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        type: 'value',
                        minInterval: 1,
                        nameGap: 20,
                    },
                    {
                        nameTextStyle: {
                            color: '#222',
                            fontSize: 14,
                        },
                        axisLabel: {
                            margin: 20,
                            color: '#595959',
                            fontSize: 14,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#ECECEC',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        type: 'value',
                        minInterval: 1,
                        nameGap: 20,
                    },
                ],
                series: [
                    {
                        type: 'line',
                        name: 'Pedidos',
                        yAxisIndex: 0,
                        symbol: 'emptyCircle',
                        smooth: 0.3,
                        symbolSize: 8,
                        itemStyle: {
                            borderWidth: 2,
                            color: 'red',
                        },
                        emphasis: {
                            itemStyle: {
                                color: 'red',
                                borderColor: '#fff',
                            },
                        },
                    },
                    {
                        type: 'bar',
                        name: 'Ventas',
                        yAxisIndex: 1,
                        showBackground: true,
                        barWidth: '20%',
                        itemStyle: {
                            color: '#DBDBDB',
                        },
                    },
                ],
            },
            estadistica: {},
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
        maxAccesos(){
            if(_.isEmpty(this.estadistica.userAccesos)) return 0
            let {count: count1 = 0} = this.estadistica.userAccesos.find(el => el.tipo == 1) ?? {}
            let {count: count2 = 0} = this.estadistica.userAccesos.find(el => el.tipo == 2) ?? {}
            let {count: count3 = 0} = this.estadistica.userAccesos.find(el => el.tipo == 3) ?? {}
            return count1 + count2 + count3
        }
    },
    watch:{
        id_moneda(val, oldVal){
            if(oldVal == null) return
            setTimeout(() => {
                this.fetchData()
            }, 500);
        }
    },
    mounted(){
        setTimeout(() => {
            this.fetchData()
        }, 500);
    },
    methods: {
        async fetchData(){
            try {
                this.loading = true
                const params = {
                    id: this.$route.params.id_cliente,
                    moneda: this.id_moneda
                }
                const { data } = await estadistica.estadistica(params)
                this.estadistica = data
                this.compras_pedidos.dataset.source = data.semanas
            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false
            }
        },
        countAcceso(tipo = 1){
            if(_.isEmpty(this.estadistica.userAccesos)) return 0
            let {count = 0} = this.estadistica.userAccesos.find(el => el.tipo == tipo) ?? {}
            return count
            
        },
        percent(count){
            return (count * 100) / this.maxAccesos
        }
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
    &:hover{
        color: var(--color-general) !important;
    }
}
</style>
<template>
    <section>
        <p class="text-general f-400 mb-5">
            Define la cantidad de monedas y gemas que recibirá cada usuario que se registre en la aplicación.
        </p>
        <div v-for="(cedi,index) in datos" :key="index" class="row mx-0 mb-3">
            <div class="col-4 px-0 f-15 text-general f-500 pt-4">
                {{ cedi.nombre }}
            </div>
            <div class="col px-0">
                <div class="row mx-0 mb-3 align-items-end">
                    <div class="px-0 pb-1 text-general2 f-500" style="width:100px;">
                        {{$config.cliente}}
                    </div>
                    <div class="col-auto text-general pb-1 pr-0">
                        <img src="/img/gaming/i_moneda.svg" />
                    </div>
                    <div class="col">
                        <p class="label-mini">
                            Monedas
                        </p>
                        <el-input v-model="cedi.recompensas.cliente_monedas" placeholder="Cantidad" class="w-100" size="small" @change="actualizarConversor(cedi.id,cedi.recompensas.cliente_monedas,1)" />
                    </div>
                    <div class="col-auto text-general pb-1 pr-0">
                        <img src="/img/gaming/i_gema.svg" />
                    </div>
                    <div class="col">
                        <p class="label-mini">
                            Gemas
                        </p>
                        <el-input v-model="cedi.recompensas.cliente_gemas" placeholder="Cantidad" class="w-100" size="small" @change="actualizarConversor(cedi.id,cedi.recompensas.cliente_gemas,2)" />
                    </div>
                </div>
                <div class="row mx-0 mb-3 align-items-end">
                    <div class="px-0 pb-1 text-general2 f-500" style="width:100px;">
                        {{$config.vendedor}}
                    </div>
                    <div class="col-auto text-general pb-1 pr-0">
                        <img src="/img/gaming/i_moneda.svg" />
                    </div>
                    <div class="col">
                        <p class="label-mini">
                            Monedas
                        </p>
                        <el-input v-model="cedi.recompensas.tienda_monedas" placeholder="Cantidad" class="w-100" size="small" @change="actualizarConversor(cedi.id,cedi.recompensas.tienda_monedas,3)" />
                    </div>
                    <div class="col-auto text-general pb-1 pr-0">
                        <img src="/img/gaming/i_gema.svg" />
                    </div>
                    <div class="col">
                        <p class="label-mini">
                            Gemas
                        </p>
                        <el-input v-model="cedi.recompensas.tienda_gemas" placeholder="Cantidad" class="w-100" size="small" @change="actualizarConversor(cedi.id,cedi.recompensas.tienda_gemas,4)" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import GamingRecompensas from '~/services/gaming/gamingrecompensas'

export default {
    data(){
        return {
            datos: [
                {   cedi: '',
                    recompensas:{
                        cliente_monedas:0,
                        cliente_gemas:0,
                        tiendas_monedas:0,
                        tiendas_gemas:0
                    }},
            ],
        }
    },
    mounted(){
        this.listarCedisRecompensas()
    },
    methods: {
        async listarCedisRecompensas(){
            try {
                let params = {
                    tipo: 21
                }
                const { data } = await GamingRecompensas.listarCedisRecompensas({params})
                this.datos = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarConversor(idCedis,valor,campo){
            try {
                if(valor>=65535) return this.notificacion("","El valor debe ser igual o menor a 65.535")
                let variable
                if(campo == 1) variable = "cliente_monedas"
                if(campo == 2) variable = "cliente_gemas"
                if(campo == 3) variable = "tienda_monedas"
                if(campo == 4) variable = "tienda_gemas"
                let params = {
                    id_cedis: idCedis,
                    valor: valor,
                    campo: variable,
                    tipo: 21
                }
                const { data } = await GamingRecompensas.actualizarRecompensa(params)
                this.notificacion("","Recompensa actualizada","success")
                params = null
            } catch (e){
                this.error_catch(e)
            }
        },
        manejador(event){
            console.log({event});
        }
    }
}
</script>
<style lang="scss" scoped>
.label-mini{
    font-size: 12px;
    padding-left: 15px;
    color: #000000;
}
</style>
<template>
    <section class="px-4 pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 53px)">
        <div class="row mx-0 mb-3 align-items-center">
            <i class="icon-back f-20 text-general cr-pointer" @click="$router.back()" />
            <div class="col-auto px-2 text-general f-18">
                Liquidación de afiliados
            </div>
            <div class="col-auto ml-3 text-gris2 f-18">
                Última liquidación: {{ ultLiquidacion | helper-fecha('DD MMMM YYYY') }}
            </div>
        </div>
        <div class="row mx-0 mb-4">
            <div class="col px-2">
                <p class="text-general f-20 f-600 mb-3">
                    Selecciona la fecha hasta cuando se liquidará
                </p>
                <div class="row mx-0 mt-2 align-items-end">
                    <div class="col-6 pl-0">
                        <p class="text-general f-12 pl-3">
                            Fecha
                        </p>
                        <el-date-picker
                        v-model="fechaLiquidar"
                        type="date"
                        size="small"
                        class="w-100"
                        placeholder="Seleccione Fecha"
                        format="dd MMMM yyyy"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        />
                    </div>
                    <div class="btn-general f-14 px-4" @click="getLiquidacionesPendientes">
                        Buscar
                    </div>
                </div>
            </div>
            <div class="col pr-0">
                <div class="bg-white h-100 br-8">
                    <div class="row mx-0 align-items-center h-100">
                        <div class="col-auto">
                            <p class="text-general f-14">
                                Deudores
                            </p>
                            <div class="d-middle text-general">
                                <i class="icon-cliente f-20" />
                                <span class="f-600 f-22 ml-2">
                                    {{ totalDeudores }}
                                </span>
                            </div>
                        </div>
                        <div class="col-auto">
                            <p class="text-general f-14">
                                Pedidos
                            </p>
                            <div class="d-middle text-general">
                                <i class="icon-pedidos f-20" />
                                <span class="f-600 f-22 ml-2">
                                    {{ totalPedidos }}
                                </span>
                            </div>
                        </div>
                        <div class="col-auto">
                            <p class="text-general f-14">
                                Deuda
                            </p>
                            <div class="d-middle text-general">
                                <i class="icon-pedidos f-20" />
                                <span class="f-600 f-22 ml-2">
                                    {{ convertMoney(totalDeuda) }}
                                </span>
                            </div>
                        </div>
                        <div v-if="$can('botones_nomina_liquidar_afiliar') || $usuario.liquidador" class="btn-general px-4 f-14 mt-2 ml-4" @click="liquidar">
                            Liquidar
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 bg-white br-t-8 mb-4">
            <div class="col-12 px-0 py-4">
                <div class="row mx-0 align-items-center">
                    <div class="col-auto text-general lh-18 f-18 f-500 ml-4">
                        Afiliados con deuda
                    </div>
                    <div class="col-auto d-middle lh-18 text-general ml-auto">
                        <el-checkbox v-model="confirmados" class="check-green mr-2" @change="getLiquidacionesPendientes" />
                        Confirmados <br /> por delivery
                    </div>
                    <div class="col-auto d-middle lh-18 text-general">
                        <el-checkbox v-model="noConfirmados" class="check-green mr-2" @change="getLiquidacionesPendientes" />
                        No confirmados <br /> por delivery
                    </div>
                    <div class="col-3">
                        <el-input v-model="search" placeholder="Buscar" class="w-100 br-20" size="small" />
                    </div>
                </div>
            </div>
            <div class="col-12 px-0">
                <cargando v-if="loading" />
                <el-table
                id="afiliadosActivos"
                ref="tablaLiquidar"
                :data="usuarios"
                height="calc(100vh - 225px)"
                style="width: 100%"
                header-row-class-name="text-general f-500 f-16"
                @selection-change="selection"
                >
                    <el-table-column type="selection" width="55" class-name="check-green" />
                    <el-table-column width="50">
                        <template slot-scope="{row}">
                            <div v-show="!row.estado && !row.en_convenio" class="row mx-0 justify-center">
                                <el-tooltip content="No esta afiliado a la empresa, pero tiene deuda pendiente" placement="bottom" effect="light">
                                    <i class="icon-account-remove-outline text-general-red f-18" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id_user" label="Código" class-name="text-center" width="100" />
                    <el-table-column prop="nombre" label="Nombre" class-name="text-center" width="180" />
                    <el-table-column prop="documento" label="Documento" class-name="text-center" min-width="160" />
                    <el-table-column prop="deuda" label="Deuda" class-name="text-center">
                        <template slot-scope="{row}">
                            <p class="text-general f-14">
                                {{ convertMoney(row.deuda) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cant_pedidos" label="Pedidos" class-name="text-center" />
                    <el-table-column label="Cupo" class-name="text-center">
                        <template slot-scope="{row}">
                            <p v-if="!row.en_convenio" class="text-general f-14">
                                --
                            </p>
                            <p v-else-if="row.cupo === 0" class="text-general f-14">
                                Ilimitado
                            </p>
                            <p v-else class="text-general f-14">
                                {{ convertMoney(row.cupo) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Antiguedad" min-width="160">
                        <template slot-scope="{row}">
                            <p v-if="!row.en_convenio" class="text-general f-14">
                                --
                            </p>
                            <p v-else class="text-general f-14">
                                {{ row.fecha_vinculacion | helper-fecha('DD MMM YYYY') }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column label="Fecha Registro" min-width="160">
                        <template slot-scope="{row}">
                            <p class="text-general f-14">
                                {{ row.fecha_registro | helper-fecha('DD MMM YYYY') }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="80">
                        <template slot="header">
                            <i class="icon-truck-check f-18 text-green text-center" />
                        </template>
                        <template slot-scope="{row}">
                            <p>{{ row.entregados }}% </p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <modalLiquidar ref="modalLiquidar" @update="getLiquidacionesPendientes" />
    </section>
</template>

<script>
import Nomina from '~/services/nomina/afiliados'
import moment from 'moment'
export default {
    components:{
        modalLiquidar: () => import('../partials/modalConfirmarLiquidacion'),
    },
    data(){
        return {
            confirmados: true,
            noConfirmados: true,
            search: '',

            totalDeudores:0,
            totalPedidos:0,
            totalDeuda:0,
            ultLiquidacion:'',


            fechaLiquidar:moment().format('YYYY-MM-DD'),
            usuarios:[],
            backUsuarios:[],
            loading:false,
            pedidosLiquidar:[]
        }
    },
    computed:{
        idConvenio(){
            return Number(this.$route.params.id_convenio)
        },
        confirmacionDelivery(){
            if(this.confirmados && this.noConfirmados) return 3
            if(this.confirmados) return 1
            if(this.noConfirmados) return 2
            return 3
        },
        // listado(){
        //     return this.usuarios.filter(data => !this.search || data.nombre.toLowerCase().includes(this.search.toLowerCase()))
        // }
    },
    watch:{
        search(val){

            const filtrar = () => {
                this.usuarios = this.backUsuarios.filter(
                    data => !val || data.nombre.toLowerCase().includes(val.toLowerCase())
                || String(data.id_user).toLowerCase().includes(val.toLowerCase())
                || String(data.documento).toLowerCase().includes(val.toLowerCase())
                )
            }
            this.delay(filtrar)

        }
    },
    methods: {
        async getLiquidacionesPendientes(){
            try {
                this.loading = true
                const params = {
                    fecha:this.fechaLiquidar,
                    confirmacion: this.confirmacionDelivery,
                }

                const {data} = await Nomina.getLiquidacionesPendientes(this.idConvenio, params)
                this.usuarios = data.usuarios
                this.backUsuarios = _.cloneDeep(data.usuarios)
                this.ultLiquidacion = data.ult_liquidacion

                this.$refs.tablaLiquidar.toggleAllSelection	();


            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        liquidar(){
            const params = {
                deudores: this.totalDeudores,
                deuda: this.totalDeuda,
                fechaCorte:this.fechaLiquidar,
                pedidos:this.pedidosLiquidar,
                tipoLiquidacion: this.confirmacionDelivery
            }
            this.$refs.modalLiquidar.toggle(params)
        },
        selection(rows){

            this.totalDeuda = _.sumBy(rows,'deuda')
            this.totalDeudores = rows.length
            this.totalPedidos = _.sumBy(rows,'cant_pedidos')
            this.pedidosLiquidar = rows.reduce((acc,el)=>{
                acc = [...acc, ...el.pedidos_liquidar]
                return acc
            },[])

        }
    }
}
</script>
<style lang="scss" scoped>
.btn-desvinculados{
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    position: absolute;
    background-color: #FF1720;
    bottom: 20px;
    right: 31px;
    z-index: 10;
}

.btn-activos{
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    position: absolute;
    background-color: #29D884;
    bottom: 71px;
    z-index: 10;
    right: 31px;
}
</style>

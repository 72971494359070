<template>
    <section class="pedidos-especiales mt-3">
        <div class="row mx-0 bg-white br-t-12 border-bottom">
            <div class="col-4">
                <div class="row mx-0 py-2 align-items-center" style="height:45px;">
                    <div class="col-xl-8 col-lg-6 col-md-5 col-sm-5 col-5 px-2">
                        <el-input v-model="buscarPedido" placeholder="Buscar Pedido" class="w-100 br-20" size="small" @input="filtro=1" />
                    </div>
                    <el-checkbox v-model="filtroLeechero" class="check-dark">
                        <i class="icon-leechero-cap f-15" />
                    </el-checkbox>
                    <el-checkbox v-model="filtroCliente" class="check-dark">
                        <i class="icon-cliente f-15" />
                    </el-checkbox>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0 align-items-center" style="height:45px">
                    <p class="col-3 px-0">
                        <span class="text-general f-500 f-18">ID. {{ activo.id }}</span>
                        <span class="text-general2 ml-2 f-17">
                            {{ estadoPedido(activo.estado) }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="row mx-0 bg-white">
            <div class="col-4 px-0">
                <div class="custom-scroll overflow-auto pt-4 border-right" style="height:calc(100vh - 193px)">
                    <div class="mb-2">
                        <div v-for="(pedido,index) in listado" :key="index" class="row cr-pointer mx-0 px-3 my-3 pl-3 pb-2 mb-3 border-bottom" @click="handlerIdPedido(pedido)">
                            <div :class="`col-auto br-20 px-1 ${activo.id == pedido.id ? 'bg-general': ''} `" />
                            <img :src="pedido.imagen" class="br-4 ml-2" width="96" height="96" />
                            <div class="col px-2">
                                <div class="row mx-0 text-general">
                                    <p class="col px-0 f-500">
                                        Canje No. {{ pedido.id }}
                                    </p>
                                    <i :class="`icon-${pedido.tipo_user == 2 ? 'leechero-cap' : 'cliente' } f-18`" />
                                    <p class="col-12 px-0 tres-puntos">
                                        {{ pedido.nombre_producto }}
                                    </p>
                                </div>
                                <div v-if="pedido.monedas" class="row mx-0 align-items-center">
                                    <img src="/img/gaming/i_moneda.svg" height="28" />
                                    <div class="col px-2 text-general f-600">
                                        {{ pedido.monedas }}
                                    </div>
                                </div>
                                <div v-if="pedido.gemas" class="row mx-0 align-items-center">
                                    <img src="/img/gaming/i_gema.svg" height="28" />
                                    <div class="col px-2 text-general f-600">
                                        {{ pedido.gemas }}
                                    </div>
                                </div>
                                <div v-if="pedido.diario_dia" class="row mx-0 align-items-center">
                                    <img src="/img/gaming/i_ruleta.svg" height="28" />
                                    <span class="text-general2">Ruleta día </span>
                                    <div class="col px-2 text-general f-600">
                                        {{ pedido.diario_dia }}
                                    </div>
                                </div>
                                <div class="row mx-0 mt-1">
                                    <i :class="`${pedido.estado == 4 ? 'icon-shopping text-green' : 'icon-cancel-circled-outline f-17 text-general2'} `" />
                                    <span :class="`col px-2 ${pedido.estado == 4 ? 'text-green f-15' : 'text-general2 f-15' }`">
                                        {{ estadoPedido(pedido.estado) }}
                                    </span>
                                    <span class="col px-2 text-general2 f-15">
                                        {{ formatearFecha(pedido.updated_at) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="listado.length" class="col-12 bg-white br-b-8 pb-3 d-flex justify-center" @click="getGamingPedidos(pedidos.length,true)">
                        <span class="bg-general3 text-white br-12 px-2 cr-pointer mt-2" :style="`${totalLength ? '' : 'pointer-events:none;opacity:0.5;'}` ">Ver más +50</span>
                    </div>
                </div>
            </div>
            <panel-detalle-pedido v-if="!isEmpty(activo)" :pedido="detalle" class="border-right" />
            <comentarios v-if="!isEmpty(activo)" :comentarios="comentarios" @update="getComentariosPedido(activo.id)" />
            <div v-else class="col text-center f-15 f-600 d-middle-center flex-column">
                <div class="d-middle-center bg-whitesmoke br-5" style="width: 115px; height: 115px;">
                    <img src="/img/no-imagen/click.svg" height="80" />
                </div>
                <p class="text-general2" style="width:200px"> Selecciona uno de los pedidos activos del panel de la izquierda. </p>
            </div>
        </div>
    </section>
</template>
<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import GamingPedidos from '@/services/gaming/gamingPedidos'
export default {
    components: {
        panelDetallePedido: () => import('./components/detallePedido.vue'),
        comentarios: () => import('./components/comentarios.vue')
    },
    data(){
        return {
            totalLength: null,
            buscarPedido: '',
            filtroCliente: null,
            filtroLeechero: null,
            pedidos: [],
            activo: {},
            detalle: {
                estados: [],
                user: {},
                responsable: {}
            },
            comentarios: [],
            lastIdCedis: null
        }
    },
    computed: {
        listado(){
            if(this.buscarPedido) return this.pedidos.filter(o => !this.buscarPedido || (String(o.id).toLowerCase().includes(this.buscarPedido.toLowerCase())))
            if(this.filtroCliente && !this.filtroLeechero) return this.pedidos.filter(el=>el.tipo_user==5)
            if(this.filtroLeechero && !this.filtroCliente ) return  this.pedidos.filter(el=>el.tipo_user==2)
            if(this.filtroCliente && this.filtroLeechero){
                return this.pedidos
            }
            else{
                return this.pedidos
            }
        },
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        })
    },
    watch: {
        async id_cedis(val){
            if(this.lastIdCedis!==this.id_cedis) await this.getGamingPedidos()
            this.lastIdCedis = this.id_cedis
            
        },
    },
    mounted(){
        this.getGamingPedidos()
    },
    methods: {
        async getGamingPedidos(len=this.pedidos.length,verMas=false){
            try {

                this.activo = {}

                const { data } = await GamingPedidos.getGamingPedidosHistorial(len)

                this.totalLength = data.data.length
                // si hay ver más o se lista normal

                if(verMas){ 
                    this.pedidos = [...this.pedidos,...data.data]
                }else {
                    this.pedidos = data.data
                }

                // miramos si hay un # de pedido enrutado

                if(this.$route.params.id_gaming_pedido){
                    let pedido = ((this.pedidos.sin_confirmar.find(el=>el.id==this.$route.params.id_gaming_pedido)) || (this.pedidos.confirmados.find(el=>el.id==this.$route.params.id_gaming_pedido)))
                    if(pedido)  this.handlerIdPedido(pedido)
                    
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        estadoPedido(estado){
            switch (estado){
            case 4:
                return "Entregado"
            case 21: 
                return "Cancelado"
            default:
                return "-"
            }
        },
        async handlerIdPedido(pedido){
            this.activo = pedido
            await this.getDetallePedido(pedido.id)
            await this.getComentariosPedido(pedido.id)
        },
        async getDetallePedido(idPedido){
            try {
                const { data } = await GamingPedidos.getGamingPedido(idPedido)
                this.detalle = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async getComentariosPedido(idPedido){
            try {
                const { data } = await GamingPedidos.getComentariosPedido(idPedido)
                this.comentarios = {
                    data: data.data,
                    id_pedido: idPedido
                } 
                
            } catch (e){
                this.error_catch(e)
            }
        },
        isEmpty(obj){
            return Object.keys(obj).length === 0;
        }
        
    }
}
</script>
<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom mt-3" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                { titulo: 'Cliente', ruta:   'admin.gaming.usuarios.cliente' },
                { titulo: 'Vendedores', ruta: 'admin.gaming.usuarios.leecheros' }
            ]
        }
    },
    mounted(){
        this.rutas[0].titulo = this.$config.cliente
        this.rutas[1].titulo = this.$config.vendedor
    }
}
</script>

<template>
    <section class="pt-2" style="height:calc(100vh - 146px)">
        <div class="row mx-0 h-100">
            <div class="menu-prinicipal border-r-gris" style="height:calc(100% - 5px);">
                <div class="row mx-0 border-b-gris" style="height:56px;">
                    <div class="col-12 my-auto">
                        <el-input v-model="word" size="small" clearable placeholder="Buscar pedido o delivery" class="br-20" />
                    </div>
                </div>
                <div class="col px-0 overflow-auto custom-scroll" style="width:400px; height: calc(100% - 59px);">
                    <content-loader v-if="loading" />
                    <div
                    v-for="(sop, idx) in novedades" :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    style="height:70px;"
                    @click="activarCar(sop)"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${id_pedido==sop.id?'bg-general':''}`" />
                        <img class="border bg-whitesmoke rounded-circle" width="48" height="48" :src="sop.delivery.foto" alt="" />
                        <div class="col-9 f-15">
                            <div class="row mx-0 f-600 align-items-center">
                                <p class="tres-puntos">ID {{ sop.id }}</p>
                                <p class="tres-puntos ml-auto">{{ formatearFecha(sop.hora_ult_chat,'D MMM HH:mmA') }}</p>
                            </div>
                            <div class="row mx-0">
                                <el-tooltip v-if="sop.ult_mensaje" placement="bottom" :content="sop.ult_mensaje" effect="light">
                                    <p class="tres-puntos">{{ sop.ult_mensaje }}</p>
                                </el-tooltip>
                                <p v-else>Sin último mensaje</p>
                            </div>
                            <div class="row mx-0">
                                <p class="tres-puntos col-6 f-600 px-1">{{ sop.delivery.nombre }}</p>
                                <p class="tres-puntos col-6 px-1"> {{ formatearFecha(sop.fecha_entrega,'D MMM HH:mmA') }}</p>
                            </div>
                        </div>
                    </div>
                    <infinite-loading ref="infiniteLoading" @infinite="listarNovedades">
                        <div slot="spinner">
                            <div v-loading="true">
                                Cargando...
                            </div>
                        </div>
                        <div slot="no-more" class="text-muted">
                            <span v-if="false">No hay más novedades</span>
                        </div>
                        <div slot="no-results" class="text-muted">
                            No se encuentran novedades
                        </div>
                    </infinite-loading>
                    <div v-if="novedades.length <= 0" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/question.svg" alt="" />
                            </div>
                            <p>Aún no has recibido novedades de deliverys.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 col br-tr-8 menu-prinicipal" style="width: calc(100vw - 560px);height:calc(100% - 2px);">
                <!-- <ver-novedades-delivery v-show="id_pedido!== null" :delivery="delivery" @actualizar="listarNovedades" /> -->
                <ver-novedades-delivery v-show="id_pedido!== null" :delivery="delivery" @actualizar="listarNovedades" />
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Novedades from '~/services/soporte/novedades'
import InfiniteLoading from 'vue-infinite-loading'
export default {
    sockets: {
        pedidos(obj){
            this.Novedades()
        },
    },
    middleware:['auth'],
    metaInfo: () =>({ title: 'Pedidos'}),
    components: {
        verNovedadesDelivery: () => import('./verNovedadesDelivery'),
        InfiniteLoading
    },
    data(){
        return {
            loading: false,
            novedades: [],
            delivery: {
                id: null,
                hora_ult_chat: null,
                fecha_entrega: null,
                delivery: {
                    nombre: '',
                    foto: '',
                }
            },
            word: null,
            page:1,
            cantNovedades:0,
        }
    },
    computed:{
        ...mapGetters({
            pedido: 'pedidos/pedidos_admin/pedido', // solo para actualizar icono chat
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            cant_novedades: 'soporte/novedades/cant_novedades',
            id_cedis:'cedis/id_cedis'
        }),
        rol(){
            return this.$usuario.rol_nombre
        },
    },
    watch:{
        id_cedis(val,oldVal){
            if(val != null){
                if(oldVal == null) return
                this.novedades = []
                this.page = 1
                this.$store.commit('soporte/novedades/set_cant_novedades', 0)
                this.sincronizar()
            }
        }
    },
    mounted(){
        this.$store.commit('soporte/novedades/set_cant_novedades', 0)
    },
    methods: {
        async sincronizar(){
            console.log('simco');
            let that = this
            this.page = 1
            if(this.id_cedis === null){
                setTimeout(function(){
                    that.listarNovedades()
                }, 500);
            }else{
                that.listarNovedades()
            }
        },
        async listarNovedades($state= false){
            try {
                const state = $state
                const params = {
                    page: this.page,
                    id_cedis: this.id_cedis,
                    id_user: this.user,
                    word: this.word // solo para chat de pedidos
                }
                this.loading = true
                const {data} = await Novedades.listarNovedades(params)
                this.$store.commit('soporte/novedades/set_cant_novedades', this.cant_novedades + data.cant_msg_total)
                this.page += 1
                let temp = _.union(this.novedades, data.novedades.data, 'id')
                let uniqBy = _.uniqBy(temp, 'id')
                this.novedades = uniqBy
                if (data.novedades.data.length > 0){
                    if(state){
                        state.loaded()
                    }
                } else {
                    if(state){
                        state.complete()
                    }
                }

            } catch (e){
                this.error_catch(e)
            }finally{
                this.loading = false
            }
        },
        async activarCar(delivery){
            this.delivery = delivery
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', delivery.id)
        },
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{
    background: #fff;
}
.bg-blue{
    background: #0D4DFF;
}
.icono{
    padding-left: 5px;
    padding-right: 5px;
    i{
        font-size: 20px;
        position: relative;
    }
}
</style>

<template>
    <section class="px-4 pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 63px)">
        <cargando v-if="cargando" />
        <ValidationObserver ref="validacion">
            <div class="row mx-0 align-items-center mb-3">
                <i class="icon-back f-20 text-general cr-pointer" @click="irAtras" />
                <div class="col-auto text-general f-18">
                    Editar empresa
                </div>
                <div class="btn-general f-14 px-5 ml-auto ml-auto" @click="putEmpresa">
                    Guardar
                </div>
            </div>
            <div class="row mx-0 bg-white br-8 py-4 px-3">
                <p class="col-12 text-general f-18 mb-3">
                    Datos de la empresa
                </p>
                <div class="col-auto px-4" />
                <div class="col-auto px-2">
                    <slim-cropper ref="imgEmpresa" :options="slimOptions" class="border cr-pointer br-12 slim-styles" />
                    <p class="text-gris2 mt-2 f-14 text-center">
                        Logo
                    </p>
                </div>
                <div class="col px-3">
                    <div class="row mx-0">
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Nombre de la empresa
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Nombre de la empresa">
                                <el-input v-model="nombre" class="w-100" size="small" maxlength="40" placeholder="Nombre" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Nit
                            </p>
                            <el-input v-model="nit" class="w-100" size="small" maxlength="15" placeholder="Nit" />
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Pais
                            </p>
                            <el-select v-model="idPais" placeholder="Seleccionar" class="w-100" size="small" :clearable="true" @clear="limpiarPais">
                                <el-option
                                v-for="item in paises"
                                :key="item.id"
                                :label="item.pais"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Ciudad
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Ciudad">
                                <el-select 
                                v-model="idCiudad"
                                filterable
                                remote
                                :remote-method="getCiudades"
                                :loading="loading"
                                placeholder="Seleccionar"
                                class="w-100"
                                size="small"
                                :clearable="true"
                                @clear="limpiarCiudad"
                                >
                                    <el-option
                                    v-for="item in ciudades"
                                    :key="item.id"
                                    :label="item.ciudad + ' ('+item.estado+')'"
                                    :value="item.id"
                                    />
                                </el-select>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-4 bg-white br-8 py-4 px-3">
                <p class="col-12 text-general f-18 mb-3">
                    Asociación con leeche
                </p>
                <div class="col-auto px-3" />
                <div class="col">
                    <div class="row mx-0 mb-3">
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Código de afiliación de clientes
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Código">
                                <el-input v-model="codigoAfiliacion" class="w-100" size="small" maxlength="15" placeholder="Código" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-6">
                            <p class="text-general pl-3 f-12">
                                Mensaje de bienvenida para las solicitudes de afiliación
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Mensaje">
                                <el-input v-model="mensajeBienvenida" type="textarea" :rows="3" class="w-100" size="small" maxlength="500" show-word-limit placeholder="Mensaje" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mb-3">
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Cupo
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Cupo">
                                <el-input v-model="porcentajeCupo" class="w-100 soloNumeros" size="small" maxlength="3" placeholder="%" @focus="soloNumeros" @input="validarInputCupo" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-4 bg-white br-8 py-4 px-3">
                <p class="col-12 text-general f-18 mb-4">
                    Datos solicitados a los usuarios para afiliación
                </p>
                <div class="col-12">
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3" />
                        <div class="col-2 text-general f-16 text-center">
                            Obligatorio
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            Opcional
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            No solicitar
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3 text-general f-16">
                            Salario
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="salarioRequerido" class="radio-green" :label="2">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="salarioRequerido" class="radio-green" :label="1">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="salarioRequerido" class="radio-green" :label="0">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3 text-general f-16">
                            No. de documento identidad
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="documentoIdentidad" class="radio-green" :label="2">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="documentoIdentidad" class="radio-green" :label="1">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="documentoIdentidad" class="radio-green" :label="0">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3 text-general f-16">
                            Adjunto del soporte
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="adjuntoSoporte" class="radio-green" :label="2">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="adjuntoSoporte" class="radio-green" :label="1">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="adjuntoSoporte" class="radio-green" :label="0">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                    </div>
                </div>
                <div class="col-12 my-3 border" />
                <div class="col-12">
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3 text-general f-16">
                            Nombre del soporte solicitado
                        </div>
                        <div class="col-3">
                            <el-input v-model="nombreSoporte" class="w-100" size="small" placeholder="Nombre" />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-3 text-general f-16">
                            Tipo de archivo de soporte
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="tipoSoporte" class="radio-green position-relative" :label="1">
                                <span class="position-absolute text-general" style="top:3px" v-text="`Imagen`" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="tipoSoporte" class="radio-green position-relative" :label="2">
                                <span class="position-absolute text-general" style="top:3px" v-text="`PDF`" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="tipoSoporte" class="radio-green position-relative" :label="3">
                                <span class="position-absolute text-general" style="top:3px" v-text="`CUALQUIERA`" />
                            </el-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-4 bg-white br-8 py-4 px-3">
                <p class="col-12 text-general f-18 mb-4">
                    Beneficios del convenio
                </p>
                <div class="col-auto px-3" />
                <div class="col text-general px-0 f-16">
                    Defina el porcentaje de descuento por cada monto de compra que realicen los clientes
                </div>
                <div class="col-12 mb-3" />
                <div class="col-auto" />
                <div class="col d-middle pl-4">
                    <span class="text-general f-18 mr-2">Descuentos</span>
                    <el-tooltip placement="bottom" content="Crear descuento" effect="light">
                        <div class="btn-red f-14">
                            <i class="icon-plus f-18" @click="pushDescuento" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-12 mb-4" />
                <div class="col-auto" />
                <div class="col pl-4">
                    <div v-for="(item,index) in descuentos" :key="index" class="row mx-0 align-items-center mt-1">
                        <div class="col-auto px-0 text-general f-18">
                            Más de
                        </div>
                        <div class="col-2">
                            <money v-model="item.desde" class="input-money w-100 soloNumeros" placeholder="Valor" size="small" v-bind="money" @focus="soloNumeros" />                       
                        </div>
                        <div class="col-auto px-0 text-general f-18 ml-3">
                            Descuento
                        </div>
                        <div class="col-2">
                            <el-input v-model="item.descuento" placeholder="%" class="w-100 soloNumeros" size="small" maxlength="2" @focus="soloNumeros" />
                        </div>
                        <el-tooltip placement="bottom" content="Eliminar" effect="light">
                            <div class="btn-action border" @click="deleteDescuento(index,item.id)">
                                <i class="icon-delete-trash f-18" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import Service from '~/services/nomina/nomina'
import {Money} from 'v-money'
export default {
    components:{Money},
    data(){
        return {
            cargando: false,
            loading: false,
            paises: [],
            ciudades: [],
            descuentos: [],
            nombre: '',
            nit: '',
            idPais: '',
            idCiudad: '',
            salarioRequerido: 2,
            documentoIdentidad: 2,
            adjuntoSoporte: 2,
            nombreSoporte: '',
            tipoSoporte: 1,
            codigoAfiliacion: '',
            mensajeBienvenida: '',
            porcentajeCupo: '',

            slimOptions: {
                ratio: '1:1',
                label:'Subir logo',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },

            url_leechero: '',
            radio: '1',

            descuento: '',
            valor: '',
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
        }
    },
    computed: {
        id_convenio(){
            return this.$route.params.id_convenio
        },
    },
    created(){
        this.cargando = true;
        this.getPaises();
        this.getDatosEmpresa();
    },
    methods: {
        async getDatosEmpresa(){
            try {
                if(!this.id_convenio)return
                const {data} = await Service.getEmpresaEdit(this.id_convenio)
                this.nombre = data.empresa.nombre;
                this.nit = data.empresa.nit;
                this.idPais = data.empresa.id_pais;
                this.ciudades = data.ciudad;
                this.codigoAfiliacion = data.empresa.codigo;
                this.mensajeBienvenida = data.empresa.mensaje;
                this.porcentajeCupo = data.empresa.porcentaje_cupo;
                this.salarioRequerido = data.empresa.salario_requerido;
                this.documentoIdentidad = data.empresa.cedula_requerida;
                this.adjuntoSoporte = data.empresa.documento_requerido;
                this.nombreSoporte = data.empresa.documento_label;
                this.tipoSoporte = data.empresa.documento_tipo;
                this.idCiudad = data.empresa.idm_ciudad;
                this.descuentos = data.descuentos;
                if (data.empresa.logo !== null && data.empresa.logo !== ''){
                    this.$refs.imgEmpresa.set_image(data.empresa.logo_ver)
                } else {
                    this.$refs.imgEmpresa.instanciaCrop.remove()
                }
                if (this.descuentos.length > 0){
                    this.descuentos.forEach(element => {
                        element.descuento = this.agregarSeparadoresNumero(element.descuento);
                    });
                }
                this.cargando = false;
            } catch(e){
                this.cargando = false;
                this.error_catch(e)
            }
        },
        async getPaises(){
            try {
                const {data} = await Service.getPaises()
                this.paises = data.paises
            } catch (e){
                this.error_catch(e)
            }
        },
        async getCiudades(query){
            try {
                if (!this.idPais){
                    this.notificacion('Mensaje','Debe seleccionar un país', 'warning');
                    return this.ciudades = [];
                }
                if (query.length < 3) return this.ciudades = [];
                const params = {
                    buscador: query,
                };
                const {data} = await Service.getCiudades(this.idPais,params)
                this.ciudades = data.ciudades
            } catch (e){
                this.error_catch(e)
            }
        },
        async putEmpresa(){
            try {
                let validCodigo = this.codigoAfiliacion.split(' ');
                if (validCodigo.length > 1) return this.notificacion('Mensaje', 'El código no puede tener espacios', 'warning');
                const valid = await this.$refs.validacion.validate()
                if (!valid) return
                let descuentosAceptados = [];
                this.cargando = true;
                if (this.descuentos.length > 0){
                    let desde = 0;
                    for(let i = 0; i < this.descuentos.length; i++){
                        if (this.descuentos[i].desde == 0 && this.descuentos[i].descuento === ''){
                            continue;
                        }
                        if (this.descuentos[i].desde != 0 && this.descuentos[i].descuento === ''){
                            this.cargando = false;
                            return this.notificacion('Beneficios del convenio', 'Información incompleta', 'warning');
                        }
                        if (this.descuentos[i].descuento != '' && this.descuentos[i].desde === 0){
                            this.cargando = false;
                            return this.notificacion('Beneficios del convenio', 'Información incompleta', 'warning');
                        }
                        if (parseInt(desde) === parseInt(this.descuentos[i].desde)){
                            this.cargando = false;
                            return this.notificacion('Beneficios del convenio', 'Información repetida', 'warning');
                        }
                        if (this.descuentos[i].descuento > 99){
                            this.cargando = false;
                            return this.notificacion('Beneficios del convenio', 'Descuentos no pueden ser mayores al 99%', 'warning');
                        }
                        desde = this.descuentos[i].desde;
                        descuentosAceptados.push(this.descuentos[i]);
                    }
                }
                const logo = this.$refs.imgEmpresa.instanciaCrop.dataBase64.output.image
                const form = {
                    nombre: this.nombre,
                    nit: this.nit,
                    logo: logo,
                    idm_ciudad: this.idCiudad,
                    codigo: this.codigoAfiliacion,
                    mensaje: this.mensajeBienvenida,
                    porcentaje_cupo: this.porcentajeCupo,
                    salario_requerido: this.salarioRequerido,
                    cedula_requerida: this.documentoIdentidad,
                    documento_requerido: this.adjuntoSoporte,
                    documento_label: this.nombreSoporte,
                    documento_tipo: this.tipoSoporte,
                    descuentos: descuentosAceptados
                };
                const {data} = await Service.putEmpresa(this.id_convenio, form)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$router.push({name: 'admin.nomina.ver.empresa', params: {id_convenio: this.id_convenio}});
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        limpiarPais(){
            this.idPais = '';
            this.idCiudad = '';
            this.ciudades = [];
        },
        limpiarCiudad(){
            this.idCiudad = '';
        },
        limpiar(){
            this.nombre = '';
            this.nit = '';
            this.idPais = '';
            this.idCiudad = '';
            this.codigoAfiliacion = '';
            this.mensajeBienvenida = '';
            this.porcentajeCupo = '';
            this.leecherosSeleccionados = [];
            this.leecherosSeleccionadosDesv = [];
            this.leecheros = [];
            this.leecherosDesv = [];
            this.salarioRequerido = 2;
            this.documentoIdentidad = 2;
            this.adjuntoSoporte = 2;
            this.nombreSoporte = '';
            this.tipoSoporte = 1;
            this.$refs.validacion.reset();
            this.$refs.imgEmpresa.instanciaCrop.remove()
        },
        soloNumeros(){
            $('.soloNumeros').keypress(function(event){
                // Bloqueo de letras y caracteres especiales
                let char = String.fromCharCode(event.keyCode);
                let pattern = '[a-zA-Z|°!"#$%&/()=?¿¡{}]';
                if (char.match(pattern)){
                    return false;
                }
            });
        },
        validarInputCupo(){
            if (this.porcentajeCupo > 200){
                this.notificacion('Cupo no valido','El cupo no puede ser mayor a  200%','warning');
            }
        },
        pushDescuento(){
            let index = this.descuentos.length;
            if(this.descuentos.length < 1){
                this.descuentos.push({
                    'id': null,
                    'desde': 0,
                    'descuento': ''
                });
            } else if (this.descuentos[index-1].desde != 0 && this.descuentos[index-1].desde != ''){
                this.descuentos.push({
                    'id': null,
                    'desde': 0,
                    'descuento': ''
                });
            }
        },
        async deleteDescuento(index,id){
            if (id === null) return this.descuentos.splice(index, 1);
            try {
                const {data} = await Service.deleteDescuentoEmpresa(id)
                this.notificacion('Mensaje','Descuento se ha eliminado correctamente','success');
                return this.descuentos.splice(index, 1);
            } catch (e){
                this.error_catch(e)
            }
        },
        irAtras(){
            this.$router.push({name:'admin.nomina.ver.empresa', params:{id_convenio:this.id_convenio}});
        },
    },
}
</script>
<style lang="scss" scoped>
.slim-styles{
    width:162px;
    height:162px;
    border-radius:6px;
    background-color:#F5F5F5;
}
</style>

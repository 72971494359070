<template>
    <section class="pt-3" style="height: calc(100% - 60px);">
        <div class="row mx-0 h-100">
            <div class="menu-prinicipal border-r-gris h-100">
                <div class="row mx-0 border-b-gris" style="height:57px;">
                    <div class="col my-auto">
                        <el-input v-model="word" :placeholder="`Buscar ${$config.cliente} o pedido`" size="small" class="br-20" @input="buscarSoportes" />
                    </div>
                </div>
                <div class="col px-0 overflow-auto custom-scroll" style="width:450px; height: calc(100% - 58px);">
                    <content-loader v-if="loading" />
                    <div
                    v-for="(sop, idx) in usersSoportes" :key="idx"
                    :class="`col mx-0 px-0 d-middle border-bottom cr-pointer py-2 ${id_user_cliente==sop.id_user?'bg-whitesmoke':''}`"
                    style="height:90px;"
                    @click="activarCar(sop)"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${id_user_cliente==sop.id_user?'bg-general':''}`" />
                        <img class="border bg-whitesmoke rounded-circle" width="55" height="55" :src="sop.imagen_firmada" alt="" />
                        <div class="col-9 px-0 text-general f-15">
                            <div class="row mx-0 px-0 f-600 align-items-baseline">
                                <p class="col-4">ID {{ sop.id_user }}</p>
                                <p class="col tres-puntos">{{ sop.nombre }}</p>
                                <estrellas :row="sop" class="ml-auto" />
                            </div>
                            <div class="row mx-0 align-items-start">
                                <el-tooltip placement="bottom" :content="sop.ult_mensaje" effect="light">
                                    <p class="col tres-puntos text-general">{{ sop.ult_mensaje }}</p>
                                </el-tooltip>
                                <div v-if="sop.cant_msg > 0" class="br-10 bg-general3 text-white px-2">
                                    {{ sop.cant_msg }}
                                </div>
                            </div>
                            <div class="row mx-0">
                                <p class="tres-puntos col-6 ml-auto">{{ sop.fecha_ult_finalizado }}</p>
                                <p class="tres-puntos col-6 px-1"> {{ sop.hora_ult_chat_format }}</p>
                            </div>
                        </div>
                    </div>
                    <infinite-loading ref="infiniteLoading" @infinite="listarUsesClientes">
                        <div slot="spinner">
                            <div v-loading="true">
                                Cargando...
                            </div>
                        </div>
                        <div slot="no-more" class="text-muted py-2">
                            <span>No hay más soportes</span>
                        </div>
                        <div slot="no-results" class="text-muted">
                            No se encuentran soportes
                        </div>
                    </infinite-loading>
                    <div v-if="usersSoportes.length <= 0" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/question.svg" alt="" />
                            </div>
                            <p>Aún no has recibido un mensaje de soporte</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 h-100 col menu-prinicipal" style="width: calc(100vw - 560px);">
                <div v-show="id_user_cliente !== 0" class="row mx-0 px-3 border-b-gris align-items-center" style="height:75px;">
                    <img class="border bg-whitesmoke rounded-circle mx-2" width="50" height="50" :src="detalle.imagen_firmada" alt="" />
                    <div class="col-auto pl-0">
                        <p class="text-general f-600">Soporte: {{ detalle.id_user }}</p>
                        <p class="text-general2 f-14">{{ detalle.nombre }} </p>
                        <p class="text-general2 f-12">{{ detalle.telefono_celular }}</p>
                    </div>
                    <div class="col-auto pl-2">
                        <p class="text-general f-600">{{ $config.vendedor }}</p>
                        <p class="text-general2">{{ detalle.propietario_nombre || 'No registra' }}</p>
                    </div>
                    <div class="col-auto pl-2">
                        <p class="text-general f-600">Cedis</p>
                        <p class="text-general2">{{ detalle.cedis_nombre || 'No registra' }}</p>
                    </div>
                    <div class="col px-0 d-middle justify-content-end">
                        <estrellas :row="detalle" />
                        <div class="btn-general tres-puntos px-2 f-14" @click="cerrarSoporte">
                            Finalizar soporte
                        </div>
                    </div>
                </div>
                <div class="" style="height: calc(100% - 63px);">
                    <chat v-if="id_user_cliente!=0" ref="mychat" v-loading="loading_chat" :tipo="4" :user="detalle.id_user" :con-soporte="true" @actualizar-lectura="actualizarLectura" />
                    <div v-else class="d-middle-center h-100">
                        <div class="col-4 text-center">
                            <img height="110" src="/img/no-imagen/click.svg" alt="" />
                            <p>Selecciona uno de los soportes abiertos del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalReclamos ref="modalReclamos" />
        <modalConfirmar ref="modalCerrarSoporte" titulo="Cerrar soporte" icon="checked" mensaje="¿Desea cerrar este caso?" @guardar="finalizarChat">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center">
                    <div class="col-11">
                        <label class="pl-3 f-14 text-general">Deja un comentario final sobre este caso</label>
                        <ValidationProvider v-slot="{errors}" rules="required" name="comentario">
                            <el-input v-model="comentario" type="textarea" :rows="4" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modalConfirmar>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Soporte from '~/services/soporte/tiendas'
import Chat from '~/services/chat/chat'
import InfiniteLoading from 'vue-infinite-loading'

export default {
    metaInfo: () =>({ title: 'soporte'}),
    components: {
        modalReclamos: () => import('./partials/modalReclamos'),
        InfiniteLoading
    },
    sockets:{
        chats_users(value){
            try {
                const {chat} = JSON.parse(value)
                if(parseInt(chat.id_user) !== parseInt(this.id_user_cliente)){
                    // this.notifiChat(1, chat) se realiza en el menu lateral
                    const index = this.usersSoportes.findIndex( e => parseInt(e.id_user) === parseInt(chat.id_user) )
                    if(index >= 0){
                        this.usersSoportes[index].cant_msg +=1
                    }else{// No esta el usuario a en el listado
                        // this.buscarTiendas()
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    data(){
        return {
            comentario:'',
            loading: false,
            usersSoportes: [],
            word: null,
            chatActivo:  true,
            page:1,
            loading_chat: false
        }
    },
    computed:{
        ...mapGetters({
            id_user_cliente: 'soporte/general/id_user_cliente',
            detalle: 'soporte/general/detalle',
            cant_users_soportes: 'soporte/tiendas/cant_users_soportes',
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',

        }),

    },
    watch: {
        id_cedis(){
            this.page = 1
            this.$store.commit('soporte/tiendas/set_cant_users_soportes', 0)
            this.$store.commit('soporte/general/set_id_user_cliente', 0)
            this.usersSoportes = []
            this.listarUsesClientes()
        }
    },
    mounted(){
        this.$store.commit('soporte/tiendas/set_cant_users_soportes', 0)
        this.$store.commit('soporte/general/set_id_user_cliente', 0)

    },
    methods: {
        async listarUsesClientes($state= false){
            try {
                const state = $state
                const params = {
                    page: this.page,
                    id_user: this.user,
                    word: this.word // solo para chat de pedidos
                }
                this.loading = true
                const {data} = await Soporte.listarUsersClientes(params)
                this.$store.commit('soporte/tiendas/set_cant_users_soportes', this.cant_users_soportes +data.cant_msg_total)

				if(this.word != null && this.word != ''){
					this.usersSoportes = [];
				}
                this.page += 1
                this.loading = false
                let temp = _.union(this.usersSoportes, data.users.data, 'id')
                let uniqBy = _.uniqBy(temp, 'id')
                this.usersSoportes = uniqBy
                if (data.users.data.length > 0){
                    if(state){
                        state.loaded()
                    }
                } else {
                    if(state){
                        state.complete()
                    }
                }

            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        async activarCar(soporte){
            this.$store.commit('soporte/general/set_id_user_cliente', soporte.id_user)
            this.$store.commit('soporte/general/set_detalle', soporte)
            // this.detalle = soporte
            await this.actualizarUltimaLectura()
            soporte.cant_msg = 0
        },
        async actualizarLectura(){
            await this.actualizarUltimaLectura()
        },
        async actualizarUltimaLectura(){
            try {
                const enviar ={
                    id_dinamico: this.id_user_cliente,
                    tipo: 1 // user -- users_cliente
                }
                const {data} = await Chat.actualizarUltimaLecturaChat(enviar)

            } catch (e){
                this.error_catch(e)
            }
        },
        buscarSoportes(){
            this.page = 1
            this.$store.commit('soporte/tiendas/set_cant_users_soportes', 0)

            // this.limpiarDetalle()
            this.tiendas = []
            this.delay(this.listarUsesClientes)
        },
        cerrarSoporte(){
            this.comentario = ''
            this.$refs.modalCerrarSoporte.toggle()
        },
        reclamos(){
            this.$refs.modalReclamos.toggle()
        },

        async finalizarChat(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const enviar = {
                    id_user:  this.id_user_cliente,
                    comentario: this.comentario

                }
                const {data} = await Soporte.finalizarChat(enviar)
                this.notificacion('Mensaje', 'Chat finalizado', 'success')
                this.loading_chat= true
                this.$refs.mychat.resetear()
                this.loading_chat= false
                this.$refs.modalCerrarSoporte.toggle()


            } catch (e){
                this.error_catch(e)
            }

        }

    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal{
    background: #fff;
}
.text-red{
    color: #FF2525;
}
.text-yellow{
    color: #FF9D32;
}
.text-aqua{
    color: #03D6BC;
}
</style>

import axios from 'axios'

const api = "nomina/detalle-afiliado"

const detalleAfiliado = {
    
    header: (id, params) => axios.get(`${api}/header/${id}`, { params }),
    actualizarAfiliado: (params) => axios.post(`${api}/actualizar-afiliado`, params),
    desvincular: (params) => axios.post(`${api}/desvincular`, params),
    dataDesvincular: id => axios.get(`${api}/data-desvincular/${id}`),
    
}
export default detalleAfiliado

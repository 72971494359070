import axios from 'axios'

const api = 'gaming';

const GamingPedidos = {
    
    getGamingPedidos: () => axios.get(`${api}/get-gaming-pedidos`),
    getGamingPedidosHistorial: (offset) => axios.get(`${api}/${offset}/get-gaming-historial-pedidos`),

    getGamingPedido: (idPedido) => axios.get(`${api}/${idPedido}/get-gaming-pedido-detalle`),
    getComentariosPedido: (idPedido) => axios.get(`${api}/${idPedido}/get-comentarios-pedido`),
    confirmarORechazarGamingPedido: (params) => axios.put(`${api}/put-gaming-pedido-estado`,params),
    cancelarGamingPedido: (params) => axios.put(`${api}/cancelar-gaming-pedido`,params),
    crearComentarioGamingPedido: (params) => axios.post(`${api}/crear-comentario-pedido`,params)

}

export default GamingPedidos
<template>
    <section class="premios pt-3">
        <div class="row mx-0 bg-white br-t-12">
            <div class="select-premio border-right">
                <div class="row mx-0 align-items-center border-bottom" style="height:45px;">
                    <div class="col">
                        <el-input v-model="buscar" placeholder="Buscar producto" class="br-20 w-100" size="small" @input="buscador" />
                    </div>
                    <template v-if="tabActiva != 'cedis'">
                        <el-tooltip placement="bottom" content="Administrar Categorias" effect="light">
                            <div class="btn-action border mr-2" @click="administrarCategorias">
                                <i class="icon-view-grid-outline f-20" />
                            </div>
                        </el-tooltip>
                        <el-tooltip v-if="navBar.length" placement="bottom" content="Añadir premio" effect="light">
                            <div class="btn-red mr-2" @click="crearPremio">
                                <i class="icon-package-add f-20" />
                            </div>
                        </el-tooltip>
                    </template>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 190px)">
                    <div v-for="(cat,index) in categorias" :key="index" class="row mx-0 border-bottom px-3 cr-pointer py-2 align-items-center" style="height:54px;" @click="setCategoriaActiva(cat.id)">
                        <div :class="`px-1 ${categoriaActiva == cat.id ? 'bg-general' : ''}  br-20`" style="width:7px;height:38px;" />
                        <div class="col">
                            {{ cat.nombre }}
                        </div>
                        <div class="pill d-middle-center f-14" :class="categoriaActiva == cat.id ? 'bg-general3 text-white' : 'bg-whitesmoke4 text-general'">
                            {{ cat.contador }}
                        </div>
                        <i class="icon-angle-right f-15" :class="categoriaActiva == cat.id ? 'text-general' : 'text-grey2'" />
                    </div>
                </div>
            </div>
            <div :class="`${!productos.length ? 'row justify-center ' : ''} custom-scroll pt-3 overflow-auto col pr-0 border-right`" style="height:calc(100vh - 145px)">
                <div v-infinite-scroll="getProductosListado" infinite-scroll-disabled="busy" infinite-scroll-immediate-check="false" infinite-scroll-distance="20">
                    <div v-for="(pro,index) in productos" :key="index" class="row mx-0 mb-3 cr-pointer" @click="handlerProducto(pro.id)">
                        <div :class="`px-1 ${productoActivo == pro.id ? 'bg-general' : ''} br-20`" style="width:7px;height:90px;" />
                        <img :src="pro.imagen" class="ml-2 obj-cover br-4" width="90" height="90" />
                        <div class="col px-3 pt-3">
                            <div class="row mx-0">
                                <div class="col pl-0 pr-2 f-14 text-general">
                                    {{ pro.nombre }}
                                </div>
                                <i :class="`${pro.estado ? 'icon-eye' : 'icon-eye-off'} f-20 text-general`" @click="updateEstadoProducto(pro.id,pro.estado,index)" />
                            </div>
                            <p class="text-general f-14 f-500">
                                {{ pro.contador_cedis }} Cedis
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="!productos.length" class="col pr-0 px-0 mx-0 my-auto">
                    <div class="row mx-0 justify-center align-content-center f-60">
                        <div class="col-5 text-center">
                            <img src="/img/no-imagen/click.svg" style="width: 115px; height: 115px;" />
                            <p class="mb-0 text-muted2 mt-2 f-17 f-600">Selecciona una categoría del panel de la izquierda.</p>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col px-0">
                <div class="row mx-0 border-bottom">
                    <div class="col text-center cr-pointer pt-3 pb-1 f-14" :class="tabActiva == 'registro' ? 'text-general border-b-black' : ''" @click="tabActiva = 'registro'">
                        Registro
                    </div>
                    <div class="col text-center cr-pointer pt-3 pb-1 f-14" :class="tabActiva == 'cedis' ? 'text-general border-b-black' : ''" @click="tabActiva = 'cedis'">
                        Cedis
                    </div>
                </div>
                <registro v-if="tabActiva=='registro'" :producto="producto" @update="handlerProducto" @recargar="getCategoriasListado" />
                <cedis v-if="tabActiva=='cedis'" :cedis="cedis" @aumentar="aumentarCedisPremios" />
            </div>
        </div>
        <!-- Partials -->
        <modal-administrar-categorias ref="administrarCategorias" />
        <modal-crear-premio ref="crearPremio" @update="getCategoriasListado" />
    </section>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import {mapGetters} from 'vuex'
import GamingProductos from '@/services/gaming/gamingProductos'
export default {
    directives: {infiniteScroll},
    components: {
        registro : () => import('./registro.vue'),
        cedis : () => import('./cedis.vue'),
        modalAdministrarCategorias: () => import('../partials/modalAdministrarCategorias.vue'),
        modalCrearPremio: () => import('../partials/modalCrearPremio.vue'),
    },
    data(){
        return {
            busy: false,
            buscar: null,
            categoriaActiva: null,
            categoriaAntigua: null,
            productoActivo: null,
            tabs: [
                { titulo: 'Registro', ruta: 'admin.gaming.premios.registro' },
                { titulo: 'Cedis', ruta: 'admin.gaming.premios.cedis' } 
            ],
            tabActiva: 'registro',
            categorias: [],
            productos: [],
            producto: {},
            cedis: {}
        }
    },
    computed: {
        ...mapGetters({
            navBar: 'cedis/cedisNavbar',
        }),
    },
    watch: {
        async navBar(){
            await this.getCategoriasListado()
        }
    },
    mounted(){
        this.getCategoriasListado()
    },
    methods: {
        administrarCategorias(){
            this.$refs.administrarCategorias.toggle();
        },
        crearPremio(){
            this.$refs.crearPremio.toggle(this.navBar[0].id);
        },
        buscador(){
            this.delay(this.getCategoriasListado,1000) 
        },
        setCategoriaActiva(categoria){
            this.producto = {}
            this.categoriaActiva = categoria
            this.getProductosListado()
        },
        async getCategoriasListado(){ 
            try {
                this.limpiar()

                let params = {
                    buscar: this.buscar
                }

                const { data } = await GamingProductos.getCategoriasListado({params})
                this.categorias = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async getProductosListado(){
            try {
                this.busy = true

                let params = {
                    buscar: this.buscar,
                    take: 10,
                    offset: this.categoriaAntigua==this.categoriaActiva ? this.productos.length : 0
                }

                let idCategoria = this.categoriaActiva

                const { data } = await GamingProductos.getProductosXCategoria(idCategoria,{params})

                if(this.categoriaAntigua==this.categoriaActiva){

                    this.productos = [...this.productos,...data.data]

                }else{
                    this.productos = data.data
                }
                
                if(!data.data.length){
                    this.busy = true
                }else{
                    this.busy = false
                }

                this.categoriaAntigua = this.categoriaActiva

            } catch (e){
                this.error_catch(e)
            }

        },
        async updateEstadoProducto(idProducto,estado,index){
            try {

                let params = {
                    id_gaming_producto: idProducto,
                    estado: !estado
                }    

                const { data } = await GamingProductos.updateEstadoGamingProducto(params)
                this.productos[index].estado = !estado
                this.notificacion("",`Premio ${params.estado ? 'activado' : 'inactivado'} correctamente`,"success")

            } catch (e){
                this.error_catch(e)
            }
        },
        async handlerProducto(idProducto,actualizar=false){
            try {
                this.productoActivo = idProducto
                
                await this.getGamingProducto(idProducto)
                await this.getCedisGamingProducto(idProducto)

                if(actualizar){
                    let index = this.productos.findIndex(el=> el.id == idProducto)

                    this.productos[index].nombre = this.producto.producto.nombre
                    this.productos[index].imagen = this.producto.producto.imagen
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        async getGamingProducto(idProducto){
            try {
                const { data } = await GamingProductos.getGamingProducto(idProducto)
                this.producto = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async getCedisGamingProducto(idProducto){
            try {

                const { data } = await GamingProductos.getCedisGamingProducto(idProducto)

                let cedis = data.data.map(function(el){
                    el.estado = el.estado ? true : false
                    el.monedas = el.monedas ? el.monedas : 0
                    el.gemas = el.gemas ? el.gemas : 0
                    return el
                })

                let objeto = {
                    data: cedis,
                    id_producto: data.id_producto
                }

                this.cedis = objeto
                
            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.categorias = []
            this.productos = []
            this.producto = {
                producto: {},
                categorias: []
            }
            this.cedis = null

        },
        aumentarCedisPremios(calculo){
            let index = this.productos.findIndex(el=>el.id==this.productoActivo)
            console.log(index,calculo)
            this.productos[index].contador_cedis = calculo ? this.productos[index].contador_cedis+1 : this.productos[index].contador_cedis-1
        }
        
    }
}
</script>
<style lang="scss" scoped>
.select-premio{
    width: 371px;
}
.pill{
    height: 28px;
    min-width: 28px;
    border-radius: 20px;
    color: #000000;
}
</style>
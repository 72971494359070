import axios from 'axios'

const API = "nomina"

const ENDPOINTS = {
    getLeecheros(params){
        return axios.get(`${API}/leecheros`, {params})
    },
    getPaises(){
        return axios.get(`${API}/paises`)
    },
    getEstados(id){
        return axios.get(`${API}/${id}/estados`)
    },
    getCiudades(id,params){
        return axios.get(`${API}/${id}/ciudades`,{params})
    },
    postEmpresa(form){
        return axios.post(`${API}/empresa/crear`,form)
    },
    putEmpresa(id, form){
        return axios.put(`${API}/${id}/empresa/actualizar`,form)
    },
    getEmpresas(){
        return axios.get(`${API}/empresas`)
    },
    getEmpresaDetalle(id){
        return axios.get(`${API}/${id}/empresa/detalle`)
    },
    getEmpresaEdit(id){
        return axios.get(`${API}/${id}/empresa/ver`)
    },
    putEstadoEmpresa(id){
        return axios.put(`${API}/${id}/empresa/estado`)
    },
    deleteEmpresa(id){
        return axios.delete(`${API}/${id}/empresa/eliminar`)
    },
    getLeecherosEmpresa(id){
        return axios.get(`${API}/${id}/empresa/leecheros`)
    },
    deleteLeecheroEmpresa(id_empresa, id_leechero, tipo){
        return axios.delete(`${API}/${id_empresa}/empresa/${id_leechero}/leechero/${tipo}/eliminar`);
    },
    asignarLeecherosEmpresa(id, form){
        return axios.post(`${API}/${id}/empresa/leecheros/asignar`,form)
    },
    deleteDescuentoEmpresa(id){
        return axios.delete(`${API}/${id}/empresa/descuento`);
    },
    getLeecherosConvenio(id){
        return axios.get(`${API}/${id}/convenio-leecheros`);
    }
}
export default ENDPOINTS

import axios from 'axios'

const api = "nomina/dashboard"

const estadisticas = {
    index: params => axios.get(`${api}/index`, {params}),
    graficasConFechas: params => axios.get(`${api}/graficas-con-fechas`, {params}),
    deudasSinLiquidar: params => axios.get(`${api}/deudas-sin-liquidar`, {params}),
    liquidacionesSinVerificacion: params => axios.get(`${api}/liquidaciones-sin-verificacion`, {params}),
    cupoOtorgado: params => axios.get(`${api}/cupo-otorgado`, {params}),
    solicitudes: params => axios.get(`${api}/solicitudes`, {params}),
    comprasConvenio: params => axios.get(`${api}/compras-convenio`, {params}),
    calificacionPedidos: params => axios.get(`${api}/calificacion-pedidos`, {params}),
}

export default estadisticas
<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 border-bottom align-items-center" style="height:50px;">
            <div class="col px-1">
                <p class="f-600 text-general">Preguntas más frecuentes</p>
            </div>
            <div v-if="creacionCompleta" class="d-flex ml-auto h-100">
                <div class="ml-auto h-100 d-middle-center cr-pointer" :class="destinoSeleccionado === 1? 'border-b-red text-general-red':''" style="width:170px;" @click="cambiarDestino">
                    {{$config.vendedor}}
                </div>
                <div class="h-100 d-middle-center cr-pointer" :class="destinoSeleccionado === 2? 'border-b-red text-general-red':''" style="width:170px;" @click="cambiarDestino">
                    {{$config.cliente}}
                </div>
            </div>
        </div>
        <div class="p-3 f-15">
            <div class="row mx-0 text-general">
                Crea grupo de preguntas y preguntas que puedan ayudar a los usuarios a aclarar sus inquietudes sobre como funciona {{$config.proyecto}}.
            </div>
            <ValidationObserver ref="validacionCreacion" v-slot="{ invalid }">
                <div class="row mx-0 my-2">
                    <div class="col-5 px-2">
                        <p class="ml-2 text-general">Grupo de preguntas</p>
                        <ValidationProvider v-slot="{ errors }" rules="required" name="título del grupo">
                            <el-input v-model="payload.titulo" :suffix-icon="false?'icon-attention-alt text-danger':''" required maxlength="80" @input="buscarSiExisteNombreGrupo" />
                            <span v-show="nombreGrupoDuplicado" class="text-danger w-100 f-11"> Ya existe un motivo con este nombre </span>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto mt-3 pt-1">
                        <el-tooltip class="item" effect="light" content="Crear grupo de preguntas" placement="bottom">
                            <div class="bg-general cr-pointer d-middle-center br-10" style="width:40px;height:40px;" @click="intentarCrearGrupo(invalid)">
                                <i class="icon-plus f-20 text-white" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <draggable v-if="!_.isEmpty(faqs)" :list="faqs" handle=".handle" class="overflow-auto custom-scroll" style="max-height:calc(100vh - 336px);" @change="actualizar_posicion">
                <div v-for="(g, i) in faqs" :key="i" class="mb-3">
                    <div class="row mx-0 px-2">
                        <i class="icon-drag my-auto mr-1 f-18 cr-pointer handle" />
                        <div class="col px-0 border br-10">
                            <div class="d-middle mx-0">
                                <div class="px-2 br-l-10" :class="!g.editando ? 'bg-general3':'bg-general2'" style="height:40px;" />
                                <div class="col px-0 pr-2 bg-input-white">
                                    <ValidationProvider v-slot="{ errors }" rules="required" name="título del grupo">
                                        <el-input v-model="g.titulo" class="border-0" :disabled="!g.editando" required maxlength="80" @input="buscarSiExisteNombreGrupo" />
                                        <span v-show="nombreGrupoDuplicado" class="text-danger w-100 f-11"> Ya existe un motivo con este nombre </span>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                                <el-tooltip class="item" effect="light" content="Editar grupo de preguntas" placement="bottom">
                                    <div
                                    v-show="!g.editando"
                                    class="d-middle br-5  cr-pointer m-1 ml-auto" style="width:32px;height:32px;"
                                    @click="editarGrupo(g)"
                                    >
                                        <i class="icon-pencil-outline f-22 hover-icons" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip class="item" effect="light" content="Eliminar grupo de preguntas" placement="bottom">
                                    <div v-show="!g.editando" class="d-middle br-5 cr-pointer m-1" style="width:32px;height:32px;" @click="abrirModalEliminarGrupo(g)">
                                        <i class="icon-trash-empty f-22 hover-inverse" />
                                    </div>
                                </el-tooltip>
                                <div v-show="!g.editando" class="bg-general cr-pointer d-middle br-5 text-white m-1" style="width:32px;height:32px;" @click="abrirModalCrearPregunta(g)">
                                    <i class="icon-plus f-22" />
                                </div>
                                <div v-show="g.editando" class="bg-light-f5 d-middle br-5 cr-pointer m-1" style="width:32px;height:32px;" @click="intentarActualizarGrupo(g)">
                                    <i class="icon-ok-circled-outline f-22 text-general2" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!_.isEmpty(g.preguntas)" class="pl-3">
                        <draggable tag="div" :list="g.preguntas" handle=".handle" @change="actualizar_posicion_hijos($event, g.id)">
                            <template v-for="(p, ix) in g.preguntas">
                                <div :key="`item-${ix}`" class="row mx-0 pl-3 pr-2 mt-2">
                                    <i class="icon-drag my-auto mr-1 f-18 cr-pointer handle" />
                                    <div class="col px-0 br-10" :class="p.estado?'bg-rosa':'bg-desactive'">
                                        <div class="d-middle mx-0">
                                            <p class="f-17 mx-2 text-general2" v-text="p.titulo" />
                                            <el-tooltip class="item" effect="light" content="Editar pregunta" placement="bottom">
                                                <div class="d-middle br-5 ml-auto cr-pointer m-1" style="width:32px;height:32px;" @click="abrirModalEditarPregunta(g, p)">
                                                    <i class="icon-pencil-outline hover-icons f-22" />
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="light" content="Eliminar pregunta" placement="bottom">
                                                <div class="d-middle br-5 cr-pointer m-1" style="width:32px;height:32px;" @click="abrirModalEliminarPregunta(g, p)">
                                                    <i class="icon-trash-empty hover-inverse f-22" />
                                                </div>
                                            </el-tooltip>
                                            <el-switch
                                            v-model="p.estado"
                                            class="my-auto switch-red"
                                            active-color="red"
                                            inactive-color="#DBDBDB"
                                            @change="actualizarEstado(p)"
                                            />
                                            <i class="mx-2 cr-pointer f-22" :class="colocarIconoColapsado(p.colapsado)" @click="colapsar(p)" />
                                        </div>
                                    </div>
                                    <div v-show="!p.colapsado" class="col-12 mx-0 px-4 my-2">
                                        <div class="col pl-4">
                                            <p style="white-space: break-spaces;" v-text="p.texto" />
                                        </div>
                                    </div>
                                </div>
                                <!-- <div v-show="!p.colapsado" :key="`col-${ix}`" class="row mx-0 px-4 my-2">
                                    <div class="col pl-4">
                                        <p style="white-space: break-spaces;" v-text="p.texto" />
                                    </div>
                                </div> -->
                            </template>
                        </draggable>
                    </div>
                    <div v-else class="row mx-0 my-2 justify-content-center align-items-center">
                        <div class="rounded-circle bg-whitesmoke mr-2" style="width:35px;height:35px;">
                            <i class="icon-format-list-bulleted-type f-23 text-gr-general" />
                        </div>
                        <p>Grupo sin preguntas</p>
                    </div>
                </div>
            </draggable>
            <sinDatos v-else icon="list" mensaje="Aún no se han creado preguntas frecuentes" />
            <!-- <div class="row mx-0 my-3 justify-center">
                <div class="" style="width:160px;">
                    <p>Aún no se han creado preguntas frecuentes</p>
                </div>
            </div> -->
        </div>
        <modalGestionarFaqs
        ref="modalGestionarFaqs"
        :tipo="tipo"
        :modo-edicion="estaEditando"
        :datos="itemSeleccionado"
        @crear="crearFaq"
        @actualizar="actualizarFaq"
        />
        <modalEliminar
        ref="modalEliminarFaq"
        :titulo="modal[tipoModalEliminar].titulo"
        :mensaje="modal[tipoModalEliminar].mensaje"
        @eliminar="eliminarFaq"
        />
    </section>
</template>

<script>
import faqsMixin from '~/mixins/faqs/gestion'
import faqs from '~/services/faqs/faqs'
const TIPO = 1

export default {
    name: 'InformacionTerminosCondiciones',
    components: {
        modalGestionarFaqs: () => import('./partials/modalGestionarFaqs')
    },
    mixins: [ faqsMixin ],
    data(){
        return {
            payload: { titulo: '' },
            nombreGrupoDuplicado: false,
            modal: {
                grupo: {
                    titulo: 'Eliminar grupo de preguntas',
                    mensaje: '¿Desea eliminar el grupo?'
                },
                pregunta: {
                    titulo: 'Eliminar pregunta',
                    mensaje: '¿Desea eliminar la pregunta?'
                }
            },
            tipoModalEliminar: 'grupo'
        }
    },
    mounted(){
        this.consultarFaqs(TIPO, 0)
    },
    methods: {
        intentarCrearGrupo(esInvalido){
            if (esInvalido) return

            this.crearFaq(this.payload).then(() => {
                this.$refs.validacionCreacion.reset()
                this.limpiarPayload()
            })
        },
        intentarActualizarGrupo(grupo){
            grupo.editando = false
            this.actualizarFaq({
                id: grupo.id,
                titulo: grupo.titulo
            })

            this.limpiarPayload()
        },
        limpiarPayload(){
            this.payload = { titulo: '' }
        },
        editarGrupo(grupo){
            grupo.editando = true
            this.seleccionarGrupo(grupo)
        },
        seleccionarGrupo(grupo){
            this.limpiarElementoSeleccionado()
            this.itemSeleccionado = { id: grupo.id, titulo: grupo.titulo }
        },
        buscarSiExisteNombreGrupo(){
            const titulo = _.get(this.payload, 'titulo', '').toLowerCase()
            if (_.isEmpty(titulo)) return
            this.nombreGrupoDuplicado = !_.isEmpty(this.faqs.filter(x => x.titulo.toLowerCase() === titulo))
        },
        seleccionarItemPreguntas({ id: id_padre }, { estado, titulo, id, texto, id_texto }){
            this.limpiarElementoSeleccionado()
            this.itemSeleccionado = {
                id_padre,
                ...(estado && { estado }),
                ...(titulo && { titulo }),
                ...(id && { id }),
                ...(texto && { texto }),
                ...(id_texto && { id_texto })
            }
        },
        abrirModalCrearPregunta(grupo){
            this.seleccionarItemPreguntas(grupo, {})
            this.$refs.modalGestionarFaqs.toggle()
        },
        abrirModalEditarPregunta(grupo, pregunta){
            this.seleccionarItemPreguntas(grupo, pregunta)
            this.$refs.modalGestionarFaqs.toggle()
        },
        abrirModalEliminarGrupo(grupo){
            this.tipoModalEliminar = 'grupo'
            this.seleccionarGrupo(grupo)
            this.$refs.modalEliminarFaq.toggle()
        },
        abrirModalEliminarPregunta(grupo, pregunta){
            this.tipoModalEliminar = 'pregunta'
            this.seleccionarItemPreguntas(grupo, pregunta)
            this.$refs.modalEliminarFaq.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.bg-desactive{
    background: #f5f5f5;
    opacity: 0.6;
}
.text-orange{
    color: #FF612B;
}
$border: 5px;
.border-b-red{
    border-bottom: 3px solid var(--color-general);
}
</style>

import axios from 'axios'

const api = 'gaming/productos'

const GamingProductos = {
    //listas módulo
    getCategoriasListado: (params) => axios.get(`${api}/get-categorias-listado`,params),
    getProductosXCategoria: (idCategoria,params) => axios.get(`${api}/${idCategoria}/get-productos-x-categoria`,params),
    getGamingProducto: (idProducto) => axios.get(`${api}/${idProducto}/get-producto`),
    getCedisGamingProducto: (idProducto) => axios.get(`${api}/${idProducto}/get-cedis-producto`),
    //productos
    crearGamingProducto: (params) => axios.post(`${api}/crear-producto`,params),
    editarGamingProducto: (params) => axios.put(`${api}/editar-producto`,params),
    cambiarImagenGamingProducto: (params) => axios.put(`${api}/cambiar-imagen-producto`,params),
    updateEstadoGamingProducto: (params) => axios.put(`${api}/update-estado-producto`,params),
    eliminarGamingProducto: (idProducto) => axios.delete(`${api}/${idProducto}/delete-producto`),
    //categorias
    getCategoriasGamingProducto: () => axios.get(`${api}/get-categorias`),
    crearCategoriaGamingProducto: (params) => axios.post(`${api}/crear-categoria`,params),
    editarCategoriaGamingProducto: (params) => axios.put(`${api}/editar-categoria`,params),
    borrarCategoriaGamingProducto: (idCategoria) => axios.delete(`${api}/${idCategoria}/delete-categoria`),
    //cedis
    actualizarGamingProductoCedis: (params) => axios.post(`${api}/actualizar-producto-cedis`,params)
}
export default GamingProductos
<template>
    <section>
        <div class="row mx-0 px-5 py-3 border-bottom">
            <div class="col-xl-auto col-lg-6 col-md-12 text-xl-center text-lg-left text-md-center text-sm-center px-0">
                <p class="mb-0 text-general f-600 f-18">
                    Políticas de privacidad {{ textosDestinos.actual }}
                </p>
            </div>
            <div class="col-xl-auto col-lg-6 col-md-12 text-xl-center text-lg-right text-md-center text-sm-center px-0 ml-auto">
                <p v-if="visibilidadCompleta" class="mb-0 ml-auto f-15 cr-pointer text-general" @click="intentarCambiarDestino">
                    Ver las políticas de privacidad {{ textosDestinos.cambiar }}
                </p>
            </div>
        </div>
        <div class="overflow-auto custom-scroll content-politicas">
            <div class="row mx-0 justify-center pt-5">
                <p class="mb-0 f-22 f-600 text-general text-center">
                    Política de protección y tratamiento de datos personales {{ $config.proyecto }}
                </p>
            </div>
            <div class="row mx-0 justify-center">
                <p class="mb-0 w-25 f-15">
                    {{ $config.proyecto }}
                </p>
                <p class="mb-0 w-25 text-right f-15">
                    Actualizado: {{ tiempoActualizacion }}
                </p>
            </div>
            <div v-for="(f, i) in faqs" :key="i" class="overflow-auto custom-scroll">
                <div class="row mx-0 mt-4">
                    <div style="width:100%;">
                        <p class="mb-0 f-600 f-17 px-4" v-text="f.titulo" />
                    </div>
                </div>
                <div class="row mx-0 ">
                    <div style="width:100%;">
                        <p style="white-space:break-spaces;" class="mb-0 f-15 px-4" v-text="f.texto.texto" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import faqsMixin from '~/mixins/faqs/gestion'

const TIPO = 3

export default {
    name: 'AyudaPoliticasPrivacidad',
    mixins: [ faqsMixin ],
    mounted(){
        this.consultarFaqs(TIPO)
    }
}
</script>
<style lang="scss" scoped>
@media only screen  and (min-width : 1224px) {
    .content-politicas{
        height:calc(100vh - 150px) !important;
    }
}
@media only screen  and (max-width : 1224px) {
    .content-politicas{
        height:calc(100vh - 152px) !important;
    }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .content-politicas{
        height:calc(100vh - 148px) !important;
    }
}
</style>

import { render, staticRenderFns } from "./editarEmpresa.vue?vue&type=template&id=07806851&scoped=true&"
import script from "./editarEmpresa.vue?vue&type=script&lang=js&"
export * from "./editarEmpresa.vue?vue&type=script&lang=js&"
import style0 from "./editarEmpresa.vue?vue&type=style&index=0&id=07806851&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07806851",
  null
  
)

export default component.exports
<template>
    <section class="position-relative">
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center mb-2">
            <div class="btn-back" @click="irAtras">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto text-general f-300">
                Detalle del evento
            </div>
            <span class="text-general2 f-14 ml-auto mr-2">
                Evento activo
            </span>
            <el-switch
            v-model="estadoEvento"
            active-color="#000000"
            inactive-color="#DBDBDB"
            @change="putEstado"
            />
            <div class="btn-action border ml-2" @click="editarEvento">
                <i class="icon-pencil-outline f-20" />
            </div>
            <div class="btn-action border ml-2" @click="eliminarEvento">
                <i class="icon-delete-outline f-20" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col px-0 mr-2">
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 145px)">
                    <div class="row mx-0 bg-white br-12 px-3 py-3 mb-3">
                        <p class="col-12 text-general f-17 f-500">
                            {{ detalle.nombre }}
                        </p>
                        <p class="col-12 text-grey2 f-14 pl-4 my-2">
                            {{ detalle.descripcion }}
                        </p>
                        <div class="col-12 pl-4 mt-1">
                            <div class="row mx-0">
                                <i class="icon-calendar-clock f-20 text-general" />
                                <div class="col pr-0 px-3">
                                    <p class="text-general mt-1 f-15 mb-2">
                                        Vigencia:
                                    </p>
                                    <p class="text-general2 f-14 my-1">
                                        {{ detalle.fecha_inicio | helper-fecha('D MMMM [de] YYYY') }} - {{ detalle.fecha_fin | helper-fecha('D MMMM [de] YYYY') }}
                                    </p>
                                    <p class="text-general2 f-14 my-1 mt-4">
                                        Mostrar {{ detalle.dias_anticipo }} días antes del lanzamiento
                                    </p>
                                </div>
                                <i class="icon-cedis f-20 text-general ml-2" />
                                <div class="col pr-0">
                                    <p class="text-general mt-1 f-15 mb-1">
                                        Centros de distribución:
                                    </p>
                                    <template v-if="detalle.cedis.length > 0">
                                        <p v-for="cedi in detalle.cedis" :key="cedi.id_cedis" class="text-general2 f-14 my-1">
                                            {{ cedi.cedi }}
                                        </p>
                                    </template>
                                    <template v-else>
                                        <p class="text-general2 f-14 my-1">
                                            No registra
                                        </p>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 bg-white br-12 px-3 py-3 mb-3">
                        <p class="col-12 text-general f-17 f-500">
                            Usuario y metas
                        </p>
                        <div class="col-12 px-0 mb-4">
                            <div class="row mx-0 align-items-center pl-4 mt-3">
                                <div class="col-5 text-general d-middle my-2">
                                    <i class="icon-leechero-cap f-18" />
                                    <span class="ml-2">
                                        {{ detalle.usuario === 1 ? $config.cliente : $config.vendedor }}
                                    </span>
                                </div>
                                <div class="col">
                                    <i class="icon-currency-usd-circle text-general f-18" />
                                    <span class="ml-2 f-15 text-general">
                                        Tipo de evento:
                                    </span>
                                    <span class="ml-2 f-15 text-grey2">
                                        {{ tipoEvento(detalle.tipo) }}
                                    </span>
                                </div>
                            </div>
                            <div class="row mx-0 pl-4 mt-3 align-items-center">
                                <div class="col f-15 text-general f-500">
                                    {{ detalle.tipo == 11 || detalle.tipo == 21 ? 'Valor de las compras' : 'Pedidos' }}
                                </div>
                                <div class="col-auto mx-2">
                                    <img src="/img/gaming/i_moneda.svg" height="30" />
                                </div>
                                <div class="col-auto mx-2">
                                    <img src="/img/gaming/i_gema.svg" height="30" />
                                </div>
                                <div class="col f-15 text-general f-500 text-center">
                                    Ganadores
                                </div>
                            </div>
                            <div v-for="meta in detalle.metas" :key="meta.id" class="row mx-0 pl-4 mt-3 align-items-center">
                                <div class="col f-15 text-general f-500 d-middle">
                                    <span class="text-general f-400">
                                        Más de
                                    </span>
                                    <span v-if="detalle.tipo == 11 || detalle.tipo == 21" class="text-general f-15 f-500 ml-5">
                                        {{ separadorNumeroDecimales(meta.meta) }}
                                    </span>
                                    <span v-else class="text-general f-15 f-500 ml-5">
                                        {{ agregarSeparadoresNumero(meta.meta) }}
                                    </span>
                                </div>
                                <div class="col-auto mx-2 text-general f-15 f-500 text-right">
                                    {{ meta.monedas }}
                                </div>
                                <div class="col-auto mx-2 text-general f-15 f-500 text-right">
                                    {{ meta.gemas }}
                                </div>
                                <div class="col f-15 text-general f-500 text-center">
                                    {{ meta.ganadores }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0 ml-2">
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 145px)">
                    <img :src="detalle.bannerUrl != null ? detalle.bannerUrl : '/img/no-imagen/promociones.png'" width="100%" height="219" class="obj-cover br-4" />
                    <div class="row mx-0 my-2 bg-white br-12 py-3 my-3">
                        <div class="col border-right">
                            <p class="text-general f-33 f-500 text-center">
                                {{ agregarSeparadoresNumero(detalle.monedasGanadas) }}
                            </p>
                            <div class="row mx-0 mt-2 justify-center align-items-center">
                                <img src="/img/gaming/i_moneda.svg" height="25" />
                                <span class="text-general f-14 ml-2">
                                    Monedas ganadas
                                </span>
                            </div>
                        </div>
                        <div class="col border-right">
                            <p class="text-general f-33 f-500 text-center">
                                {{ agregarSeparadoresNumero(detalle.gemasGanadas) }}
                            </p>
                            <div class="row mx-0 mt-2 justify-center align-items-center">
                                <img src="/img/gaming/i_gema.svg" height="25" />
                                <span class="text-general f-14 ml-2">
                                    Gemas ganadas
                                </span>
                            </div>
                        </div>
                        <div class="col" @click="verGanadores">
                            <p class="text-general f-33 f-500 text-center">
                                {{ agregarSeparadoresNumero(detalle.ganadores) }}
                            </p>
                            <div class="row mx-0 mt-2 justify-center align-items-center">
                                <i class="icon-leechero-cap f-18 text-general" />
                                <span class="ml-2 text-general f-14">
                                    Ganadores
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-2 bg-white br-12 py-3 my-3">
                        <div class="col border-right">
                            <p class="text-general f-33 f-500 text-center">
                                {{ separadorNumeroDecimales(detalle.comprasRealizadas) }}
                            </p>
                            <div class="row mx-0 mt-2 justify-center align-items-center">
                                <i class="icon-currency-usd-circle text-general" />
                                <span class="text-general f-14 ml-2">
                                    Compras realizadas
                                </span>
                            </div>
                        </div>
                        <div class="col-3 border-right">
                            <p class="text-general f-33 f-500 text-center">
                                {{ detalle.pedidosTotales }}
                            </p>
                            <div class="row mx-0 mt-2 justify-center align-items-center">
                                <i class="icon-pedidos text-general" />
                                <span class="text-general f-14 ml-2">
                                    Pedidos
                                </span>
                            </div>
                        </div>
                        <div class="col-3">
                            <p class="text-general f-33 f-500 text-center">
                                {{ detalle.participantesPedidos }}
                            </p>
                            <div class="row mx-0 mt-2 justify-center align-items-center">
                                <i class="icon-leechero-cap f-18 text-general" />
                                <span class="ml-2 text-general f-14">
                                    Participantes
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-2 bg-white br-12 py-3 my-3">
                        <div class="col-12">
                            <p class="text-general f-18 f-500">
                                Participantes vs Dinero por cedis
                            </p>
                        </div>
                        <div class="col-12">
                            <echart :options="participantesDinero" />
                            <div v-if="graficaParticipantes.length < 1" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                            <div v-if="graficaParticipantes.length < 1" class="img-sindatos text-center">
                                <img class="obj-cover" src="/img/otros/no_datos.svg" />
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-2 bg-white br-12 py-3 my-3">
                        <div class="col-12">
                            <p class="text-general f-18 f-500">
                                Recompensas vs Ganadores por cedis
                            </p>
                        </div>
                        <div class="col-12">
                            <echart :options="recompensasDinero" />
                            <div v-if="graficaMonedas.length < 1" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                            <div v-if="graficaMonedas.length < 1" class="img-sindatos text-center">
                                <img class="obj-cover" src="/img/otros/no_datos.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalEliminar" titulo="Eliminar evento" no-aceptar :adicional="!eventoInactivo ? 'Inactivar' : 'Eliminar'" @adicional="deleteEvento">
            <div class="row mx-0 justify-center">
                <template v-if="eventoInactivo">
                    <div class="col-auto text-general f-15">
                        ¿Desea eliminar este evento?
                    </div>
                </template>
                <template v-else>
                    <div class="col-12 f-15 text-general text-center">
                        No es posible eliminar el evento, debido a que ya ha sido usado. 
                    </div>
                    <div class="col-12 f-15 text-general text-center mt-3">
                        Te recomendamos que inactives el evento
                    </div>
                </template>
            </div>
        </modal>
        <modal-ganadores-recompensas ref="ganadoresRecompensas" />
    </section>
</template>

<script>
import Service from "~/services/gaming/eventos/eventos";
import moment from 'moment';
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);

export default {
    components: {
        modalGanadoresRecompensas: () => import('./partials/modalGanadoresRecompensas')
    },
    data(){
        return {
            cargando: false,
            estadoEvento: false,
            detalle: {},
            graficaMonedas: [],
            graficaParticipantes: [],

            evento: false,
            eventoInactivo: true,
            participantesDinero: {
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        let bar = params[0]
                        let line = params[1]
                        return `${bar.axisValue} <br />
                        ${bar.marker} ${bar.value} participantes <br />
                        ${line.marker} $ ${this.agregarSeparadoresNumero(line.value)}
                        ` 
                    },
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#F3F3F3'
                        }
                    },
                    axisLabel: {
                        show: false
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        min: 0,
                    },
                    {
                        type: 'value',
                        position: 'right',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: '$ {value}'
                        }
                    }
                ],
                series: [
                    {
                        data: [],
                        type: 'bar',
                        barWidth: 40,
                        itemStyle: {
                            color: '#DBDBDB'
                        }
                    },
                    {
                        data: [],
                        type: 'line',
                        lineStyle: {
                            color: '#000000'
                        },
                        yAxisIndex: 1,
                        symbolSize: 10,
                        symbol: 'circle',
                        itemStyle: {
                            color: '#000000'
                        }
                    }
                ]
            },
            recompensasDinero: {
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        let monedas = params[0]
                        let gemas = params[1]
                        let ganadores = params[2]
                        return `${monedas.axisValue}: <br />
                        ${monedas.marker} ${monedas.value} Monedas <br />
                        ${gemas.marker} ${gemas.value} Gemas <br />
                        ${ganadores.marker} ${ganadores.value} Ganadores <br />
                        ` 
                    },
                    axisPointer: {
                        // Use axis to trigger tooltip
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#F3F3F3'
                        }
                    },
                    axisLabel: {
                        show: false,
                        color: '#6D6B6B'
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#F3F3F3'
                        }
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        min: 0,
                    },
                    {
                        type: 'value',
                        position: 'right',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                        },
                    }
                ],
                series: [
                    {
                        type: 'bar',
                        stack: 'total',
                        itemStyle: {
                            color: '#FFDA56'
                        },
                        data: [],
                    },
                    {
                        type: 'bar',
                        stack: 'total',
                        itemStyle: {
                            color: '#DD3B25'
                        },
                        data: []
                    },
                    {
                        type: 'line',
                        lineStyle: {
                            color: '#000000'
                        },
                        yAxisIndex: 1,
                        symbolSize: 10,
                        symbol: 'circle',
                        itemStyle: {
                            color: '#000000'
                        },
                        data: [],
                    }
                ],
            }
        }
    },
    computed: {
        id_evento(){
            return this.$route.params.id_evento
        },
    },
    created(){
        this.cargando = true;
        this.getDetalle();
    },
    methods: {
        async getDetalle(){
            try {
                const {data} = await Service.getDetalleEvento(this.id_evento)
                this.detalle = data.evento;
                this.estadoEvento = data.evento.estado === 1 ? true : false;
                this.graficaParticipantes = data.grafica_participantes;
                this.graficaMonedas = data.grafica_monedas;
                this.armarDataGraficas();
                
            } catch(e){
                this.cargando = false;
                this.error_catch(e)
            }
        },
        async putEstado(){
            try {
                this.cargando = true;
                const form = {
                    estado: this.estadoEvento === true ? 1 : 0
                };
                const {data} = await Service.putEstadoEvento(this.id_evento, form)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.detalle.estado = data.estado;
                this.cargando = false;
            } catch(e){
                this.cargando = false;
                this.error_catch(e)
            }
        },
        async deleteEvento(){
            try {
                this.cargando = true;
                const {data} = await Service.deleteEvento(this.id_evento)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.cargando = false;
                this.irAtras();
            } catch(e){
                this.cargando = false;
                this.error_catch(e)
            }
        },
        tipoEvento(tipo){
            switch(parseInt(tipo)){
            case 11:
                return "Compras en dinero"
            case 12:
                return "Compras en pedidos"
            case 21:
                return "Ventas en dinero"
            case 22:
                return "Ventas en pedidos"
            default:
                return "No registra"
            }
        },
        armarDataGraficas(){
            // -------  Grafica participantesDinero ----------
            let cedis = [];
            let participantes = [];
            let totales = [];
            let data = this.graficaParticipantes;
            for(let i = 0; i < data.length; i++){
                cedis.push(data[i].cedis);
                participantes.push(data[i].participantesPedidos);
                totales.push(data[i].totalPedidos);
            }
            // Asignamos la data que vamos a mostrar en la grafica
            this.participantesDinero.xAxis.data = cedis;
            this.participantesDinero.series[0].data = participantes;
            this.participantesDinero.series[1].data = totales;
            // -------- Grafica recompensasDinero ----------
            let cedis2 = [];
            let ganadores = [];
            let gemas = [];
            let monedas = [];
            let data2 = this.graficaMonedas;
            for(let i = 0; i < data2.length; i++){
                cedis2.push(data2[i].cedi);
                ganadores.push(data2[i].ganadores);
                gemas.push(data2[i].gemas);
                monedas.push(data2[i].monedas);
            }
            // Asignamos la data que vamos a mostrar en la grafica
            this.recompensasDinero.xAxis.data = cedis2;
            this.recompensasDinero.series[0].data = monedas;
            this.recompensasDinero.series[1].data = gemas;
            this.recompensasDinero.series[2].data = ganadores;
            this.cargando = false;
        },
        eliminarEvento(){
            this.$refs.modalEliminar.toggle();
        },
        editarEvento(){
            this.$router.push({name: 'admin.gaming.editar-evento', params:{id_evento: this.id_evento}});
        },
        verGanadores(){
            this.$refs.ganadoresRecompensas.toggle();
        },
        irAtras(){
            this.$router.push({name: 'admin.gaming.eventos'});
        },
    }
}
</script>
<style lang="scss" scoped>
.back{
    width: 20px;
    height: 20px;
    color: #FFFFFF;
    border-radius: 50%;
    background-color: #000000;
}
.f-33{
    font-size: 33px !important;
}
.img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
<template>
    <section class="pedidos-especiales mt-3">
        <div class="row mx-0 bg-white br-t-12 border-bottom">
            <div class="col">
                <div class="row mx-0 py-2 align-items-center" style="height:45px;">
                    <div class="col-xl-8 col-lg-5 col-md-5 col-sm-5 col-5 px-0">
                        <el-input v-model="buscarPedido" placeholder="Buscar Pedido" class="w-100 br-20" size="small" @input="mostrarConfirmados=true,mostrarSinConfirmar=true,filtro=1" />
                    </div>
                    <el-checkbox v-model="filtroLeechero" class="check-dark ml-auto">
                        <i class="icon-leechero-cap f-15" />
                    </el-checkbox>
                    <el-checkbox v-model="filtroCliente" class="check-dark">
                        <i class="icon-cliente f-15" />
                    </el-checkbox>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0 align-items-center" style="height:45px">
                    <p class="col px-0">
                        <span class="text-general f-500 f-18">ID. {{ activo.id }}</span>
                        <span class="text-general2 ml-2 f-17">
                            {{ estadoPedido(activo.estado) }}
                        </span>
                    </p>
                </div>
            </div>
            <div class="col">
                <div v-if="activo.estado==1" class="row mx-0 align-items-center justify-content-end" style="height:45px;">
                    <div class="btn-outline f-14 px-4" @click="abrirModalRechazarPedido(1)">
                        Rechazar
                    </div>
                    <div class="btn-general f-14 px-4 ml-2" @click="abrirModalConfirmarPedido">
                        Confirmar
                    </div>
                </div>
                <div v-if="activo.estado==2" class="row mx-0 align-items-center justify-content-end" style="height:45px;">
                    <div class="btn-outline f-14 px-4" @click="abrirModalRechazarPedido(2)">
                        Cancelar
                    </div>
                    <div class="btn-general f-14 px-4 ml-2" @click="abrirModalEntregarPedido">
                        Entregar
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 bg-white">
            <div class="col-4 px-0">
                <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 196px)">
                    <div class="mb-2">
                        <div class="box-info-pedido mx-3 px-3 text-general2 d-middle" @click="mostrarSinConfirmar=!mostrarSinConfirmar">
                            <span class="f-17 f-300">Sin confirmar</span>
                            <div class="pill-cant ml-auto d-middle-center px-2">
                                {{ sin_confirmar.length }}
                            </div>
                            <i :class="`icon-angle-${mostrarSinConfirmar ? 'up': 'down' }`" />
                        </div>
                        <div v-if="mostrarSinConfirmar">
                            <div v-for="(pedido,index) in sin_confirmar" :key="index" class="row cr-pointer mx-0 px-3 my-3 pl-3 pb-2 mb-3 border-bottom" @click="handlerIdPedido(pedido)">
                                <div :class="`col-auto br-20 px-1 ${activo.id == pedido.id ? 'bg-general': ''} `" />
                                <img :src="pedido.imagen" class="br-4 ml-2" width="96" height="96" />
                                <div class="col px-2">
                                    <div class="row mx-0 text-general">
                                        <p class="col px-0 f-500">
                                            Canje No. {{ pedido.id }}
                                        </p>
                                        <i :class="`icon-${pedido.tipo_user == 2 ? 'leechero-cap' : 'cliente' } f-18`" />
                                        <p class="col-12 px-0 tres-puntos">
                                            {{ pedido.nombre_producto }}
                                        </p>
                                    </div>
                                    <div v-if="pedido.monedas" class="row mx-0 align-items-center">
                                        <img src="/img/gaming/i_moneda.svg" height="28" />
                                        <div class="col px-2 text-general f-600">
                                            {{ pedido.monedas }}
                                        </div>
                                    </div>
                                    <div v-if="pedido.gemas" class="row mx-0 align-items-center">
                                        <img src="/img/gaming/i_gema.svg" height="28" />
                                        <div class="col px-2 text-general f-600">
                                            {{ pedido.gemas }}
                                        </div>
                                    </div>
                                    <div v-if="pedido.diario_dia" class="row mx-0 align-items-center">
                                        <img src="/img/gaming/i_ruleta.svg" height="28" />
                                        <span class="text-general2">Ruleta día </span>
                                        <div class="col px-2 text-general f-600">
                                            {{ pedido.diario_dia }}
                                        </div>
                                    </div>
                                    <div class="row mx-0 mt-1">
                                        <i class="icon-pedidos f-17 text-general2" />
                                        <p class="col px-2 text-general2 f-15">
                                            {{ estadoPedido(pedido.estado) }} {{ formatearFecha(pedido.updated_at) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="box-info-pedido mx-3 px-3 text-general2 d-middle" @click="mostrarConfirmados=!mostrarConfirmados">
                            <span class="f-17 f-300">Confirmados</span>
                            <div class="pill-cant ml-auto d-middle-center px-2">
                                {{ confirmados.length }}
                            </div>
                            <i :class="`icon-angle-${mostrarConfirmados ? 'up': 'down' }`" />
                        </div>
                        <div v-if="mostrarConfirmados">
                            <div v-for="(pedido,index) in confirmados" :key="index" class="row cr-pointer mx-0 px-3 my-3 pl-3 pb-2 mb-3 border-bottom" @click="handlerIdPedido(pedido)">
                                <div :class="`col-auto br-20 px-1 ${activo.id == pedido.id ? 'bg-general': ''} `" />
                                <img :src="pedido.imagen" class="br-4 ml-2" width="96" height="96" />
                                <div class="col px-2">
                                    <div class="row mx-0 text-general">
                                        <p class="col px-0 f-500">
                                            Canje No. {{ pedido.id }}
                                        </p>
                                        <i :class="`icon-${pedido.tipo_user == 2 ? 'leechero-cap' : 'cliente' } f-18`" />
                                        <p class="col-12 px-0 tres-puntos">
                                            {{ pedido.nombre_producto }}
                                        </p>
                                    </div>
                                    <div v-if="pedido.monedas" class="row mx-0 align-items-center">
                                        <img src="/img/gaming/i_moneda.svg" height="28" />
                                        <div class="col px-2 text-general f-600">
                                            {{ pedido.monedas }}
                                        </div>
                                    </div>
                                    <div v-if="pedido.gemas" class="row mx-0 align-items-center">
                                        <img src="/img/gaming/i_gema.svg" height="28" />
                                        <div class="col px-2 text-general f-600">
                                            {{ pedido.gemas }}
                                        </div>
                                    </div>
                                    <div v-if="pedido.diario_dia" class="row mx-0 align-items-center">
                                        <img class="mr-2" src="/img/gaming/i_ruleta.svg" height="28" />
                                        <span class="text-general f-600">Ruleta día </span>
                                        <div class="col px-2 text-general f-600">
                                            {{ pedido.diario_dia }}
                                        </div>
                                    </div>
                                    <div class="row mx-0 mt-1">
                                        <i class="icon-pedidos f-17 text-general2" />
                                        <p class="col px-2 text-general2 f-15">
                                            {{ estadoPedido(pedido.estado) }} {{ formatearFecha(pedido.updated_at) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal ref="modalConfirmarPedido" :titulo="'Confirmar pedido'" :adicional="'Confirmar'" no-aceptar @adicional="confirmarPedido">
                <p class="text-general text-center"> ¿Desea confirmar el pedido? </p>
            </modal>
            <modal ref="modalRechazarPedido" :titulo="rechazarOcancelar == 1 ? 'Rechazar pedido' : 'Cancelar pedido' " :adicional="rechazarOcancelar == 1 ? 'Rechazar' : 'Cancelar'" no-aceptar @adicional="cancelarORechazarPedido">
                <p class="text-general text-center"> ¿Desea {{ rechazarOcancelar == 1 ? 'rechazar' : 'cancelar' }} el pedido? </p>
                <div>
                    <span class="col-auto ml-3 f-12 text-general mb-2">Justificación</span>
                    <div class="col-12">
                        <ValidationObserver ref="validacion">
                            <ValidationProvider v-slot="{errors}" rules="required|max:300" name="jutificación">
                                <el-input v-model="justificacion" class="text-center justify-center" type="textarea" :rows="4" placeholder="Por favor justifica tu decisión" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </ValidationObserver>
                    </div>
                </div>
            </modal>
            <modal ref="modalEntregarPedido" :titulo="'Entregar pedido'" :adicional="'Entregar'" no-aceptar @adicional="entregarPedido">
                <p class="text-general text-center"> ¿Desea entregar el pedido? </p>
            </modal>
            <panel-detalle-pedido v-if="!isEmpty(activo)" :pedido="detalle" />
            <comentarios v-if="!isEmpty(activo)" :comentarios="comentarios" @update="getComentariosPedido(activo.id)" />
            <div v-else class="col text-center f-15 f-600 d-middle-center flex-column">
                <div class="d-middle-center bg-whitesmoke br-5" style="width: 115px; height: 115px;">
                    <img src="/img/no-imagen/click.svg" height="80" />
                </div>
                <p class="text-general2" style="width:200px"> Selecciona uno de los pedidos activos del panel de la izquierda. </p>
            </div>
        </div>
    </section>
</template>
<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import GamingPedidos from '@/services/gaming/gamingPedidos'
export default {
    components: {
        panelDetallePedido: () => import('./components/detallePedido.vue'),
        comentarios: () => import('./components/comentarios.vue')
    },
    data(){
        return {
            rechazarOcancelar: null,
            buscarPedido: '',
            filtroCliente: null,
            filtroLeechero: null,
            pedidos: { 
                confirmados: [],
                sin_confirmar: []
            },
            mostrarConfirmados: false,
            mostrarSinConfirmar: false,
            activo: {},
            detalle: {
                estados: [],
                user: {},
                responsable: {}
            },
            comentario: null,
            comentarios: [],
            justificacion: null,
            lastIdCedis: null
        }
    },
    computed: {
        confirmados(){
            if(this.buscarPedido) return this.pedidos.confirmados.filter(o => !this.buscarPedido || (String(o.id).toLowerCase().includes(this.buscarPedido.toLowerCase())))
            if(this.filtroCliente && !this.filtroLeechero) return this.pedidos.confirmados.filter(el=>el.tipo_user==5)
            if(this.filtroLeechero && !this.filtroCliente ) return  this.pedidos.confirmados.filter(el=>el.tipo_user==2)
            if(this.filtroCliente && this.filtroLeechero){
                return this.pedidos.confirmados
            }
            else{
                return this.pedidos.confirmados
            }
        },
        sin_confirmar(){
            if(this.buscarPedido) return this.pedidos.sin_confirmar.filter(o => !this.buscarPedido || (String(o.id).toLowerCase().includes(this.buscarPedido.toLowerCase())))
            if(this.filtroCliente && !this.filtroLeechero) return this.pedidos.sin_confirmar.filter(el=>el.tipo_user==5)
            if(this.filtroLeechero && !this.filtroCliente ) return  this.pedidos.sin_confirmar.filter(el=>el.tipo_user==2)
            if(this.filtroCliente && this.filtroLeechero){
                return this.pedidos.sin_confirmar
            }
            else{
                return this.pedidos.sin_confirmar
            }
        },
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        })
    },
    watch: {
        async id_cedis(val){
            if(this.lastIdCedis!==this.id_cedis) await this.getGamingPedidos()
            this.lastIdCedis = this.id_cedis
            
        },
    },
    mounted(){
        this.getGamingPedidos()
    },
    methods: {
        async getGamingPedidos(){
            try {

                this.activo = {}

                const { data } = await GamingPedidos.getGamingPedidos()

                this.pedidos = data.data  

                // miramos si hay un # de pedido enrutado

                if(this.$route.params.id_gaming_pedido){
                    let pedido = ((this.pedidos.sin_confirmar.find(el=>el.id==this.$route.params.id_gaming_pedido)) || (this.pedidos.confirmados.find(el=>el.id==this.$route.params.id_gaming_pedido)))
                    if(pedido)  this.handlerIdPedido(pedido)
                    
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        estadoPedido(estado){
            switch (estado){
            case 1:
                return "Sin confirmar";
            case 2:
                return "Confirmado"
            default:
                return "-"
            }
        },
        async handlerIdPedido(pedido){
            this.activo = pedido
            this.$router.push({params: { id_gaming_pedido: pedido.id }})
            await this.getDetallePedido(pedido.id)
            await this.getComentariosPedido(pedido.id)
        },
        async getDetallePedido(idPedido){
            try {
                const { data } = await GamingPedidos.getGamingPedido(idPedido)
                this.detalle = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async getComentariosPedido(idPedido){
            try {
                const { data } = await GamingPedidos.getComentariosPedido(idPedido)
                this.comentarios = {
                    data: data.data,
                    id_pedido: idPedido
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        abrirModalConfirmarPedido(){
            this.$refs.modalConfirmarPedido.toggle()
        },
        abrirModalRechazarPedido(origen){
            this.rechazarOcancelar = origen
            this.$refs.modalRechazarPedido.toggle()
        },
        abrirModalEntregarPedido(){
            this.$refs.modalEntregarPedido.toggle()
        },
        async confirmarPedido(){
            try {
                
                let params = {
                    id_pedido: this.activo.id,
                    estado: 2
                }
                const { data }  = await GamingPedidos.confirmarORechazarGamingPedido(params)
                
                //se borra de sin confirmar
                let index = this.pedidos.sin_confirmar.findIndex(el=>el.id == this.activo.id)
                this.pedidos.sin_confirmar[index].updated_at = moment()
                this.pedidos.sin_confirmar.splice(index,1)
                //se añade a confirmados
                this.pedidos.confirmados.splice(0,0,this.activo)
                this.activo.estado = 2
                // se actualiza el detalle
                this.getDetallePedido(this.activo.id)
                this.$refs.modalConfirmarPedido.toggle()   
                this.notificacion("","Pedido confirmado correctamente","success")
            } catch (error){
                this.error_catch(error)
            }
        },
        async entregarPedido(){
            try {
                let params = {
                    id_pedido: this.activo.id,
                    estado: 4
                }
                const { data }  = await GamingPedidos.confirmarORechazarGamingPedido(params)
                
                // se borra de confirmados
                let index = this.pedidos.confirmados.findIndex(el=>el.id == this.activo.id)
                this.pedidos.confirmados.splice(index,1)
                this.activo.estado = 4
                this.activo = {}
                // this.detalle = {}
                this.comentarios = []
                this.$refs.modalEntregarPedido.toggle()
                this.notificacion("","Pedido entregado correctamente","success")
            } catch (error){
                this.error_catch(error)
            }
        },
        async cancelarORechazarPedido(){
            try {

                let validate = await this.$refs.validacion.validate()
                if(!validate) return this.notificacion("","Campo justificación no valido","warning")
                    
                let params = {
                    id_pedido: this.activo.id,
                    estado: 21,
                    justificacion: this.justificacion ? this.justificacion : null
                }
                const { data }  = await GamingPedidos.cancelarGamingPedido(params)

                // se borra de confirmados o de sin_confirmar
                if(this.rechazarOcancelar==1){
                    let index = this.pedidos.sin_confirmar.findIndex(el=>el.id == this.activo.id)
                    this.pedidos.sin_confirmar.splice(index,1)
                }
                if(this.rechazarOcancelar==2){
                    let index = this.pedidos.confirmados.findIndex(el=>el.id == this.activo.id)
                    this.pedidos.confirmados.splice(index,1)
                }
                this.activo.estado = 21
                this.justificacion = null
                this.activo = {}
                this.$refs.modalRechazarPedido.toggle()
                this.notificacion("",`Pedido ${this.rechazarOcancelar == 1 ? 'rechazado': 'cancelado' } correctamente`,"success")
            } catch (error){
                this.error_catch(error)
            }
        },
        isEmpty(obj){
            return Object.keys(obj).length === 0;
        }
        
    }
}
</script>
<style lang="scss" scoped>
.box-info-pedido{
    height: 45px;
    background-color: #F5F5F5;
    border: 1px solid #DBDBDB;
    border-radius: 12px;
    .pill-cant{
        background-color: #FFFFFF;
        border-radius: 20px;
        height: 28px;
        min-width: 28px;
        color: #000000;
        font-weight: bold;
    }
}
</style>
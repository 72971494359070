<template>
    <section>
        <div class="row mx-0">
            <div class="border bg-white menu-prinicipal ayuda br-10">
                <div
                v-for="(menu, idx) in menus" :key="idx"
                class="d-flex mx-0 d-middle border-bottom cr-pointer"
                style="height:60px;"
                @click="enrutar(menu)"
                >
                    <div :class="`mx-2 px-1 h-80 br-5 ${menu.ruta==$route.name?'bg-general':''}`" />
                    <i :class="`${menu.icon} f-22 text-gr-general`" />
                    <div class="col-xl-8 col-lg-8 name-menu tres-puntos f-15">
                        {{ menu.nombre }}
                        <span v-if="menu.ruta==='tendero.ayuda.contactar'" class=" ml-2 br-10 bg-general3 text-white px-2">
                            {{ cant_msg }}
                        </span>
                    </div>
                    <i :class="`icon-angle-right f-18 ${menu.ruta==$route.name?'text-general':'text-gris'}`" />
                </div>
            </div>
            <div class="px-0 col border col br-t-10 menu-prinicipal-content ml-3">
                <!-- <component :is="activo" /> -->
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
import Chat from '~/services/chat/chat'

export default {
    sockets:{
        chats_tiendas(value){
            try {
                const {chat} = JSON.parse(value)
                if(parseInt(chat.id_tienda) === this.$usuario.tienda.id && this.$route.name !=='tendero.ayuda.contactar'){
                    this.notifiChat(3, chat)
                    this.mensajesSinLeer = this.mensajesSinLeer +1
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    middleware:['auth'],
    data(){
        return {
            activo: 'tipoTiendas',
            menus: [
                { nombre: 'Consultas más frecuentes', ruta: 'tendero.ayuda.frecuentes', icon: 'icon-help' },
                { nombre: `Quiero contactar con la App`, ruta: 'tendero.ayuda.contactar', icon: 'icon-forum-outline' },
                { nombre: 'Términos y condiciones', ruta: 'tendero.ayuda.terminos-condiciones', icon: 'icon-book-open-variant' },
                { nombre: 'Política de privacidad', ruta: 'tendero.ayuda.politica-privacidad', icon: 'icon-lock' },
            ],
            mensajesSinLeer: 0
        }
    },
    computed:{
        cant_msg(){
            return this.mensajesSinLeer
        }
    },
    mounted(){
        this.cantidadMensajesSinLeer()
        this.menus[1].nombre = `Quiero contactar con ${this.$config.proyecto}`
    },
    methods:{
        enrutar(menu){
            if(menu.ruta==='tendero.ayuda.contactar'){
                this.actualizarUltimaLectura()
                this.mensajesSinLeer = 0

            }
            this.$router.push({ name: menu.ruta })
        },
        async cantidadMensajesSinLeer(){
            try {
                const params = {
                    id : this.$usuario.tienda.id,
                    tipo: 3// 3= tipo tienda
                }
                const {data} = await Chat.saberCantidadMensajesSinLeer(params)
                this.mensajesSinLeer = data.cant_msg
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarUltimaLectura(){
            try {
                const enviar ={
                    id_dinamico: this.$usuario.tienda.id,
                    tipo: 3
                }
                const {data} = await Chat.actualizarUltimaLecturaChat(enviar)
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal-content{
    /* height: calc(100vh - 58px); */
    background: #fff;
}
</style>

<template>
    <section class="position-relative">
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <div class="btn-back mt-4" @click="$router.back()">
                <i class="icon-left f-12" />
            </div>
            <div class="ml-3" style="width:calc(100vw - 152px);height:calc(100vh - 93px)">
                <div class="row align-items-center mx-0 text-general br-t-8 py-3 bg-white">
                    <p class="col-auto f-18 f-400">
                        Lista de grupos
                    </p>
                    <div class="btn-general ml-auto cr-pointer px-3 f-14" @click="anadirGrupo">
                        Añadir grupo
                    </div>
                    <div class="col-3">
                        <el-input v-model="buscar" placeholder="Buscar" class="br-20 w-100" size="small" />
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <el-table
                        :data="grupos.filter(data => !buscar || data.nombre.toLowerCase().includes(buscar.toLowerCase()))"
                        header-row-class-name="text-general f-15"
                        style="width: 100%"
                        >
                            <el-table-column
                            prop="nombre"
                            label="Nombre del grupo"
                            width="180"
                            />
                            <el-table-column
                            prop="clientes"
                            :label="$config.cliente"
                            />
                            <el-table-column
                            prop="address"
                            width="180"
                            >
                                <template slot-scope="scope">
                                    <div class="row mx-0 justify-content-start">
                                        <div class="btn-action border mr-2" @click="verDescripcion(scope.row.nombre,scope.row.descripcion)">
                                            <el-tooltip class="item" effect="light" content="Descripción" placement="top-start">
                                                <i class="icon-info-circled-alt f-20" style="cursor:pointer;" />
                                            </el-tooltip>
                                        </div>
                                        <div class="btn-action border mr-2" @click="confirmarEliminar(scope.row.id)">
                                            <el-tooltip class="item" effect="light" content="Eliminar grupo" placement="top-start">
                                                <i class="icon-delete-outline f-20" style="cursor:pointer;" />
                                            </el-tooltip>
                                        </div>
                                        <div class="btn-action border" @click="irEditar(scope.row.id,scope.row.nombre,scope.row.descripcion)">
                                            <el-tooltip class="item" effect="light" content="Editar grupo" placement="top-start">
                                                <i class="icon-pencil-outline f-20" style="cursor:pointer;" />
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <modal ref="modalDescripcionGrupo" :titulo="grupoSelect" :no-aceptar="true" :no-cancelar="true" adicional="Cerrar" @adicional="modalDescripcion()">
            <div class="row mx-0 justify-center py-1">
                <div class="col-9">
                    <p>{{ descripcionSelect }}</p>
                </div>
            </div>
        </modal>
        <modal-confirmar ref="modalConfirmarEliminar" titulo="Eliminar grupo" :no-aceptar="true" adicional="Eliminar" @adicional="irEliminar()">
            <div class="row mx-0 justify-center">
                <div class="col-9">
                    <p class="text-general">¿Está seguro de eliminar este grupo?</p>
                </div>
            </div>
        </modal-confirmar>
        <!-- Partials -->
        <modal-anadir-grupo ref="modalAnadirGrupo" @get="getListarGrupos" />
        <modal-editar-grupo ref="modalEditarGrupo" @get="getListarGrupos" />
    </section>
</template>

<script>
import Referidos from "../../services/clientesEstadisticas/referidos";
export default {
    components: {
        modalAnadirGrupo: () => import('./partials/modalCrearGrupo'),
        modalEditarGrupo: () => import('./partials/modalEditarGrupo')
    },
    data(){
        return {
            cargando: true,
            grupos: [],
            info_editar: {},
            buscar: '',
            grupoSelect: '',
            descripcionSelect: '',
            id_eliminar: '',
        }
    },
    created(){
        this.getListarGrupos();
    },
    methods: {
        async getListarGrupos(){
            try {
                const { data } = await Referidos.getListarGrupos()
                this.grupos = data.grupos;
                this.cargando = false;
            } catch (error){
                this.error_catch(error)
            }
        },
        async irEditar(id,nombre,descripcion){
            const info = new Object();
            info.id = id;
            info.nombre = nombre;
            info.descripcion = descripcion;
            this.$refs.modalEditarGrupo.toggle(info);
        },
        confirmarEliminar(id){
            if (this.id_eliminar != ''){this.id_eliminar = ''}
            this.id_eliminar = id;
            this.$refs.modalConfirmarEliminar.toggle();
        },
        async irEliminar(){
            try {
                const { data } = await Referidos.deleteGrupo(this.id_eliminar)
                this.$refs.modalConfirmarEliminar.toggle();
                if (!data.operacion){
                    return this.notificacion('Mensaje', data.mensaje, data.tipo);
                }
                this.grupos = data.grupos;
                this.notificacion('Mensaje', data.mensaje, data.tipo);
            } catch (error){
                this.error_catch(error)
            }
        },
        verDescripcion(nombre,descripcion){
            this.grupoSelect = nombre;
            this.descripcionSelect = descripcion;
            this.modalDescripcion();
        },
        modalDescripcion(){
            this.$refs.modalDescripcionGrupo.toggle();
        },
        anadirGrupo(){
            this.$refs.modalAnadirGrupo.toggle();
        }
    }
}
</script>

import axios from 'axios'

const api = "gaming"

const UsuariosRecompensas = {

    getClientesRecompensas(params){
        return axios.get(`${api}/clientes-recompensas`,params)
    },
    getLeecherosRecompensas(params){
        return axios.get(`${api}/leecheros-recompensas`,params)
    },
    getDetalleRecompensasCliente(idUser){
        return axios.get(`${api}/${idUser}/detalle-recompensas-cliente`)
    },  
    getDetalleRecompensasLeechero(idUser){
        return axios.get(`${api}/${idUser}/detalle-recompensas-leechero`)
    },
    getHistorialRecompensasCliente(params){
        return axios.get(`${api}/historial-recompensas-cliente`,params)
    },
    getHistorialRecompensasLeechero(params){
        return axios.get(`${api}/historial-recompensas-leechero`,params)
    }

}

export default UsuariosRecompensas
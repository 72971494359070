import axios from 'axios'

const api = "recompensas"
const apiMetas = "recompensas/metas"

const GaminRecompensas = {
    listarCedisRecompensas(params){
        return axios.get(`${api}/listar-recompensas`,params)
    },
    listarCedisRecompensasCuentas(){
        return axios.get(`${api}/listar-recompensas-cuentas`)
    },
    listarCedisRecompensasReferidos(){
        return axios.get(`${api}/listar-recompensas-referidos`)
    },
    actualizarRecompensa(params){
        return axios.post(`${api}/actualizar-recompensas`,params)
    },

    //metas 

    mountedMetas(){
        return axios.get(`${apiMetas}/mounted`)
    },
    getMetas(params){
        return axios.get(`${apiMetas}/get-meta`,{params})
    },
    crearMeta(params){
        return axios.post(`${apiMetas}/crear-meta`,params)
    },
    editarMeta(params){
        return axios.put(`${apiMetas}/editar-meta`,params)
    },
    eliminarMeta(idMeta){
        return axios.delete(`${apiMetas}/${idMeta}/eliminar-meta`)
    },
    duplicarMeta(params){
        return axios.post(`${apiMetas}/duplicar-metas`,params)
    }

}
export default GaminRecompensas
import axios from "axios"

const api = "gaming/recompensas/visitas-diarias"

const visitasDiarias = {
    listarCedis: () => axios.get(`${api}/listar-cedis`),
    recompensasCedis: params => axios.get(`${api}/recompensas-cedis`, { params }),
    cambiarRecompensaFija: data => axios.post(`${api}/cambiar-recompensa-fija`, data),
    cambiarRecompensaDia: data => axios.post(`${api}/cambiar-recompensa-dia`, data),
    eliminarRecompensaDia: id => axios.delete(`${api}/eliminar-recompensa-dia/${id}`),
    crearRecompensaDia: data => axios.post(`${api}/crear-recompensa-dia`, data),
    eliminarDia: id => axios.delete(`${api}/eliminar-dia/${id}`),
    cambiarPosicion: data => axios.post(`${api}/cambiar-posicion`, data),
    duplicar: data => axios.post(`${api}/duplicar`, data),
}

export default visitasDiarias
<template>
    <section class="px-4 pt-3">
        <div class="row mx-0 bg-white align-items-center br-8 px-2 py-2 shadow-box">
            <cargando v-if="cargando" />
            <i v-show="rol != 'nomina'" class="icon-back f-20 text-general mr-2 cr-pointer" @click="$router.push({name: 'admin.nomina'})" />
            <img :src="!convenio.logo_ver ? '/img/no-imagen/no-imagen-empresa.svg' : convenio.logo_ver" class="obj-cover br-4" width="94" height="94" />
            <div class=" col pr-0">
                <p class="text-general f-18 f-600">
                    {{ convenio.nombre }}
                </p>
                <p class="text-gris2 f-14">
                    {{ convenio.ciudad }}, {{ convenio.pais }}
                </p>
                <p class="text-gris2 f-14 mt-2">
                    Código:
                </p>
                <p class="text-general f-14">
                    {{ convenio.codigo }}
                </p>
            </div>
            <div class="col-auto">
                <p class="text-general f-14"> Deudores </p>
                <div class="d-middle text-general">
                    <i class="icon-cedis f-17" />
                    <span class="f-20 f-600 ml-2">
                        {{ convenio.cantidad_deudores }}
                    </span>
                </div>
            </div>
            <div class="col-auto px-2">
                <p class="text-general f-14"> Pedidos </p>
                <div class="d-middle text-general">
                    <i class="icon-pedidos f-17" />
                    <span class="f-20 f-600 ml-2">
                        {{ convenio.cantidad_pedidos }}
                    </span>
                </div>
            </div>
            <div class="col-auto px-2">
                <p class="text-general f-14"> Deuda </p>
                <div class="d-middle text-general">
                    <i class="icon-cedis f-17" />
                    <span class="f-20 f-600 ml-2">
                        {{ separadorNumero(convenio.cantidad_deuda) }}
                    </span>
                </div>
            </div>
            <div class="col-auto cr-pointer" @click="leecheros">
                <p class="text-general f-14"> {{$config.vendedor}} </p>
                <div class="d-middle text-general">
                    <i class="icon-leechero-cap f-17" />
                    <span class="f-20 f-600 ml-2 ">
                        {{ convenio.cantidad_leecheros }}
                    </span>
                </div>
            </div>
            <div class="col-auto cr-pointer" @click="verDescuentos">
                <p class="text-general f-14"> Descuentos </p>
                <div class="d-middle text-general">
                    <i class="icon-leechero-cap f-17" />
                    <template v-if="descuentos.length > 0">
                        <span v-for="(item, index) in descuentos" :key="index" class="f-20 f-600 ml-2 ">
                            {{ agregarSeparadoresNumero(item.descuento) }}%<span v-if="index + 1 < descuentos.length" class="ml-1">-</span>
                        </span>
                    </template>
                    <template v-else>
                        <span class="f-20 f-600 ml-2">
                            0%
                        </span>
                    </template>
                </div>
            </div>
            <div class="col-auto px-2">
                <p class="text-general f-14"> Cupo </p>
                <div class="d-middle text-general tres-puntos">
                    <i class="icon-impuestos f-17" />
                    <span class="f-20 f-600 ml-2 tres-puntos">
                        {{ convenio.porcentaje_cupo }}%
                    </span>
                </div>
            </div>
            <div class="col-auto">
                <p class="text-general f-14"> Convenio </p>
                <div class="d-middle text-general">
                    <i class="icon-cedis f-17" />
                    <span class="text-green f-20 f-600 ml-2">
                        {{ convenio.estado === 1 ? 'Activo' : 'Inactivo' }}
                    </span>
                </div>
            </div>
            <div v-if="$can('boton_nomina_gestionar_empresa') && $usuario.rol === 1" class="col-auto px-0">
                <el-popover
                placement="bottom"
                trigger="click"
                width="200"
                popper-class="p-0 br-none"
                >
                    <div v-if="$can('boton_nomina_gestionar_empresa')" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="irEditar()">
                        Editar
                    </div>
                    <div v-if="$can('boton_nomina_gestionar_empresa')" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="editarLeecheros()">
                        Editar {{$config.vendedor}}
                    </div>
                    <div v-if="$can('boton_nomina_gestionar_empresa')" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="confirmarInactivarConvenio()">
                        {{ convenio.estado == 0 ? 'Activar convenio' : 'Inactivar convenio' }}
                    </div>
                    <div v-if="$can('boton_nomina_eliminar_empresa')" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="confirmarEliminarEmpresa()">
                        Eliminar
                    </div>
                    <i slot="reference" class="icon-dots-vertical f-20 text-general cr-pointer" />
                </el-popover>
            </div>
        </div>
        <div class="row mx-0 py-2" />
        <tabs :tabs="tabs" class="border-bottom mb-3" />
        <router-view />
        <!-- Partials -->
        <modal-leecheros ref="modalLeecheros" />
        <modal-descuentos ref="modalDescuentos" />
        <modal-editar-leecheros ref="modalEditarLeecheros" @actualizar="actualizarCantidadLeecheros" />
        <modal ref="modalInactivar" :titulo="convenio.estado == 1 ? 'Inactivar convenio' : 'Activar convenio'" no-aceptar :adicional="convenio.estado == 1 ? 'Inactivar' : 'Activar'" @adicional="putEstadoConvenio">
            <p v-if="convenio.estado === 1" class="text-center text-general my-3">
                ¿Desea inactivar el convenio con esta <br /> empresa?
            </p>
            <p v-else class="text-center text-general my-3">
                ¿Desea activar el convenio con esta <br /> empresa?
            </p>
        </modal>
        <modal ref="modalEliminar" titulo="Eliminar empresa" no-aceptar adicional="Eliminar" @adicional="eliminarEmpresa">
            <p class="text-center text-general my-3">
                ¿Desea eliminar esta empresa?
            </p>
        </modal>
    </section>
</template>

<script>
import Service from '~/services/nomina/nomina'
import {mapGetters} from 'vuex'
export default {
    components: {
        modalLeecheros: () => import('../partials/modalLeecheros'),
        modalDescuentos: () => import('../partials/modalDescuentos'),
        modalEditarLeecheros: () => import('../partials/modalEditarLeecheros')
    },
    data(){
        return {
            cargando: false,
            descuentos: [],
            tabsAdmin: [
                { titulo: 'Estadisticas', ruta: 'admin.nomina.ver.empresa.estadisticas'},
                { titulo: 'Afiliados', ruta: 'admin.nomina.ver.empresa.afiliados', can: 'tab_nomina_afiliacion' },
                { titulo: 'Solicitudes de afiliación', ruta: 'admin.nomina.ver.solicitud-afiliacion', can:'tab_nomina_afiliacion' },
                { titulo: 'Historial de liquidacion', ruta: 'admin.nomina.ver.historial-liquidacion', can:'tab_nomina_liquidadores' },
                { titulo: 'Liquidadores', ruta: 'admin.nomina.ver.liquidadores', can:'tab_nomina_liquidadores' }
            ],
            tabsLiquidador: [
                { titulo: 'Estadisticas', ruta: 'admin.nomina.ver.empresa.estadisticas' },
                { titulo: 'Afiliados', ruta: 'admin.nomina.ver.empresa.afiliados' },
                { titulo: 'Solicitudes de afiliación', ruta: 'admin.nomina.ver.solicitud-afiliacion' },
                { titulo: 'Historial de liquidacion', ruta: 'admin.nomina.ver.historial-liquidacion' },
            ],
            tabsContacto: [
                { titulo: 'Estadisticas', ruta: 'admin.nomina.ver.empresa.estadisticas'},
                { titulo: 'Afiliados', ruta: 'admin.nomina.ver.empresa.afiliados' },
            ],
        }
    },
    computed: {
        tabs(){
            if(this.$usuario.rol === 1){
                return this.tabsAdmin
            }
            if(this.$usuario.rol === 9 && this.$usuario.liquidador){
                return this.tabsLiquidador
            }
            if(this.$usuario.rol === 9 && this.$usuario.liquidador === 0){
                return this.tabsContacto
            }
            return []
        },
        id_convenio(){
            return this.$route.params.id_convenio
        },
        rol(){
            return this.$usuario.rol_nombre
        },
        ...mapGetters({
            convenio: `nomina/nomina/convenio`,
        })
    },
    mounted(){
        this.getEmpresaDetalle();
    },
    methods: {
        async getEmpresaDetalle(){
            try {
                this.cargando = true;
                if(!this.id_convenio)return
                const {data} = await Service.getEmpresaDetalle(this.id_convenio)
                this.$store.commit('nomina/nomina/setConvenio', data.empresa)
                this.descuentos = data.descuentos;
            } catch(e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        async putEstadoConvenio(){
            try {
                if(!this.id_convenio)return
                const {data} = await Service.putEstadoEmpresa(this.id_convenio)
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.convenio.estado = data.estado;
                this.$refs.modalInactivar.toggle();
            } catch(e){
                this.error_catch(e)
            }
        },
        async eliminarEmpresa(){
            try {
                if(!this.id_convenio)return
                const {data} = await Service.deleteEmpresa(this.id_convenio)
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.$refs.modalEliminar.toggle();
                this.$router.push({name:'admin.nomina.empresas-asociadas'});
            } catch(e){
                this.error_catch(e)
            }
        },
        irEditar(){
            if(!this.id_convenio)return
            this.$router.push({name:'admin.nomina.empresas.editar', params:{id_convenio: this.id_convenio}});
        },
        leecheros(){
            if(this.convenio.cantidad_leecheros > 0){
                this.$refs.modalLeecheros.toggle();
            }
        },
        verDescuentos(){
            if(this.descuentos.length > 0){
                this.$refs.modalDescuentos.toggle(this.descuentos);
            }
        },
        editarLeecheros(){
            this.$refs.modalEditarLeecheros.toggle();
        },
        confirmarInactivarConvenio(){
            this.$refs.modalInactivar.toggle();
        },
        confirmarEliminarEmpresa(){
            this.$refs.modalEliminar.toggle();
        },
        actualizarCantidadLeecheros(cantidad){
            console.log("cantidad: "+cantidad);
            this.convenio.cantidad_leecheros = cantidad;
        },
    }
}
</script>
<style lang="scss" scoped>
.shadow-box{
    box-shadow: 0px 1px 4px #00000014;
}

.item-option{
    height: 32px;
    border-radius: 4px;
    color: 13px;
    &:hover{
        background-color: #F6F9FB;
    }
}
</style>

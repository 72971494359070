<template>
    <section>
        <div class="row mx-0">
            <div class="border menu-prinicipal br-10" style="width:430px;">
                <div
                v-for="(menu, idx) in menus" :key="idx"
                class="row mx-0 d-middle border-bottom cr-pointer"
                style="height:60px;"
                @click="$router.push({ name: menu.ruta })"
                >
                    <div :class="`mx-2 px-1 h-80 br-5 ${menu.ruta==$route.name?'bg-gr-red':''}`" />
                    <i :class="`${menu.icon} f-22 text-gr-general`" />
                    <div class="col-9 tres-puntos f-15">
                        {{ menu.nombre }}
                    </div>
                    <i :class="`icon-angle-right f-18 ${menu.ruta==$route.name?'text-gr-red':''}`" />
                </div>
            </div>
            <div class="px-0 border col br-t-10 menu-prinicipal ml-3" style="width: calc(100vw - 595px);">
                <!-- <component :is="activo" /> -->
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            activo: 'tipoTiendas',
            menus: [
                { nombre: 'Preguntas más frecuentes', ruta: 'tendero-vip.info.preguntas', icon: 'icon-help' },
                { nombre: 'Términos y condiciones', ruta: 'tendero-vip.info.terminos', icon: 'icon-book-open-variant' },
                { nombre: 'Política de privacidad', ruta: 'tendero-vip.info.politica', icon: 'icon-lock' },
            ]
        }
    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal{
    height: calc(100vh - 58px);
    background: #fff;
}
</style>

<template>
    <section>
        <div class="row mx-0 bg-white br-t-12">
            <cargando v-if="cargando" />
            <div class="col-12 py-3 d-middle">
                <div class="col-auto text-general f-18 f-500">
                    Liquidaciones
                </div>
                <div class="col-3 ml-auto">
                    <el-input v-model="buscar" prefix-icon="icon-search" placeholder="Buscar liquidación" size="small" class="w-100 br-20" />
                </div>
            </div>
            <div class="col-12 px-0">
                <el-table
                :data="historial.filter(data => !buscar || JSON.stringify(data).toLowerCase().includes(buscar.toLowerCase()))"
                style="width: 100%;"
                height="calc(100vh - 378px)"
                header-row-class-name="text-general f-500 f-17 text-center"
                @row-click="verHistorial"
                >
                    <el-table-column
                    class-name="text-center"
                    width="80"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0 justify-center">
                                <i v-if="scope.row.estado === 3" class="icon-attention-alt f-17 text-green" />
                                <i v-if="scope.row.estado === 2" class="icon-ok-circled-outline f-17 text-green" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="id"
                    label="No."
                    class-name="text-center"
                    width="80"
                    />
                    <el-table-column
                    label="Fecha de corte"
                    class-name="text-center"
                    width="180"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.fecha_corte | helper-fecha('DD MMM YYYY') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Fecha de cobro"
                    class-name="text-center"
                    width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.fecha_descuento | helper-fecha('DD MMM YYYY') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="total_usuarios"
                    class-name="text-center"
                    label="Usuarios"
                    min-width="100"
                    />
                    <el-table-column
                    prop="total_pedidos"
                    class-name="text-center"
                    label="Pedidos"
                    min-width="100"
                    />
                    <el-table-column
                    label="Valor"
                    class-name="text-center"
                    min-width="150"
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.total_pedidos > 0">{{ separadorNumeroDecimales(scope.row.valor_total) }}</p>
                            <p v-else>$0</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Encargado"
                    class-name="text-center"
                    min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.usuario_verifico != null ? scope.row.usuario_verifico : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Fecha de liquidación"
                    class-name="text-center"
                    min-width="200"
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.fecha_verifico != null">
                                {{ scope.row.fecha_verifico | helper-fecha('DD MMM YYYY') }}
                            </p>
                            <p v-else>
                                -
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    min-width="180"
                    class-name="text-center"
                    label="Fecha de liquidacion"
                    >
                        <template slot="header">
                            <el-tooltip content="Porcentaje de entregas confirmadas por delivery" placement="bottom" effect="light">
                                <i class="icon-truck-check f-20 text-green" />
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <p v-if="scope.row.total_pedidos > 0">{{ porcentajesBordes(scope.row.porcentaje_entrega) }}%</p>
                            <p v-else>-</p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="col-12">
                <div v-if="totalLiquidacion > historial.length" class="row mx-0 justify-center bg-white py-3 br-b-12">
                    <div class="br-20 d-middle-center text-white bg-general3 f-14 px-3 cr-pointer" style="height:28px" @click="mostrarMas(10)">
                        Ver más 10
                    </div>
                    <div class="br-20 d-middle-center text-white bg-general3 f-14 px-3 cr-pointer ml-2" style="height:28px" @click="mostrarMas(50)">
                        Ver más 50
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Service from '~/services/nomina/historial_liquidacion'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            cargando: false,
            historial: [],
            totalLiquidacion: 0,
            limite: 20,
            buscar: '',
        }
    },
    computed: {
        id_convenio(){
            return this.$route.params.id_convenio
        },
        ...mapGetters({
            convenio: `nomina/nomina/convenio`,
        })
    },
    created(){
        this.getHistorial();
    },
    methods: {
        async getHistorial(){
            try {
                this.cargando = true;
                const params = {
                    limite: this.limite,
                    mostrarDesde: 0,
                };
                const {data} = await Service.getHistorial(this.id_convenio, params)
                this.historial = data.historial;
                this.totalLiquidacion = data.total_liquidaciones;
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        async filtroMostrarMas(){
            try {
                this.cargando = true;
                const params = {
                    limite: this.limite,
                    mostrarDesde: this.historial.length,
                };
                const {data} = await Service.getHistorial(this.id_convenio, params)
                this.totalLiquidacion = data.total_liquidaciones.length;
                if(data.historial.length > 0){
                    let contador = data.historial.length;
                    for(let i = 0; i < contador; i++){
                        this.historial.push(data.historial[i]);
                    }
                }
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        mostrarMas(cantidad){
            this.limite = cantidad;
            this.filtroMostrarMas();
        },
        verHistorial(row){
            this.$router.push({name: 'admin.nomina.empresas.liquidacion', params:{ id_convenio:this.id_convenio, id_liquidacion:row.id }});
        }
    }
}
</script>
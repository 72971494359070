<template>
    <section>
        <cargando v-if="cargando" />
        <div class="row mx-0 bg-white br-t-12">
            <div class="col-12 py-3 d-flex align-items-end">
                <div class="col-auto text-general f-18 f-500">
                    Solicitudes
                </div>
                <i class="icon-account-wait text-general f-18 mb-1 ml-auto" />
                <div class="col-auto text-general lh-18">
                    <small>Solicitudes</small>
                    <p class="text-general f-600 f-22">
                        {{ totalSolicitudesMostradas }}
                    </p>
                </div>
                <div class="col-3">
                    <el-input v-model="buscar" prefix-icon="icon-search" placeholder="Buscar afiliado" size="small" class="w-100 br-20" />
                </div>
            </div>
            <div class="col-12 px-0">
                <el-table
                :data="solicitudes.filter(data => !buscar || JSON.stringify(data).toLowerCase().includes(buscar.toLowerCase()))"
                style="width: 100%"
                height="calc(100vh - 413px)"
                header-row-class-name="text-general f-16"
                >
                    <el-table-column
                    prop="id"
                    label="Código"
                    width="100"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="cliente"
                    label="Nombre"
                    width="130"
                    />
                    <el-table-column
                    label="Deuda"
                    min-width="100"
                    >
                        <template slot-scope="scope">
                            <p>{{ separadorNumeroDecimales(scope.row.deuda) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Antigüedad"
                    min-width="100"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>{{ fechaAntiguedad(scope.row.fecha_vinculacion) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Fecha de registro"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.fecha_registro != null">{{ scope.row.fecha_registro | helper-fecha('DD MMM YYYY') }}</p>
                            <p v-else>-</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Fecha de solicitud"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.fecha_solicitud != null">{{ scope.row.fecha_solicitud | helper-fecha('DD MMM YYYY') }}</p>
                            <p v-else>-</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    width="250"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0 justify-center">
                                <div class="bg-general3 d-middle-center text-white f-14 px-3 br-20 cr-pointer f-14 mr-1" style="height:28px;" @click="irAceptarSolicitud(scope.row.id)">
                                    Aceptar
                                </div>
                                <div class="bg-fondo border d-middle-center text-general f-14 px-3 br-20 cr-pointer f-14 ml-1" style="height:28px;" @click="confirmarRechazarAfilizacion(scope.row.id)">
                                    Rechazar
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="col-12">
                <div v-if="totalSolicitudes > totalSolicitudesMostradas" class="row mx-0 justify-center bg-white py-3 br-b-12">
                    <div class="br-20 d-middle-center text-white bg-general3 f-14 px-3 cr-pointer" style="height:28px" @click="mostrarMas(10)">
                        Ver más 10
                    </div>
                    <div class="br-20 d-middle-center text-white bg-general3 f-14 px-3 cr-pointer ml-2" style="height:28px" @click="mostrarMas(50)">
                        Ver más 50
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalRechazarAfiliacion" titulo="Rechazar afiliacion" @guardar="rechazarAfiliacion">
            <div class="row mx-0">
                <div class="col-12 text-general text-center lh-18">
                    ¿Desea rechazar la solicitud <br /> de afiliación de este cliente?
                </div>
            </div>
        </modal>
        <modal-aceptar-afiliacion ref="modalAceptarAfiliacion" @aceptado="aceptarSolicitud" />
    </section>
</template>

<script>
import Service from '~/services/nomina/solicitudes'
import moment from 'moment'
export default {
    components: {
        modalAceptarAfiliacion: () => import('../partials/modalAceptarAfiliado'),
    },
    data(){
        return {
            cargando: false,
            solicitudes: [],
            totalSolicitudes: 0,
            totalSolicitudesMostradas: 0,
            buscar: '',
            limite: 20,
            mostrarDesde: 0,
            idSolicitud: '',
        }
    },
    computed: {
        id_convenio(){
            return this.$route.params.id_convenio
        },
    },
    mounted(){
        this.getSolicitudesAfiliados();
    },
    methods: {
        async getSolicitudesAfiliados(){
            try {
                this.cargando = true;
                const params = {
                    limite: 20,
                    mostrarDesde: 0,
                };
                const {data} = await Service.getSolicitudesAfiliados(this.id_convenio, params);
                this.solicitudes = data.solicitudes;
                this.totalSolicitudesMostradas = data.solicitudes.length;
                this.totalSolicitudes = data.total;
            } catch(e){
                this.error_catch(e);
            } finally {
                this.cargando = false;
            }
        },
        async filtroMostrarMas(){
            try {
                const params = {
                    limite: this.limite,
                    mostrarDesde: this.solicitudes.length
                };
                const {data} = await Service.getSolicitudesAfiliados(this.id_convenio, params);
                this.totalSolicitudes = data.total;
                if(data.solicitudes.length > 0){
                    let contador = data.solicitudes.length;
                    for(let i = 0; i < contador; i++){
                        this.solicitudes.push(data.solicitudes[i]);
                    }
                }
                this.totalSolicitudesMostradas = this.solicitudes.length;
            } catch(e){
                this.error_catch(e);
            } finally {
                this.cargando = false;
            }
        },
        mostrarMas(cantidad){
            this.limite = cantidad;
            this.filtroMostrarMas();
        },
        fechaAntiguedad(fecha){
            if(fecha === null || fecha === '')return '-';
            return moment(fecha).startOf('day').fromNow();
        },
        irAceptarSolicitud(id){
            let data = this.solicitudes.find((item) => item.id === id);
            this.$refs.modalAceptarAfiliacion.toggle(data);
        },
        async aceptarSolicitud(dataUsuario){
            try {
                this.cargando = true;
                const {data} = await Service.putAceptarSolicitud(dataUsuario);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                if(data.exito){
                    let index = this.solicitudes.findIndex(e => e.id === dataUsuario.id)
                    this.solicitudes.splice(index, 1);
                }
                this.$refs.modalAceptarAfiliacion.toggle(data);
            } catch(e){
                this.error_catch(e);
            } finally {
                this.cargando = false;
            }
        },
        confirmarRechazarAfilizacion(id){
            this.idSolicitud = id;
            this.$refs.modalRechazarAfiliacion.toggle();
        },
        async rechazarAfiliacion(){
            try {
                this.cargando = true;
                const {data} = await Service.putRechazarSolicitud(this.idSolicitud);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                if(data.exito){
                    let index = this.solicitudes.findIndex(e => e.id === this.idSolicitud)
                    this.solicitudes.splice(index, 1);
                }
            } catch(e){
                this.error_catch(e);
            } finally {
                this.idSolicitud = "";
                this.$refs.modalRechazarAfiliacion.toggle();
                this.cargando = false;
            }
        }
    }
}
</script>
<template>
    <section class="overflow-auto custom-scroll" style="height:calc(100vh - 297px)">
        <div class="row mx-0 bg-white br-8 py-3">
            <div class="col-2 border-right">
                <p class="text-general f-16 f-500 text-center">
                    Solicitudes <br /> Pendientes
                </p>
                <div class="row mx-0 justify-center">
                    <i class="icon-account-wait f-20 text-orange" />
                    <span class="mx-2 f-500 f-20 text-general">
                        {{ agregarSeparadoresNumero(dataGeneral.solicitudes || 0) }}
                    </span>
                </div>
            </div>
            <div class="col-2 border-right">
                <p class="text-general f-16 f-500 text-center">
                    Índice de <br /> cancelacion
                </p>
                <div class="row mx-0 justify-center">
                    <i class="icon-cancel-circled-outline f-20 text-general" />
                    <span class="mx-2 f-500 f-20 text-general-red">
                        {{ dataGeneral.porcentajeCancelacion }}%
                    </span>
                    <span class="mx-2 f-500 f-20 text-general">
                        ({{ agregarSeparadoresNumero(dataGeneral.cancelacion || 0) }})
                    </span>
                </div>
            </div>
            <div class="col-2 border-right">
                <p class="text-general f-16 f-500 text-center">
                    Índice de <br /> reclamacion
                </p>
                <div class="row mx-0 justify-center">
                    <i class="icon-support f-20 text-general" />
                    <span class="mx-2 f-500 f-20 text-general-red">
                        {{ dataGeneral.porcentajeReclamacion }}%
                    </span>
                    <span class="mx-2 f-500 f-20 text-general">
                        ({{ agregarSeparadoresNumero(dataGeneral.reclamacion || 0) }})
                    </span>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0 align-items-center">
                    <div class="col-auto px-0 f-500 f-16 f-500 text-general">
                        Calificaciones de los pedidos
                    </div>
                    <div class="col-auto">
                        <i class="icon-star text-warning f-20" />
                        <span class="text-general f-500">
                            {{ dataGeneral.calificaciones.promedio || 0 }}
                        </span>
                    </div>
                    <el-popover
                    placement="bottom"
                    width="215"
                    trigger="hover"
                    class="ml-auto"
                    popper-class="p-2 br-8"
                    content="Todos los datos estan calculados de acuerdo a los últimos 6 meses"
                    >
                        <div slot="reference" class="col-auto">
                            <i class="icon-help-circle-outline f-20 text-general ml-auto" />
                        </div>
                    </el-popover>
                </div>
                <div class="row mx-0">
                    <div v-for="stars in dataGeneral.stars" :key="stars.stars" class="col-auto pl-0 pr-2">
                        <div class="d-flex">
                            <i v-for="data in 5" :key="data" :class="data <= stars.stars ? 'text-warning' : 'text-grey'" class="icon-star f-15 " />
                        </div>
                        <p class="text-general f-500 text-center f-20">
                            {{stars.valor}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-liquid mt-4 mb-2">
            <p class="text-general f-600 f-18 pl-4">
                Liquidaciones
            </p>
            <div class="d-flex liquid-scroll custom-scroll pb-3">
                <!--  -->
                <div v-for="data in liquidaciones" :key="data.id" class="mr-4 mt-4" style="display:inline-block;">
                    <div class="card-liquid p-2">
                        <div class="row mx-0 text-white f-16 f-500 br-4 mb-2" :class="data.id ? 'bg-general' : 'bg-danger'">
                            <div class="col-auto">
                                {{ data.label }}
                            </div>
                        </div>
                        <div class="row mx-0 px-2 mb-2">
                            <div class="col px-0 text-general">
                                <span class="f-16 f-600">Liquidado:</span>
                                <span class="f-400">
                                    {{ formatearFecha(data.created_at) }}
                                </span>
                            </div>
                        </div>
                        <div class="row mx-0 px-2 mb-2">
                            <div class="col px-0 text-general">
                                <span class="f-16 f-600">Cobro:</span>
                                <span class="f-400">
                                    {{ formatearFecha(data.fecha_descuento) }}
                                </span>
                            </div>
                        </div>
                        <div class="row mx-0 align-items-center px-2">
                            <div class="col text-general px-0">
                                <el-tooltip placement="bottom" content="Pedidos" effect="light">
                                    <i class="icon-pedidos mr-2 f-18 text-general" />
                                </el-tooltip>
                                <span class="f-16">{{ agregarSeparadoresNumero(data.count_pedidos) }}</span>
                            </div>
                            <div class="col text-general px-0">
                                <el-tooltip placement="bottom" content="Clientes" effect="light">
                                    <i class="icon-cliente mr-2 f-18 text-general" />
                                </el-tooltip>
                                <span class="f-16">{{ agregarSeparadoresNumero(data.count_personas) }}</span>
                            </div>
                        </div>
                        <div class="row mx-0 align-items-center px-2 mt-2">
                            <div class="col text-general px-0">
                                <el-tooltip placement="bottom" effect="light">
                                    <div slot="content" style="width:150px">
                                        Porcentaje de entregas confirmadas por delivery
                                    </div>
                                    <i class="icon-truck-check f-18 mr-2 text-general" />
                                </el-tooltip>
                                <span class="f-16">{{ data.porcentaje }}%</span>
                            </div>
                            <div class="col text-general px-0 tres-puntos">
                                <el-tooltip placement="bottom" content="Dinero liquidado" effect="light">
                                    <i class="icon-comision f-14 mr-2 text-general" />
                                </el-tooltip>
                                <span class="f-16">{{ convertMoney(data.suma) }}</span>
                            </div>
                        </div>
                        <div v-if="data.estado == 2" class="row mx-0 mt-3 px-2">
                            <i class="icon-ok-circled-outline f-20 text-green" />
                            <div class="col px-2 pt-1">
                                <p class="text-green f-16">
                                    Verificado por leeche
                                </p>
                                <p class="text-gris2 f-16">
                                    {{ formatearFecha(data.fecha_cobro) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <graficas-estadisticas />
    </section>
</template>

<script>
import nominaDetalleEstadisticas from '~/services/nomina/nominaDetalleEstadisticas'
import moment from 'moment'

export default {
    components: {
        graficasEstadisticas: () => import('./components/graficas.vue')
    },
    data(){
        return {
            liquidaciones: [],
            dataGeneral: {},
        }
    },
    computed:{
        idConvenio(){
            return this.$route.params.id_convenio
        }
    },
    async mounted(){
        await this.init()
    },
    methods: {
        async init(){
            await this.datosGenerales()
            await this.getLiquidaciones()
        },
        async getLiquidaciones(){
            try {
                const { data } = await nominaDetalleEstadisticas.liquidaciones(this.idConvenio);
                this.liquidaciones = data.map(el => {
                    let label = `No. ${el.id}`

                    if (!(el.id)){
                        label = 'Sin liquidar' 
                    } else if (moment(el.fecha_descuento).isAfter(moment())){
                        label = `Próxima No. ${el.id}`
                    }

                    el.label = label
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async datosGenerales(){
            try {
                const { data } = await nominaDetalleEstadisticas.datosGenerales(this.idConvenio);
                this.dataGeneral = data
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.section-liquid{
    .liquid-scroll{
        overflow: auto;
        white-space:nowrap;
        .card-liquid{
            width: 280px;
            height: 247px;    
            border-radius: 8px;
            background-color: #FFFFFF;
            box-shadow: 0px 1px 4px #00000014;
        }
    }
}

.bg-danger{
    background-color: #FF1720;
}
.text-grey{
    color: #DFE4E8;
}
</style>
<template>
    <section class="position-relative">
        <cargando v-if="loading" />
        <div class="row mx-0 bg-white br-8 py-3">
            <div class="col-4 text-general">
                <p class="f-30 text-center f-500">
                    {{ agregarSeparadoresNumero(total_productos) }}
                </p>
                <div class="row mx-0 justify-center align-items-center text-center text-general">
                    <i class="icon-package-variant-closed f-20" />
                    Productos
                </div>
            </div>
            <div class="col-4 border-left text-general">
                <p class="f-30 text-center f-500">
                    {{ agregarSeparadoresNumero(total_unidades) }}
                </p>
                <div class="row mx-0 justify-center align-items-center text-center text-general">
                    <i class="icon-format-list-checks f-18" />
                    Unidades
                </div>
            </div>
            <div class="col border-left text-general">
                <p class="f-30 text-center f-500">
                    {{ separadorNumero(total_valor) }}
                </p>
                <div class="row mx-0 justify-center align-items-center text-center text-general">
                    <i class="icon-comision f-14 mr-2" />
                    Valor del stock
                </div>
            </div>
        </div>
        <div class="d-flex mx-0 bg-white br-t-8 mt-3">
            <div class="column-fixed border-right">
                <div class="col-12 px-0">
                    <div class="custom-scroll overflow-auto" style="height:calc(100vh - 242px)">
                        <div v-for="(cat, key) in categorias" :key="key" class="row cat-product cr-pointer mx-0 px-2 py-2 border-bottom" style="height:54px;" @click="selectCategoria(cat.id)">
                            <div :class="`${categoriaActiva == cat.id ? 'bg-general' : ''} px-1 br-20`" style="height:35px;width:6px;" />
                            <div class="col" :class="`${categoriaActiva == cat.id ? 'text-general' : 'text-general2'}`">
                                {{ cat.nombre }}
                            </div>
                            <div :class="`count${categoriaActiva == cat.id ? '-active' : ''} p-1`">
                                {{ cat.cant_productos }}
                            </div>
                            <i :class="`f-20 icon-angle-right ${categoriaActiva == cat.id ? 'text-general' : 'text-muted2'}`" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 pr-3" style="width:calc(100vw - 486px)">
                <div class="row mx-0 align-items-center border-bottom" style="height:53px;">
                    <div class="col-auto px-5" />
                    <div class="col-auto f-17 f-500 text-general">
                        Inventario
                    </div>
                </div>
                <div class="row">
                    <el-input v-model="buscar" placeholder="buscar" class="ml-auto col-4 my-2" size="small" />
                </div>
                <el-table
                :data="productos.filter(data => !buscar || data.nombre_completo.toLowerCase().includes(buscar.toLowerCase()))"
                style="width: 100%"
                height="calc(100vh - 315px)"
                header-cell-class-name="text-general f-16 f-500 bg-white"
                @row-click="historialInventario"
                >
                    <el-table-column width="80">
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <img :src="row.imagen" class="obj-cover br-4 border p-1" width="49" height="49" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nombre_completo" label="Producto" min-width="300" />

                    <el-table-column label="Precio Venta" min-width="180" align="center">
                        <template slot-scope="{row}">
                            <div class="mx-0">
                                {{ separadorNumero(row.valor) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cantidad_nueva" label="Unidades" width="180" align="center">
                        <template slot-scope="{row}">
                            <div class="mx-0">
                                {{ agregarSeparadoresNumero(row.cantidad_nueva) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Valor Inventario" min-width="180" align="center">
                        <template slot-scope="{row}">
                            <div class="mx-0">
                                {{ separadorNumero(row.valor_total) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Ultimo Ajuste" min-width="180">
                        <template slot-scope="{row}">
                            <div class="mx-0">
                                {{ row.created_at ? row.created_at : null | helper-fecha('DD-MMM-YYYY') }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!--Partials-->
            <modal-historial-movimiento ref="historialInventario" :id-lechero="idLechero" />
        </div>
    </section>
</template>

<script>
import Inventarios from "~/services/inventarios/lecheros"
export default {
    components: {
        modalHistorialMovimiento: () => import('../partials/modalHistorialMovimiento')
    },
    data(){
        return {

            buscar: '',
            lecheroActivo:{logo:'/img/no-imagen/sin_cliente.svg'},
            categoriaActiva:null,
            lecheros:[],
            categorias:[],
            total_productos:0,
            total_unidades:0,
            total_valor:0,
            productos:[],
            loading:false
        }
    },
    computed:{
        idLechero(){
            return this.$tienda.id
        }
    },
    mounted(){
        this.loadView()
    },
    methods: {
        async loadView(){
            try {
                this.loading = true
                const {data} = await Inventarios.getInfoXLechero(this.idLechero)
                this.categorias = data.categorias
                this.total_productos = data.total_productos
                this.total_unidades = data.total_unidades
                this.total_valor = data.total_valor
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async selectCategoria(id){
            try {
                this.loading = true
                this.categoriaActiva = id
                await this.getCategoriaLechero(id)
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async getCategoriaLechero(idCategoria){
            try {

                const {data} = await Inventarios.getCategoriaLechero(idCategoria, this.idLechero)
                this.productos = data.productos

            } catch (error){
                this.error_catch(error)
            }
        },
        historialInventario(row){
            this.$refs.historialInventario.showHistorial(row.id);
        }
    }
}
</script>
<style lang="scss" scoped>
.circled-user-avatar{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #F6F9FB;
}
.column-fixed{
    width: 393px;
}
.cat-product{
    align-items: center;
    .count{
        min-height: 28px;
        min-width: 28px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: #F5F5F5;
        color: #000000;
        &-active{
            @extend .count;
            background: #000000 !important;
            color: #ffffff !important;
        }
    }
}
</style>

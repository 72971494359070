<template>
    <section class="position-relative">
        <cargando v-if="cargando" />
        <div class="d-flex">
            <div class="px-0 pr-3" style="width:calc(100vw - 495px)">
                <div class="row mx-0 align-items-center mb-3">
                    <i class="icon-back f-28 text-general cr-pointer" @click="irAtras" />
                    <div class="col-auto f-18 text-general">
                        Liquidación <b>{{ liquidacion.id }}</b>
                    </div>
                    <div class="col-auto text-general f-18 f-500">
                        {{ liquidacion.convenio }}
                    </div>
                    <div class="col-auto d-middle ml-auto d mn-pill text-white px-2 br-20 f-14" :class="determinarColorEstado(liquidacion.estado)">
                        {{ determinarEstado(liquidacion.estado) }}
                    </div>
                    <div v-if="liquidacion.estado != 2 && $can('boton_nomina_cambio_estado_liquidacion')" class="px-2 mx-3">
                        <el-switch
                        v-model="activoLiquid"
                        class="switch-lquid"
                        active-color="#DFE4E8"
                        inactive-color="#DFE4E8"
                        />
                    </div>
                    <div v-if="liquidacion.estado != 2 && $can('boton_nomina_verificar_liquidacion')" class="btn-general px-2 f-14 mx-3" @click="$refs.modalAplicarPago.toggle()">
                        Verificar y pagar
                    </div>
                </div>
                <div class="row mx-0 bg-white br-8 px-2 py-3 align-items-center">
                    <img :src="liquidacion.convenio_logo" class="br-4 obj-cover" width="77" height="77" />
                    <div class="col-auto px-2">
                        <p class="text-general f-14">
                            Liquidados
                        </p>
                        <div class="row mx-0 align-items-center">
                            <i class="icon-cedis text-general" />
                            <span class="f-600 text-general f-20 ml-2">
                                {{ liquidacion.liquidados }}
                            </span>
                        </div>
                    </div>
                    <div class="col-auto px-2">
                        <p class="text-general f-14">
                            Pedidos
                        </p>
                        <div class="row mx-0 align-items-center">
                            <i class="icon-pedidos text-general" />
                            <span class="f-600 text-general f-20 ml-2">
                                {{ liquidacion.total_pedidos }}
                            </span>
                        </div>
                    </div>
                    <div class="col-auto px-2">
                        <p class="text-general f-14">
                            Valor liquidado
                        </p>
                        <div class="row mx-0 align-items-center">
                            <i class="icon-wallet text-general" />
                            <span class="f-600 text-general f-20 ml-2">
                                {{ separadorNumeroDecimales(liquidacion.total_liquidaciones) }}
                            </span>
                        </div>
                    </div>
                    <div class="col-auto px-2">
                        <p class="text-general f-14">
                            Fecha de cobro
                        </p>
                        <div class="row mx-0 align-items-center">
                            <i class="icon-calendar text-general" />
                            <span class="f-600 text-general f-20 ml-2">
                                {{ liquidacion.fecha_descuento | helper-fecha('DD MMM YYYY') }}
                            </span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <p class="text-general f-14">
                            Fecha de corte
                        </p>
                        <div class="row mx-0 align-items-center">
                            <i class="icon-calendar text-general" />
                            <span class="f-600 text-general f-20 ml-2">
                                {{ liquidacion.fecha_corte | helper-fecha('DD MMM YYYY') }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 bg-white br-8 px-3 py-3 my-3">
                    <img :src="liquidacion.usuario_foto" class="obj-cover rounded-circle" width="40" height="40" />
                    <div class="col pl-2">
                        <p class="text-general f-18 f-500">
                            {{ liquidacion.usuario }}
                        </p>
                        <p class="text-gris2 f-14">
                            Liquidado: <br />
                            {{ liquidacion.created_at | helper-fecha('DD MMM YYYY [-] h:mm a') }}
                        </p>
                    </div>
                    <template v-if="liquidacion.id_user_verifico != null">
                        <img :src="liquidacion.verificador_foto" class="obj-cover rounded-circle" width="40" height="40" />
                        <div class="col pl-2">
                            <p class="text-general f-18 f-500">
                                {{ liquidacion.verificador }}
                            </p>
                            <p class="text-gris2 f-14">
                                Verificado y pagado: <br />
                                {{ liquidacion.fecha_verifico | helper-fecha('DD MMM YYYY [-] h:mm a') }}
                            </p>
                        </div>
                        <template />
                    </template>
                </div>
                <liquidaciones :pedidos="pedidos" :fecha-liquidacion="liquidacion.created_at" />
            </div>
            <comentarios :comentarios="comentarios" />
        </div>
        <modalVerificarPagar ref="modalAplicarPago" :liquidacion="liquidacion" @update="getLiquidacion" />
    </section>
</template>

<script>
import Service from '~/services/nomina/liquidaciones'
export default {
    components: {
        comentarios: () => import('../components/comentarios.vue'),
        liquidaciones: () => import('../components/liquidaciones.vue'),
        modalVerificarPagar: () => import('../partials/modalVerificarYPagar.vue')
    },
    data(){
        return {
            cargando: false,
            cargandoComentarios: false,
            liquidacion: {},
            comentarios: [],
            pedidos: [],

            activoLiquid: false,
            buscar: '',
        }
    },
    computed: {
        id_convenio(){
            return this.$route.params.id_convenio
        },
        id_liquidacion(){
            return this.$route.params.id_liquidacion
        },
    },
    watch: {
        activoLiquid(){
            this.cambiarEstado();
        },
    },
    mounted(){
        this.getLiquidacion();
        this.getComentariosLiquidacion();
    },
    methods: {
        async getLiquidacion(){
            try {
                this.cargando = true;
                const params = {
                    id_convenio: this.id_convenio
                };
                const {data} = await Service.getLiquidacion(this.id_liquidacion, params)
                this.liquidacion = data.liquidacion;
                this.pedidos = data.pedidos;
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        async getComentariosLiquidacion(){
            try {
                this.cargandoComentarios = true;
                const {data} = await Service.getComentariosLiquidacion(this.id_liquidacion)
                this.comentarios = data.comentarios;
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargandoComentarios = true;
            }
        },
        async cambiarEstado(){
            try {
                if(this.liquidacion.estado === 2)return
                let estado = this.liquidacion.estado === 1 ? 3 : 1;
                const form = {
                    estado: estado
                };
                const {data} = await Service.putEstadoLiquidacion(this.id_liquidacion,form)
                this.liquidacion.estado = estado;
                this.notificacion('Mensaje', data.mensaje, data.tipo);
            } catch(e){
                this.error_catch(e)
            }
        },
        determinarColorEstado(state){
            switch (state){
            case 1:
                return 'pill-pendiente';
            case 2:
                return 'pill-verificado';
            case 3:
                return 'pill-aplicado';
            }
        },
        determinarEstado(state){
            switch (state){
            case 1:
                return 'Pendiente';
            case 2:
                return 'Verificado';
            case 3:
                return 'En revisión';
            }
        },
        irAtras(){
            this.$router.push({name:'admin.nomina.liquidaciones'});
        },

    }
}
</script>
<style lang="scss" scoped>
.section-chart{
    width: 412px !important;
}
.card-comment{
    border-bottom: 2px solid #DFE4E8;

    &.text-comment{
        word-break: break-all;
    }

}
.pill-pendiente{
    min-width: 90px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background-color: #000000;
}

.pill-verificado{
    min-width: 90px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background-color: #29D884;
}

.pill-aplicado{
    min-width: 90px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background-color: #FF5806;
}
</style>

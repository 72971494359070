import axios from 'axios'

const API = "gaming/eventos"

const ENDPOINTS = {
    getEventos(id){
        return axios.get(`${API}/${id}/listado`)
    },
    getInfoCrearEvento(){
        return axios.get(`${API}/crear/info`)
    },
    postEvento(form){
        return axios.post(`${API}/crear`,form)
    },
    getDetalleEvento(id){
        return axios.get(`${API}/${id}/detalle`)
    },
    getEventoEditar(id){
        return axios.get(`${API}/${id}/editar-info`)
    },
    putEstadoEvento(id, form){
        return axios.put(`${API}/${id}/estado`,form)
    },
    deleteEvento(id){
        return axios.delete(`${API}/${id}/eliminar`)
    },
    deleteCediEvento(id){
        return axios.delete(`${API}/${id}/eliminar-cedi`)
    },
    deleteRecompensaEvento(id){
        return axios.delete(`${API}/${id}/eliminar-recompensa`)
    },
    putEvento(id, form){
        return axios.put(`${API}/${id}/editar`, form)
    },
    getCarpetas(){
        return axios.get(`${API}/carpetas`)
    },
    postCarpeta(form){
        return axios.post(`${API}/carpeta/crear`,form)
    },
    deleteCarpeta(id){
        return axios.delete(`${API}/${id}/carpeta/eliminar`)
    },
    putCarpeta(id,form){
        return axios.put(`${API}/${id}/carpeta/actualizar`,form)
    },
}
export default ENDPOINTS
<template>
    <section :class="`${$route.name == 'tendero.metricas.estadisticas-equipo' ? 'mt-2' : ''}`" :style="`${$route.name == 'tendero.metricas.estadisticas-equipo' ? 'border-radius:12px 12px 0px 0px;' : ''}`">
        <div v-if="$route.name != 'tendero.metricas.estadisticas-equipo'" class="row mx-0 align-items-center border-bottom pb-3 px-3">
            <div class="bg-general3 text-white rounded-circle cr-pointer d-middle-center" style="width:24px;height:24px;" @click="$router.push({name: 'admin.equipos'})">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto text-general  f-16 f-600">
                {{ team.nombre }}
            </div>
            <div class="col ml-auto" />
            <div v-if="$can('botones_equipos_gestionar')" class="col-auto px-0 ml-auto">
                <div class="btn-action border">
                    <i class="icon-pencil-outline text-general f-18 cr-pointer" @click="editarDatos()" />
                </div>
            </div>
            <div v-if="$can('botones_equipos_gestionar')" class="col-auto px-0 ml-2">
                <div class="btn-action border">
                    <i class="icon-delete-outline text-general f-20 cr-pointer" @click="eliminarEquipo()" />
                </div>
            </div>
            <div v-if="$can('botones_equipos_gestionar')" class="col-auto px-0 ml-2">
                <div class="btn-action border">
                    <i class="icon-configuracion text-general f-20 cr-pointer" @click="configurarEquipo()" />
                </div>
            </div>
            <div class="col-auto">
                <el-tooltip placement="bottom" :content="team.descripcion" effect="light">
                    <i class="icon-info-circled-alt text-general f-20" />
                </el-tooltip>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 162px)">
            <div class="row mx-0 align-items-center my-4 py-2 bg-white br-8 py-1 mr-3 ml-3">
                <div class="col ml-3">
                    <p class="text-general f-500 f-18">
                        Inventario del equipo
                    </p>
                    <p class="text-general2">
                        {{ team.compartir_lider === 1 ? 'Usan el inventario del lider' : 'No usan el inventario del lider' }}
                    </p>
                </div>
                <div class="col-2 border-lef border-right">
                    <p class="f-30 text-general text-center f-600">
                        {{ agregarSeparadoresNumero(inventario.productos) }}
                    </p>
                    <p class="text-general f-400 text-center f-17">
                        <i class="icon-package-variant-closed f-17" />
                        Productos
                    </p>
                </div>
                <div class="col border-right">
                    <p class="f-30 text-general text-center f-600">
                        {{ agregarSeparadoresNumero(inventario.unidades) }}
                    </p>
                    <p class="text-general f-400 text-center f-17">
                        <i class="icon-format-list-checks f-17" />
                        Unidades
                    </p>
                </div>
                <div class="col">
                    <p class="f-30 text-general text-center f-600">
                        {{ separadorNumero(inventario.valores) }} COP
                    </p>
                    <p class="text-general f-400 text-center f-17">
                        <i class="icon-cash f-14 text-general mr-2" />
                        Valor del Stock
                    </p>
                </div>
            </div>
            <div class="row mx-0 px-3 py-3">
                <div v-for="(data, d) in team.tiendas" :key="d" class="mr-2" style="width:80px;">
                    <el-tooltip placement="bottom" :content="data.nombre" effect="light">
                        <div class="row mx-0 justify-center cr-pointer" @click="agregarLeecheros(data)">
                            <div :class="`${data.destacado ? 'border-active' : ''} br-5 active px-1 py-1 d-middle-center position-relative`" style="width:80px;height:80px;">
                                <div v-if="data.principal" class="bg-general3 rounded-circle position-absolute d-middle-center" style="width:15px;height:15px;top:6px;right:7px;">
                                    <i class="icon-star f-12 text-orange" />
                                </div>
                                <img :src="data.imagen" class="obj-cover br-4 border" height="72" width="72" />
                            </div>
                        </div>
                    </el-tooltip>
                    <p class="text-general nombre-equipo f-14 py-2 text-center px-1">
                        {{ data.nombre }}
                    </p>
                </div>
                <div class="col-auto d-middle-center pb-5">
                    <div class="bg-general px-3 py-1 f-14 br-8 text-white cr-pointer mb-1" @click="montarGraficas">
                        Mostrar gráficas
                    </div>
                </div>
            </div>
            <cliente ref="graficas" :leecheros="ids_leecheros" />
        </div>

        <!-- Partials -->
        <modal-integrantes-equipos ref="modalIntegrantesEquipo" />
        <modal-editar-datos ref="modalEditarDatosEquipos" @actualizar="infoTeam" />
        <modal-configurar-equipo ref="modalConfigurarEquipo" />
        <modal-eliminar ref="modalEliminarEquipo" titulo="Eliminar Equipo" mensaje="¿Desea eliminar este equipo?" @eliminar="deleteTeam" />
    </section>
</template>

<script>
import Teams from "~/services/teams";
import { mapGetters } from 'vuex'
export default {
    components: {
        modalIntegrantesEquipos: () => import('./partials/modalIntegrantesEquipo'),
        modalEditarDatos: () => import('./partials/modalEditarDatosEquipo'),
        modalConfigurarEquipo: () => import('./partials/modalConfigurarEquipo'),
        cliente: () => import('./clientes/index'),
    },
    data(){
        return{
            team: {
                created_at: null,
                created_by: null,
                descripcion: null,
                id: null,
                idPrincipal: null,
                idsTiendas: null,
                nombre: null,
                tiendas: [],
                updated_at: null,
                updated_by: null,
            },
            ids_leecheros: [],
            id_eliminar: null,
            inventario: {
                productos: 0,
                unidades: 0,
                valores: 0,
            }
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
        idTeam(){
            if(this.$usuario && (this.$usuario.rol == 2 || this.$usuario.rol == 4))return this.$tienda.id_team
            return this.$route.params.id_equipo
        },
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
    },
    created(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_moneda === null){
                    setTimeout(async() => {
                        await this.infoTeam()
                    }, 1000);
                }else{
                    await this.infoTeam()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async infoTeam(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                }
                const { data } = await Teams.infoTeam(this.idTeam, params)
                data.team.tiendas.map(el => el.destacado = false)
                this.team = data.team
                this.inventario = data.inventario
            } catch (error){
                this.error_catch(error)
            }
        },
        integrantesEquipo(){
            this.$refs.modalIntegrantesEquipo.toggle();
        },
        editarDatos(){
            this.$refs.modalEditarDatosEquipos.toggle(this.team);
        },
        eliminarEquipo(){
            this.$refs.modalEliminarEquipo.toggle();
        },
        async deleteTeam(){
            try {
                const {data} = await Teams.deleteTeam(this.team.id)
                this.notificacion('', data.mensaje, 'success')
                this.$refs.modalEliminarEquipo.toggle()
                this.$router.push( { name: 'admin.equipos' } )
            } catch (e){
                this.error_catch(e)
            }
        },
        configurarEquipo(){
            this.$refs.modalConfigurarEquipo.toggle(this.team);
        },
        montarGraficas(){
            this.$refs.graficas.montar()
        },
        agregarLeecheros(leechero){
            leechero.destacado = !leechero.destacado
            if (leechero.destacado){
                this.ids_leecheros.push(leechero.id)
            } else {
                let i = this.ids_leecheros.findIndex(el => el == leechero.id)
                this.ids_leecheros.splice(i,1)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.border-active{
    border: 1px solid var(--color-general) !important;
}
.nombre-equipo{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 16px;
}
</style>

<template>
    <section>
        <p class="text-general f-400 mb-5">
            Define la cantidad de monedas y gemas que recibirá cada usuario en el momento de verificar su correo electrónico, su cuenta de Facebook y su cuenta de Google.
        </p>
        <div v-for="(cedi,index) in datos" :key="index" class="row mx-0 mb-5">
            <p class="px-0 text-general f-15 f-500 mb-2">
                {{ cedi.nombre }}
            </p>
            <div style="display:inline-flex;">
                <div class="col-auto px-4" />
                <div class="col-auto px-0 mr-5">
                    <div style="height:40px;" />
                    <div class="d-flex pb-1 align-items-end mb-3" style="height:50px;">
                        <p class="text-general2 f-500"> {{$config.cliente}} </p>
                    </div>
                    <div class="d-flex pb-1 align-items-end" style="height:50px;">
                        <p class="text-general2 f-500">
                            {{$config.vendedor}}
                        </p>
                    </div>
                </div>
                <div v-for="(recom,inde) in cedi.recompensas" :key="inde" class="space-count mr-4">
                    <div class="d-middle-center" style="height:40px;">
                        <p class="f-15 text-general f-500">{{ tipoRecompensa(recom.tipo) }}</p>
                    </div>
                    <div class="row mx-0 align-items-end mb-3">
                        <img src="/img/gaming/i_moneda.svg" class="mb-1" height="27" />
                        <div class="col">
                            <p class="label-mini">Monedas</p>
                            <el-input v-model="recom.cliente_monedas" placeholder="Cantidad" size="small" class="w-100" @change="actualizarRecompensa(cedi.id,1,recom.cliente_monedas,recom.tipo)" />
                        </div>
                        <img src="/img/gaming/i_gema.svg" class="mb-1" height="27" />
                        <div class="col">
                            <p class="label-mini">Gemas</p>
                            <el-input v-model="recom.cliente_gemas" placeholder="Cantidad" size="small" class="w-100" @change="actualizarRecompensa(cedi.id,2,recom.cliente_gemas,recom.tipo)" />
                        </div>
                    </div>
                    <div class="row mx-0 align-items-end">
                        <img src="/img/gaming/i_moneda.svg" class="mb-1" height="27" />
                        <div class="col">
                            <p class="label-mini">Monedas</p>
                            <el-input v-model="recom.tienda_monedas" placeholder="Cantidad" size="small" class="w-100" @change="actualizarRecompensa(cedi.id,3,recom.tienda_monedas,recom.tipo)" />
                        </div>
                        <img src="/img/gaming/i_gema.svg" class="mb-1" height="27" />
                        <div class="col">
                            <p class="label-mini">Gemas</p>
                            <el-input v-model="recom.tienda_gemas" placeholder="Cantidad" size="small" class="w-100" @change="actualizarRecompensa(cedi.id,4,recom.tienda_gemas,recom.tipo)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import GamingRecompensas from '~/services/gaming/gamingrecompensas'

export default {
    data(){
        return {
            datos: [{
                recompensas:{
                    cliente_monedas:0,
                    cliente_gemas:0,
                    tiendas_monedas:0,
                    tiendas_gemas:0
                }
            }]
        }
    },
    mounted(){
        this.listarCedisRecompensas()
    },
    methods:{
        async listarCedisRecompensas(){
            try {
                const { data } = await GamingRecompensas.listarCedisRecompensasCuentas()
                this.datos = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        tipoRecompensa(tipo){
            switch (tipo){
            case 23:
                return "Correo electrónico"
            case 24:
                return "Facebook"
            case 25:
                return "Google"
            case 26:
                return "iOS"
            default:
                break;
            }
        },
        async actualizarRecompensa(idCedis,campo,valor,tipo){
            try {
                if(valor>=65535) return this.notificacion("","El valor debe ser igual o menor a 65.535")
                let variable
                if(campo == 1) variable = "cliente_monedas"
                if(campo == 2) variable = "cliente_gemas"
                if(campo == 3) variable = "tienda_monedas"
                if(campo == 4) variable = "tienda_gemas"
                let params = {
                    id_cedis: idCedis,
                    valor: valor,
                    campo: variable,
                    tipo: tipo
                }
                const { data } = await GamingRecompensas.actualizarRecompensa(params)
                this.notificacion("","Recompensa actualizada","success")
                params = null
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.label-mini{
    font-size: 12px;
    padding-left: 15px;
    color: #000000;
}
.title-sticky{
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 0;
    display:inline-block
}
.space-count{
    width: 350px;
}
/* .placeholder{
    outline: 1px solid red;
} */
</style>
<template>
    <section>
        <p class="text-general f-400 mb-4">
            Define la cantidad de monedas y gemas que recibirá cada usuario de acuerdo al valor de la compra.
        </p>
        <div class="row mx-0">
            <i class="icon-cedis text-general" />
            <div class="col-4">
                <el-select v-model="cediSelected" size="small" class="w-100" @change="cambiarCedis">
                    <el-option
                    v-for="(c,index) in cedis"
                    :key="index"
                    :label="c.nombre"
                    :value="c.id"
                    />
                </el-select>
            </div>
            <el-tooltip placement="bottom" effect="light">
                <div slot="content">
                    <p class="text-center">
                        Duplicar configuracion <br /> a otro cedis
                    </p>
                </div>
                <div class="btn-action border" @click="abrirModalDuplicarMeta">
                    <i class="icon-content-duplicate f-17" />
                </div>
            </el-tooltip>
        </div>
        <div class="row mx-0 border-bottom my-4" :style="`${!cediSelected ? 'pointer-events:none;opacity:0.5' : ''}`">
            <div class="col-auto cr-pointer p-2" :class="vistaActiva == 1 ? 'border-b-black text-general' : 'text-general2'" @click="cambiarVistaUsuario(1)">
                {{$config.cliente + 's'}}
            </div>
            <div class="col-auto cr-pointer p-2" :class="vistaActiva == 2 ? 'border-b-black text-general' : 'text-general2'" @click="cambiarVistaUsuario(2)">
                {{$config.vendedor + 's'}}
            </div>
        </div>
        <div class="row mx-0 border-bottom my-4" :style="`${!vistaActiva ? 'pointer-events:none;opacity:0.5' : ''}`">
            <div v-for="(fecha, index) in fechas" :key="index" class="col-auto cr-pointer p-2" :class="fechaActiva == index ? 'border-b-black text-general' : 'text-general2'" @click="fechaSelected(fecha.mes,fecha.ano,index)">
                {{ fecha.mes_nombre+'/'+fecha.ano }}
            </div>
        </div>
        <div v-if="fechaActiva!=null">
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto px-3" />
                <div class="col-autof 18 text-general f-500">
                    Metas de los {{ vistaActiva == 1 ? $config.cliente + 's' : $config.vendedor + 's' }}
                </div>
                <el-tooltip placement="bottom" content="Añadir meta" effect="light">
                    <div class="btn-red ml-2" @click="crearMetaObjeto">
                        <i class="icon-plus f-18" />
                    </div>
                </el-tooltip>
            </div>
            <div v-for="(meta,index) in metas" :key="index" class="row mx-0 align-items-end mb-3">
                <div class="col">
                    <div class="row mx-0 align-items-end">
                        <span class="text-general f-15 pb-1">
                            Más de
                        </span>
                        <div class="col">
                            <p class="label-mini">
                                Pedidos
                            </p>
                            <el-input v-model="meta.meta" :disabled="editar!=index" size="small" class="w-100" placeholder="Cantidad" />
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0 align-items-end">
                        <img src="/img/gaming/i_moneda.svg" class="mb-1" height="20" />
                        <div class="col">
                            <p class="label-mini">
                                Monedas
                            </p>
                            <el-input v-model="meta.monedas" :disabled="editar!=index" size="small" class="w-100" placeholder="Cantidad" />
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0 align-items-end">
                        <img src="/img/gaming/i_gema.svg" class="mb-1" height="20" />
                        <div class="col">
                            <p class="label-mini">
                                Gemas
                            </p>
                            <el-input v-model="meta.gemas" :disabled="editar!=index" size="small" class="w-100" placeholder="Cantidad" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <el-tooltip v-if="editar!=index" placement="bottom" content="Editar meta" effect="light">
                        <div class="btn-action border mr-2" @click="editar=index">
                            <i class="icon-pencil-outline f-18" />
                        </div>
                    </el-tooltip>
                    <el-tooltip v-if="editar==index" placement="bottom" content="Guardar meta" effect="light">
                        <div v-if="meta.nuevo" class="btn-action border mr-2" @click="crearMeta(meta.meta,meta.monedas,meta.gemas)">
                            <i class="icon-ok-circled-outline f-18 text-green" />
                        </div>
                        <div v-if="!meta.nuevo" class="btn-action border mr-2" @click="editarMeta(meta.id,meta.meta,meta.monedas,meta.gemas)">
                            <i class="icon-ok-circled-outline f-18 text-green" />
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Eliminar meta" effect="light">
                        <div class="btn-action border" :style="`width:30px; ${meta.nuevo ? 'pointer-events:none;opacity:0.5;': ''}`" @click="eliminarMeta(meta.id,index)">
                            <i class="icon-delete-outline f-18" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div v-else class="text-center f-15 f-600 d-middle-center flex-column">
            <img src="/img/no-imagen/click.svg" class="d-middle-center bg-whitesmoke br-5 mb-2" style="height:200px;width:200px;" />
            <p class="col-3">Seleccione una fecha de arriba para visualizar las metas</p>
        </div>
        <modal ref="modalDuplicarMeta" titulo="Duplicar metas" adicional="Duplicar" no-aceptar @adicional="duplicarMeta">
            <p class="text-general text-center mb-2"> Selecciona los perfiles que desea duplicar </p>

            <div class="row mx-0 justify-center align-items">
                <el-checkbox v-model="paraCliente" class="check-dark mr-2" />
                <label class="text-general mr-2">{{$config.cliente}}</label>
                <el-checkbox v-model="paraLeechero" class="check-dark mr-2" />
                <label class="text-general mr-2">{{$config.vendedor}}</label>
            </div>

            <p class="text-general text-center mt-2 mx-0"> Selecciona los cedis a los que se duplicarán estos parámetros </p>

            <div class="mx-0 ml-1 ">
                <div class="col">
                    <div v-for="(cedi,indexC) in cedis" :key="indexC" class="mt-2">
                        <el-checkbox v-model="cedi.checked" class="check-dark mr-2" />
                        <span class="text-general mr-2">{{ cedi.nombre }}</span>
                    </div>
                </div>
            </div>

            <p class="text-general text-center mt-2 mx-0"> Selecciona los meses a los que se aplicarán estos parámetros </p>

            <div class="mx-0 ml-1 ">
                <div class="col">
                    <div v-for="(fecha,indexF) in fechas" :key="indexF" class="mt-2">
                        <el-checkbox v-model="fecha.checked" class="check-dark mr-2" />
                        <span class="text-general mr-2">{{ mes(fecha.mes,fecha.ano) | ucfirst }}</span>
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import moment from 'moment'
import GamingRecompensas from '@/services/gaming/gamingrecompensas'
export default {
    data(){
        return {
            paraCliente: false,
            paraLeechero: false,
            vistaActiva: null,
            fechaActiva: null,
            fechas: [],
            cedis: [],
            metas: [],
            cediSelected: null,
            mesSelected: null,
            anoSelected: null,
            editar: null,
            moneda: '',
            gema: '',
            valor_compra: ''
        }
    },
    mounted(){
        this.listarMounted()
    },
    methods: {
        async listarMounted(){
            try {
                const { data } = await GamingRecompensas.mountedMetas()
                this.fechas = data.data
                this.cedis = data.cedis
            } catch (error){
                this.error_catch(error)
            }
        },
        crearMetaObjeto(){
            let obj = {
                meta: null,
                gemas: null,
                monedas: null,
                nuevo: true
            }
            this.editar = 0
            this.metas.unshift(obj)
        },
        fechaSelected(mes,ano,index){
            this.fechaActiva = index
            this.mesSelected = mes
            this.anoSelected = ano
            this.getMeta()
        },
        cambiarVistaUsuario(val){
            this.vistaActiva = val
            this.getMeta()
        },
        cambiarCedis(){
            if(this.fechaActiva!=null){
                this.getMeta()
            }
        },
        async getMeta(mes=this.mesSelected,ano=this.anoSelected,idCedis=this.cediSelected){
            try {

                let params = {
                    mes: mes,
                    ano: ano,
                    id_cedis: idCedis,
                    usuario: this.vistaActiva,
                    tipo: 12
                }

                const { data } = await GamingRecompensas.getMetas(params)

                this.metas = data.data
            } catch (error){
                this.error_catch(error)
            }
        },
        async editarMeta(idMeta,meta,monedas,gemas){
            try {

                if(!Number(meta) || !Number(monedas) || !Number(gemas)) return this.notificacion('','El valor ingresado es incorrecto')

                let params = {
                    meta: meta,
                    monedas: monedas,
                    gemas: gemas,
                    id_meta: idMeta
                }
                const { data } = await GamingRecompensas.editarMeta(params)
                this.editar = null

                this.notificacion('','Meta editada correctamente','success')
            } catch (error){
                this.error_catch(error)
            }
        },
        async crearMeta(meta,monedas,gemas){
            try {

                if(!Number(meta) || !Number(monedas) || !Number(gemas)) return this.notificacion('','El valor ingresado es incorrecto')

                let params = {
                    meta: meta,
                    monedas: monedas,
                    gemas: gemas,
                    id_cedis: this.cediSelected,
                    mes: `${this.anoSelected}-${this.mesSelected}-01`,
                    usuario: this.vistaActiva,
                    tipo: 12
                }
                const { data } = await GamingRecompensas.crearMeta(params)
                this.editar = null

                this.getMeta()
                this.notificacion('','Meta creada correctamente','success')
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarMeta(idMeta,index){
            try {
                const { data } = await GamingRecompensas.eliminarMeta(idMeta)

                this.metas.splice(index,1)
                this.notificacion('','Meta eliminada correctamente','success')
            } catch (error){
                this.error_catch(error)
            }
        },
        abrirModalDuplicarMeta(){
            if(this.cediSelected && this.vistaActiva && this.mesSelected){
                this.$refs.modalDuplicarMeta.toggle()
            }else{
                return this.notificacion('','Por favor seleccione una fecha')
            }
        },
        mes(val,ano){
            return moment(`${ano}-${val}-01`).format('MMMM/YYYY')
        },
        async duplicarMeta(){

            let usuario = [this.paraCliente,this.paraLeechero].some(el=>el==true)
            let cedis = this.cedis.some(el=>el.checked==true)
            let fechas = this.fechas.some(el=>el.checked==true)
            if(!usuario && !cedis && !fechas) return this.notificacion("","Por favor completa los datos")

            try {

                let params = {
                    leechero: this.paraLeechero,
                    cliente: this.paraCliente,
                    cedis: this.cedis.filter(el=>el.checked==true).map(el=>el.id),
                    fechas: this.fechas.filter(el=>el.checked==true),
                    //para el query
                    id_cedis: this.cediSelected,
                    mes: this.mesSelected,
                    ano: this.anoSelected,
                    usuario: this.vistaActiva,
                    tipo: 12
                }
                const { data } = await GamingRecompensas.duplicarMeta(params)

                this.notificacion('','Metas duplicadas correctamente','success')

                this.cedis = this.cedis.map(function(el){
                    el.checked = false
                    return el
                })
                this.fechas = this.fechas.map(function(el){
                    el.checked = false
                    return el
                })
                this.$refs.modalDuplicarMeta.toggle()
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.label-mini{
    font-size: 12px;
    padding-left: 15px;
    color: #000000;
}
</style>

import axios from 'axios'

const API = 'soporte/novedades'

const NOVEDADES = {
    listarNovedades(payload = {}){
        return axios(`${API}/listar-novedades`, { params: payload });
    },
}

export default NOVEDADES

import axios from 'axios'

const API = "nomina/liquidaciones"

const ENDPOINTS = {
    getLiquidaciones(params){
        return axios.get(`${API}/listado`, {params})
    },
    getComentariosLiquidacion(id){
        return axios.get(`${API}/${id}/comentarios`)
    },
    postComentarioLiquidacion(params){
        return axios.post(`${API}/comentario/crear`, params)
    },
    putEstadoLiquidacion(id, form){
        return axios.put(`${API}/${id}/estado`, form)
    },
    getLiquidacion(id, params){
        return axios.get(`${API}/${id}/detalle`,{params})
    },
}
export default ENDPOINTS

<template>
    <section class="pt-4 custom-scroll overflow-auto" style="height:calc(100vh - 183px); position:relative;">
        <cargando v-if="loading" />
        <div class="row mx-0">
            <div class="col d-flex align-items-end">
                <div>
                    <p class="text-general f-15 f-600 mb-2 text-center">Pedidos</p>
                    <div class="box-pedidos p-2 px-3 br-4">
                        <i class="icon-shopping f-20" />
                        <span class="f-600">{{ _.defaultTo(data.todosPedidos, 0) }}</span>
                    </div>
                </div>
                <div class="ml-2">
                    <p class="text-general f-15 f-600 mb-2 text-center">Reclamos</p>
                    <div class="box-clamos p-2 px-3 br-4">
                        <i class="icon-attention-alt f-20" />
                        <span class="f-600">{{ _.defaultTo(data.reclamosPedidos, 0) }}</span>
                    </div>
                </div>
                <el-tooltip placement="bottom" content="Índice de reclamos" effect="light">
                    <div class="border ml-2 br-4 text-general2 f-15 f-600 px-2 py-2 d-middle-center" style="height:47px;">
                        Indice {{ porcentajeIndice }}%
                    </div>
                </el-tooltip>
            </div>
            <div class="col">
                <p class="text-general text-center f-15 mb-2 f-600">
                    Cancelaciones y rechazos
                </p>
                <div class="row mx-0">
                    <el-tooltip placement="bottom" content="Cancelación de la tienda" effect="light">
                        <div class="col mx-1 box-cancelaciones text-general2 f-600 br-4 d-middle-center">
                            <i class="icon-cliente f-22" />
                            {{ _.defaultTo(data.canceladoTiendaPedidos, 0) }}
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Cancelación del cliente" effect="light">
                        <div class="col mx-1 box-cancelaciones text-general2 f-600 br-4 d-middle-center">
                            <i class="icon-leechero-cap f-22" />
                            {{ _.defaultTo(data.canceladoClientePedidos, 0) }}
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Pedidos rechazados" effect="light">
                        <div class="col mx-1 box-cancelaciones text-general2 f-600 br-4 d-middle-center">
                            <i class="icon-playlist-remove f-22" />
                            {{ _.defaultTo(data.rechazadoPedidos, 0) }}
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="row mx-0 py-3" />
        <div v-for="(pedido, i) in pedidos" :key="i" class="row mx-3 border br-4 py-1 px-2 align-items-center mb-3">
            <img :src="pedido.logo_mini" width="46" height="46" class="obj-cover br-4" />
            <div class="col">
                <p class="text-general f-15 f-600 tres-puntos">{{ separadorNumero(pedido.valor_final) }}</p>
                <p class="text-general f-15 tres-puntos">{{ pedido.propietario_nombre }}</p>
            </div>
            <div class="col">
                <p class="text-general f-15 f-600 tres-puntos">No. {{ pedido.id }}</p>
                <p class="text-general f-15 tres-puntos">{{ formatearFecha(pedido.created_at) }}</p>
            </div>
            <div class="col">
                <div class="row mx-0 text-general align-items-center">
                    <i class="icon-package-variant-closed f-18 text-general" />
                    <b>{{ agregarSeparadoresNumero(pedido.count_productos) }}</b>
                </div>
                <div class="row mx-0 text-general align-items-center">
                    <i class="f-18 text-general" :class="estadoIcono(pedido.estado).icon" />
                    {{ estadoIcono(pedido.estado).nombre }}
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div class="bg-general3 px-3 mx-1 py-1 br-20 text-white cr-pointer" @click="fetchData(10)">
                Ver más 10
            </div>
            <div class="bg-general3 px-3 mx-1 py-1 br-20 text-white cr-pointer" @click="fetchData(50)">
                Ver más 50
            </div>
        </div>
    </section>
</template>

<script>
import estadistica from '~/services/clientes'
import { mapGetters } from 'vuex'

const iconos = {

    1: {icon: 'icon-receipt', nombre: 'Creado'},
    2: {icon: 'icon-ok-circled', nombre: 'Confirmado'},
    201: {icon: 'icon-cart-arrow-down', nombre: 'Alistando'},
    202: {icon: 'icon-shopping-outline', nombre: 'Empacado'},
    3: {icon: 'icon-truck-fast-outline', nombre: 'Enviado'},
    4: {icon: 'icon-shopping', nombre: 'Finalizado'},
    110: {icon: 'icon-ok-circled', nombre: 'Ajustes Tienda en Proceso'},
    11: {icon: 'icon-ok-circled', nombre: 'Ajustes de Tienda'},
    12: {icon: 'icon-ok-circled', nombre: 'Ajustes confirmados'},
    13: {icon: 'icon-ok-circled', nombre: 'Confirmado con Cambios Cliente'},
    21: {icon: 'icon-ok-circled', nombre: 'Timeout Creado'},
    22: {icon: 'icon-ok-circled', nombre: 'Timeout Confirmado'},
    23: {icon: 'icon-ok-circled', nombre: 'Timeout Cambios Tendero'},
    24: {icon: 'icon-ok-circled', nombre: 'Timeout Cambios Cliente'},
    25: {icon: 'icon-shopping', nombre: 'Rechazado'},
    26: {icon: 'icon-shopping', nombre: 'Cancelado Tienda'},
    27: {icon: 'icon-shopping', nombre: 'Cancelado Cliente'},
    31: {icon: 'icon-shopping', nombre: 'Reclamo'},
    32: {icon: 'icon-shopping', nombre: 'Reclamo Finalizado'},
    33: {icon: 'icon-shopping', nombre: 'Soporte'},
    34: {icon: 'icon-shopping', nombre: 'Soporte Finalizado'},
}
export default {
    data(){
        return {
            url_image: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg',
            data: {},
            pedidos: [],
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
        porcentajeIndice(){
            if(_.isEmpty(this.data)) return 0
            return Math.round((this.data.reclamosPedidos * 100) / this.data.todosPedidos)
            
        }
    },
    watch:{
        id_moneda(val, oldVal){
            if(oldVal == null) return
            setTimeout(() => {
                this.pedidos = []
                this.fetchData()
            }, 500);
        }
    },
    mounted(){
        setTimeout(() => {
            this.fetchData()
        }, 500);
    },
    methods: {
        async fetchData(paginate = 10){
            try {
                this.loading = true
                let params = {
                    id: this.$route.params.id_cliente,
                    offset: this.pedidos.length,
                    paginate,
                    moneda: this.id_moneda
                }
                const { data } = await estadistica.pedidosRealizados(params)
                this.data = data
                this.pedidos = [...this.pedidos, ...data.pedidos]
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        estadoIcono(estado){
            return iconos[estado] ?? iconos[1]
        }
    }
}
</script>
<style lang="scss" scoped>
.box-pedidos{
    background-color: #AAFFC9;
    color: var(--color-general2);
}
.box-clamos{
    background-color: #FFB8BB;
    color: var(--color-general2);
}
.box-cancelaciones{
    background-color: #FFCD97;
    height: 44px;
}
</style>
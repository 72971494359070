<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 281px)">
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center mb-3">
            <div class="col-auto text-general f-18 f-500">
                Liquidadores activos
            </div>
            <div class="mn-pill text-white bg-general3 px-3">
                {{ activosTotal }}
            </div>
            <div v-if="$can('boton_nomina_gestionar_liquidador')" class="btn-general px-4 f-14 cr-pointer ml-auto" @click="crearLiquidador">
                Crear liquidador
            </div>
        </div>
        <div class="row mx-0">
            <template v-if="activos.length > 0">
                <div v-for="(liquidador,index) in activos" :key="index" class="card-liquidadores d-middle py-3 px-3 mr-3 mb-4" @click="verLiquidador(liquidador.id,'activo')">
                    <img :src="liquidador.usuario_foto" class="obj-cover br-4" width="86" height="86" />
                    <div class="col">
                        <p class="text-general f-16">{{ liquidador.usuario }}</p>
                        <p class="text-gris2 mt-2 f-14">{{ liquidador.cargo }}</p>
                        <div class="row mx-0 mt-2">
                            <el-tooltip v-if="liquidador.contacto === 1" placement="bottom" content="Tipo contacto" effect="light">
                                <i class="icon-support f-20 text-general" />
                            </el-tooltip>
                            <el-tooltip v-if="liquidador.liquidador === 1" placement="bottom" content="Tipo liquidador" effect="light">
                                <i class="icon-account-check f-20 text-green ml-2" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="col">
                    <small class="text-general2">
                        No existen liquidadores activos
                    </small>
                </div>
            </template>
        </div>
        <div class="row mx-0 align-items-center mb-3 mt-5">
            <div class="col-auto text-general f-18 f-500">
                Liquidadores inactivos
            </div>
            <div class="mn-pill text-white bg-general3 px-3">
                {{ inactivosTotal }}
            </div>
        </div>
        <div class="row mx-0">
            <template v-if="inactivos.length > 0">
                <div v-for="(liquidador,index) in inactivos" :key="index" class="card-liquidadores d-middle py-3 px-3 mr-3 mb-4" @click="verLiquidador(liquidador.id,'inactivo')">
                    <img :src="liquidador.usuario_foto" class="obj-cover br-4" width="86" height="86" />
                    <div class="col">
                        <p class="text-general f-16">{{ liquidador.usuario }}</p>
                        <p class="text-gris2 mt-2 f-14">{{ liquidador.cargo }}</p>
                        <div class="row mx-0 mt-2">
                            <el-tooltip v-if="liquidador.contacto === 1" placement="bottom" content="Tipo contacto" effect="light">
                                <i class="icon-support f-20 text-general" />
                            </el-tooltip>
                            <el-tooltip v-if="liquidador.liquidador === 1" placement="bottom" content="Tipo liquidador" effect="light">
                                <i class="icon-account-check f-20 text-green ml-2" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="col">
                    <small class="text-general2">
                        No existen liquidadores inactivos
                    </small>
                </div>
            </template>
        </div>
        <!-- Partials -->
        <modal-crear-liquidador ref="modalCrearLiquidador" @actualizar="getLiquidadores" />
        <modal-ver-liquidador ref="modalVerLiquidador" @actualizar="getLiquidadores" @actualizarEstado="actualizarEstado" @irEditar="irEditar" @irEliminar="confirmarEliminar" />
        <modal-editar-liquidador ref="modalEditarLiquidador" @actualizar="getLiquidadores" />
        <modal ref="modalEliminarLiquidador" titulo="Eliminar liquidador" no-aceptar adicional="Eliminar" @adicional="eliminarLiquidador">
            <p class="text-center text-general my-3">
                ¿Desea eliminar este liquidador?
            </p>
        </modal>
    </section>
</template>

<script>
import Service from '~/services/nomina/liquidadores'
export default {
    components: {
        modalCrearLiquidador: () => import('../partials/modalCrearLiquidador.vue'),
        modalVerLiquidador: () => import('../partials/modalVerLiquidador.vue'),
        modalEditarLiquidador: () => import('../partials/modalEditarLiquidador.vue'),
    }   ,
    data(){
        return {
            cargando: false,
            activos: [],
            inactivos: [],
            activosTotal: 0,
            inactivosTotal: 0,
            idSeleccionado: '',
            estadoSeleccionadio: '',
            url_liquidadores: 'https://www.ecestaticos.com/imagestatic/clipping/5c6/74c/5c674ca7f188a12dd1a750bfc51d9305/lo-primero-en-lo-que-se-fijan-las-azafatas-cuando-subes-a-un-avion.jpg?mtime=1622805375'
        }
    },
    computed: {
        id_convenio(){
            return this.$route.params.id_convenio
        },
    },
    mounted(){
        this.getLiquidadores();
    },
    methods: {
        async getLiquidadores(){
            try {
                this.cargando = true;
                const {data} = await Service.getLiquidadores(this.id_convenio)
                this.activos = data.activos;
                this.inactivos = data.inactivos;
                this.activosTotal = data.activos.length;
                this.inactivosTotal = data.inactivos.length;
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        actualizarEstado(id, estado, bandera){
            if(bandera === 'inactivo'){
                let index = this.activos.findIndex(e => e.id === id)
                this.activos[index].estado = estado;
                let data = this.activos[index];
                this.activos.splice(index, 1);
                this.inactivos.push(data);
                return;
            }
            let index = this.inactivos.findIndex(e => e.id === id)
            this.inactivos[index].estado = estado;
            let data = this.inactivos[index];
            this.inactivos.splice(index, 1);
            this.activos.push(data);
            return;
        },
        crearLiquidador(){
            this.$refs.modalCrearLiquidador.toggle();
        },
        verLiquidador(id,bandera){
            let data = {};
            if(bandera === "activo"){
                data = this.activos.find((item) => item.id === id);
            }
            if(bandera === "inactivo"){
                data = this.inactivos.find((item) => item.id === id);
            }
            this.$refs.modalVerLiquidador.toggle(data);
        },
        irEditar(liquidador){
            this.$refs.modalEditarLiquidador.toggle(liquidador);
        },
        confirmarEliminar(id,estado){
            this.idSeleccionado = id;
            this.estadoSeleccionadio = estado;
            this.$refs.modalEliminarLiquidador.toggle();
        },
        async eliminarLiquidador(){
            try {
                const {data} = await Service.deleteLiquidador(this.idSeleccionado);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                if(parseInt(this.estadoSeleccionadio) === 1){
                    let index = this.activos.findIndex(e => e.id === this.idSeleccionado);
                    this.activos.splice(index, 1);
                } else if(parseInt(this.estadoSeleccionadio) === 0){
                    let index = this.inactivos.findIndex(e => e.id === this.idSeleccionado);
                    this.inactivos.splice(index, 1);
                }
                this.$refs.modalEliminarLiquidador.toggle();
                this.idSeleccionado = "";
                this.estadoSeleccionadio = "";
            } catch(e){
                this.error_catch(e)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.card-liquidadores{
    width: 355px;
    height: 126px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 1px 2px 8px #6B707414;
    cursor: pointer !important;
}
</style>

import axios from 'axios'

const API = 'nomina'

const Nomina = {
    getAfiliados(idConvenio,filtros){
        return axios(`${API}/${idConvenio}/afiliados`,{params:{...filtros}})
    },
    buscarUsuario(codigo){
        return axios(`${API}/buscar-user`,{params:{codigo}})
    },
    afiliarUserNomina(idConvenio,form){
        return axios.post(`${API}/convenio/${idConvenio}/afiliar`,form)
    },
    getLiquidacionesPendientes(idConvenio, params){
        return axios(`${API}/${idConvenio}/liquidar`,{params})
    },
    liquidarPedidos(idConvenio, form){
        return axios.post(`${API}/${idConvenio}/liquidar`,form)
    },
    pagarLiquidacion(idConvenio,idLiquidacion, form){
        return axios.put(`${API}/${idConvenio}/liquidacion/${idLiquidacion}/pagar`,form)
    },




}

export default Nomina

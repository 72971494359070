<template>
    <section class="position-relative">
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center mb-3">
            <i class="icon-back text-general cr-pointer f-20" @click="$router.back()" />
            <div class="col-auto text-general px-2">
                Liquidación <b>{{ liquidacion.id }}</b>
            </div>
            <div class="btn-outline f-14 cr-pointer py-2 px-3 ml-auto">
                <i class="icon-dowload-arrow f-20 mr-2 text-general" />
                <div v-if="$can('boton_nomina_exportar_liquidacion') || $usuario.liquidador">
                    <vue-excel-xlsx
                    style="border:none; outline:none; background-color: transparent;"
                    :data="produccionExcel"
                    :columns="columnsExport"
                    :filename="'liquidaciones'"
                    :file-type="'xlsx'"
                    :sheetname="'liquidaciones'"
                    >
                        <span>
                            Exportar liquidación
                        </span>
                    </vue-excel-xlsx>
                </div>
            </div>
        </div>
        <div class="row mx-0 bg-white py-3 px-3 br-8 mb-3">
            <div class="col-auto px-0">
                <p class="text-general f-14 text-center">Liquidados</p>
                <div class="row mx-0 align-items-center">
                    <i class="icon-cedis f-18 text-general" />
                    <span class="text-general f-22 f-600 ml-2">
                        {{ liquidacion.liquidados }}
                    </span>
                </div>
            </div>
            <div class="col-auto">
                <p class="text-general f-14">Pedidos</p>
                <div class="row mx-0 align-items-center">
                    <i class="icon-pedidos f-18 text-general" />
                    <span class="text-general f-22 f-600 ml-2">
                        {{ liquidacion.total_pedidos }}
                    </span>
                </div>
            </div>
            <div class="col-auto">
                <p class="text-general f-14">Valor liquidado</p>
                <div class="row mx-0 align-items-center">
                    <i class="icon-pedidos f-18 text-general" />
                    <span class="text-general f-22 f-600 ml-2">
                        {{ separadorNumeroDecimales(liquidacion.total_liquidaciones) }}
                    </span>
                </div>
            </div>
            <div class="col-auto">
                <p class="text-general f-14">Fecha de cobro</p>
                <div class="row mx-0 align-items-center">
                    <i class="icon-calendar f-18 text-general" />
                    <span class="text-general f-22 f-600 ml-2">
                        {{ liquidacion.fecha_descuento | helper-fecha('DD MMM YYYY') }}
                    </span>
                </div>
            </div>
            <div class="col-auto">
                <p class="text-general f-14">Fecha de corte</p>
                <div class="row mx-0 align-items-center">
                    <i class="icon-calendar f-18 text-general" />
                    <span class="text-general f-22 f-600 ml-2">
                        {{ liquidacion.fecha_corte | helper-fecha('DD MMM YYYY') }}
                    </span>
                </div>
            </div>
        </div>
        <div class="row mx-0 align-items-center bg-white py-3 px-3 br-8">
            <img :src="liquidacion.usuario_foto != null ? liquidacion.usuario_foto : '/img/no-imagen/sin_cliente.svg'" width="46" height="46" class="rounded-circle obj-cover" />
            <div class="col-auto px-2">
                <p class="text-general f-14">
                    {{ liquidacion.usuario != null ? liquidacion.usuario : 'No registra' }}
                </p>
                <p v-if="liquidacion.estado === 1" class="text-general f-18 f-600">
                    {{ liquidacion.created_at | helper-fecha('DD MMM YYYY') }}
                </p>
                <p v-if="liquidacion.estado === 2" class="text-general f-18 f-600">
                    {{ liquidacion.fecha_verifico | helper-fecha('DD MMM YYYY') }}
                </p>
                <p v-if="liquidacion.estado === 3" class="text-general f-18 f-600">
                    No registra
                </p>
            </div>
            <div class="col text-general f-14">
                <template v-if="liquidacion.comentario != null">
                    <b>Observaciones:</b>
                    {{ liquidacion.comentario }}
                </template>
            </div>
            <div class="col-auto">
                <template v-if="liquidacion.estado === 1">
                    <span class="text-green f-500 ml-2">Pendiente</span>
                </template>
                <template v-if="liquidacion.estado === 2">
                    <i class="icon-ok-circled-outline f-18 text-green" />
                    <span class="text-green f-500 ml-2">Verificado por leeche</span>
                </template>
                <template v-if="liquidacion.estado === 3">
                    <i class="icon-attention-alt f-18 text-orange" />
                    <span class="text-orange f-500 ml-2">En revisión</span>
                </template>
            </div>
        </div>
        <liquidaciones :pedidos="pedidos" :fecha-liquidacion="liquidacion.created_at" />
        <!-- Partials -->
        <modal-pedidos-cliente ref="pedidosCliente" />
    </section>
</template>

<script>
import Service from '~/services/nomina/historial_liquidacion'
import moment from 'moment'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from 'vue'
Vue.use(VueExcelXlsx)

export default {
    components: {
        modalPedidosCliente: () => import('../partials/modalPedidosCliente.vue'),
        liquidaciones: () => import('../components/liquidaciones.vue')
    },
    data(){
        return {
            cargando: false,
            liquidacion: {},
            pedidos: [],
            pedidos_excel: [],
            buscar: '',
            columnsExport : [
                {
                    label: "Código",
                    field: "id_cliente",
                },
                {
                    label: "Nombre",
                    field: "cliente",
                },
                {
                    label: "Documento",
                    field: "documento",
                },
                {
                    label: "Deuda",
                    field: "deuda",
                    dataFormat: this.formatoDeuda
                },
                {
                    label: "Pedidos",
                    field: "pedidos",
                },
                {
                    label: "Cupo",
                    field: "cupo",
                    dataFormat: this.formatoCupo
                },
                {
                    label: "Antigüedad",
                    field: "fecha_vinculacion",
                    dataFormat: this.fechaAntiguedad
                },
                {
                    label: "Fecha de registro",
                    field: "fecha_registro",
                    dataFormat: this.formatoFechaRegistro
                },
                {
                    label: "Entrega",
                    field: "porcentaje_entrega",
                    dataFormat: this.formatoEntrega
                },
            ],
            img_url: 'https://img2.freepng.es/20180525/bxt/kisspng-flight-attendant-airline-luftfahrtpersonal-travel-dynamic-flying-5b080ef5716cb2.6169015215272547734646.jpg',
        }
    },
    computed: {
        id_convenio(){
            return this.$route.params.id_convenio
        },
        id_liquidacion(){
            return this.$route.params.id_liquidacion
        },
        produccionExcel(){
            let data = _.cloneDeep(this.pedidos);
            return data;
        }
    },
    created(){
        this.validarConsulta();
    },
    methods: {
        async validarConsulta(){
            if(!this.id_liquidacion)return
            this.cargando = true;
            this.getLiquidacion();
        },
        async getLiquidacion(){
            try {
                const params = {
                    id_convenio: this.id_convenio
                };
                const {data} = await Service.getLiquidacion(this.id_liquidacion, params)
                this.liquidacion = data.liquidacion;
                this.pedidos = data.pedidos;
                this.cargando = false;
            } catch (e){
                this.error_catch(e)
            }
        },
        fechaAntiguedad(fecha){
            if(fecha === null || fecha === '')return '-';
            return moment(fecha).startOf('day').fromNow();
        },
        pedidoCliente(row){
            this.$refs.pedidosCliente.toggle(this.liquidacion.id, row.id_cliente, this.liquidacion.created_at);
        },
        formatoCupo(value){
            return parseInt(value) === 0 ? 'Ilimitado' : this.agregarSeparadoresNumero(value);
        },
        formatoDeuda(value){
            return this.agregarSeparadoresNumero(value);
        },
        formatoEntrega(value){
            return value+"%";
        },
        formatoFechaRegistro(value){
            return moment(value).format("DD MMM YYYY");
        },
    }
}
</script>

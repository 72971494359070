import axios from 'axios'

const API = "nomina/historial-liquidacion"

const ENDPOINTS = {
    getHistorial(id, params){
        return axios.get(`${API}/${id}/listado`, {params})
    },
    getLiquidacion(id, params){
        return axios.get(`${API}/${id}/detalle`,{params})
    },
    getPedidosClientes(id, params){
        return axios.get(`${API}/${id}/cliente/pedidos`,{params})
    },
}
export default ENDPOINTS

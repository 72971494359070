<template>
    <section :class="$route.name == 'admin.clientes-estadisticas.satisfaccion' ? 'custom-scroll overflow-auto height-satisfaccion' : ''">
        <!-- <div class="overflow-auto custom-scroll" style="height: calc(100vh - 50px)"> -->
        <div :class="getValidateRoute" class="my-2 p-2" style="min-height:301px;">
            <div class="row mx-0 px-4">
                <p class="mb-0 f-18 f-600 text-general">
                    Calificación de satisfacción de {{ $config.proyecto }}
                </p>
            </div>
            <div class="row mx-0 mt-5 mb-4 justify-center">
                <p class="mb-0 text-center">
                    <span class="f-18 f-600 text-general" v-text="`${pedidosGlobal.estadisticas.total}%`" />
                    <span class="f-14 text-general2"> de pedidos Calificados </span>
                </p>
            </div>
            <div class="row mx-5 br-5 mt-2 mb-2" style="height:30px;">
                <div
                class="porcentajeUh h-100 bg-general3 br-tl-5 br-bl-5 position-relative d-middle-center"
                :style="`width:${pedidosGlobal.estadisticas.mal}%`"
                >
                    <div class="position-absolute" style="bottom:-80px;">
                        <div class="d-middle-center">
                            <img src="/img/icons/emotes/Uhh.svg" alt="" />
                            <p
                            class="mb-0 f-18 text-gr-red f-600 text-center mx-2"
                            v-text="`${pedidosGlobal.estadisticas.mal}%`"
                            />
                        </div>
                        <div class="d-middle-center ">
                            <p class="mb-0 px-3 f-12 text-muted2 w-100">
                                uh..
                            </p>
                        </div>
                    </div>
                </div>
                <div
                class="porcentajeBien h-100 bg-general2 position-relative d-middle-center"
                :style="`width: ${pedidosGlobal.estadisticas.bien}%`"
                >
                    <div class="position-absolute" style="bottom:-80px;">
                        <div class="d-middle-center">
                            <img src="/img/icons/emotes/Bien.svg" alt="" />
                            <p
                            class="mb-0 f-18 text-gr-purple f-600 text-center mx-2"
                            v-text="`${pedidosGlobal.estadisticas.bien}%`"
                            />
                        </div>
                        <div class="d-middle-center ">
                            <p class="mb-0 px-3 f-12 text-muted2 w-100">
                                Bien
                            </p>
                        </div>
                    </div>
                </div>
                <div
                class="porcentajeEncanta bg-light-f5 h-100 br-tr-5 br-br-5 position-relative d-middle-center"
                :style="`width: ${pedidosGlobal.estadisticas.encanta}%`"
                >
                    <div class="position-absolute" style="bottom:-80px;">
                        <div class="d-middle-center">
                            <img src="/img/icons/emotes/MeEncanta.svg" alt="" />
                            <p
                            class="mb-0 f-18 text-general f-600 text-center mx-2"
                            v-text="`${pedidosGlobal.estadisticas.encanta}%`"
                            />
                        </div>
                        <div class="d-middle-center ">
                            <p class="mb-0 px-0 f-12 text-general2 w-100">
                                Me encanta
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="getValidateRoute" class="my-2" style="min-height:486px;">
            <div class="row mx-0">
                <div class="col-lg-4 px-4">
                    <div class="row mx-0 px-3">
                        <p class="mb-0 f-18 f-600 text-general">
                            Calificación Anual
                        </p>
                    </div>
                    <div class="row mx-0 my-4 px-3 justify-center">
                        <p class="mb-0 f-14 f-600 text-general2">
                            <span
                            class="f-18 f-600 text-general"
                            v-text="`${pedidosAnual.estadisticas.total}%`"
                            />
                            de pedidos calificados
                        </p>
                    </div>
                    <div class="row mx-0">
                        <div class="col d-middle-center p-4">
                            <div class="mx-4 br-12 d-flex align-items-end position-relative" style="width:36px;height:200px;background-color:#F5F5F5;border-radius:12px;">
                                <div
                                class="w-100 bg-general3"
                                style="border-radius:12px;"
                                :style="`height:${pedidosAnual.estadisticas.mal}%`"
                                />
                                <div class="position-absolute" style="bottom:-25px;left:-8px;">
                                    <img src="/img/icons/emotes/Uhh.svg" alt="" />
                                </div>
                                <div class="position-absolute" style="bottom:-50px;left:10px;">
                                    <p class="mb-0 f-12" style="color:#5D5D5D;">
                                        Uhh..
                                    </p>
                                </div>
                                <div class="position-absolute" style="top:-20px;left:10px;">
                                    <p class="mb-0 f-12 text-general" v-text="`${pedidosAnual.estadisticas.mal}%`" />
                                </div>
                            </div>
                            <div class="mx-4 d-flex align-items-end position-relative" style="width:36px;height:200px;background-color:#F5F5F5;border-radius:12px;">
                                <div
                                class="w-100 bg-general2"
                                style="border-radius:12px;"
                                :style="`height:${pedidosAnual.estadisticas.bien}%`"
                                />
                                <div class="position-absolute" style="bottom:-25px;left:-8px;">
                                    <img src="/img/icons/emotes/Bien.svg" alt="" />
                                </div>
                                <div class="position-absolute" style="bottom:-50px;left:10px;">
                                    <p class="mb-0 f-12" style="color:#5D5D5D;">
                                        Bien
                                    </p>
                                </div>
                                <div class="position-absolute" style="top:-20px;left:10px;">
                                    <p
                                    class="mb-0 f-12 text-general"
                                    v-text="`${pedidosAnual.estadisticas.bien}%`"
                                    />
                                </div>
                            </div>
                            <div class="mx-4 d-flex align-items-end position-relative" style="width:36px;height:200px;background-color:#F5F5F5;border-radius:12px;">
                                <div
                                class="w-100 bg-light-f5"
                                style="border-radius:12px;"
                                :style="`height:${pedidosAnual.estadisticas.encanta}%`"
                                />
                                <div class="position-absolute" style="bottom:-25px;left:-8px;">
                                    <img src="/img/icons/emotes/MeEncanta.svg" alt="" />
                                </div>
                                <div class="position-absolute" style="bottom:-50px;left:-5px;">
                                    <p class="mb-0 f-12" style="color:#5D5D5D;width:60px;">
                                        Me gusta
                                    </p>
                                </div>
                                <div class="position-absolute" style="top:-20px;left:10px;">
                                    <p
                                    class="mb-0 f-12 text-center text-general"
                                    v-text="`${pedidosAnual.estadisticas.encanta}%`"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-8">
                    <div class="row mx-5 justify-content-start border-bottom">
                        <p
                        v-for="(item, i) in pedidosGlobal.rango"
                        :key="i"
                        class="col-auto cr-pointer p-2 f-14"
                        :class="[agregarClaseTabActiva(pedidosGlobal, i), agregarClaseTabDesactivada(item)]"
                        @click="cambiarRangoActivoAnual(item, i)"
                        v-text="i"
                        />
                    </div>
                    <echart width="100" height="500px" :options="configuracionGraficaAnual" />
                </div>
            </div>
        </div>
        <div :class="getValidateRoute" class="my-2" style="min-height:486px;">
            <div class="row mx-0">
                <div class="col-lg-4 px-4">
                    <div class="row mx-0 px-3">
                        <p class="mb-0 f-18 f-600 text-general">
                            Calificación Mensual
                        </p>
                    </div>
                    <div class="row mx-0 my-4 px-3 justify-center">
                        <p class="mb-0 f-14 f-600 text-muted2 ">
                            <span
                            class="f-18 f-600 text-general"
                            v-text="`${pedidosMensual.estadisticas.total}%`"
                            />
                            de pedidos calificados
                        </p>
                    </div>
                    <div class="row">
                        <div class="col d-middle-center p-4">
                            <div 
                            class="mx-4 br-12 d-flex align-items-end position-relative" 
                            style="width:36px;height:200px;background-color:#F5F5F5;border-radius:12px;"
                            >
                                <div
                                class="w-100 bg-general3"
                                style="border-radius:12px;"
                                :style="`height:${pedidosMensual.estadisticas.mal}%`"
                                />
                                <div class="position-absolute" style="bottom:-25px;left:-8px;">
                                    <img src="/img/icons/emotes/Uhh.svg" alt="" />
                                </div>
                                <div class="position-absolute" style="bottom:-50px;left:10px;">
                                    <p class="mb-0 f-12" style="color:#5D5D5D;">
                                        Uhh..
                                    </p>
                                </div>
                                <div class="position-absolute" style="top:-20px;left:10px;">
                                    <p
                                    class="mb-0 f-12 text-general"
                                    v-text="`${pedidosMensual.estadisticas.mal}%`"
                                    />
                                </div>
                            </div>
                            <div 
                            class="mx-4 d-flex align-items-end position-relative" 
                            style="width:36px;height:200px;background-color:#F5F5F5;border-radius:12px;"
                            >
                                <div
                                class="w-100 bg-general2"
                                style="border-radius:12px;"
                                :style="`height:${pedidosMensual.estadisticas.bien}%`"
                                />
                                <div class="position-absolute" style="bottom:-25px;left:-8px;">
                                    <img src="/img/icons/emotes/Bien.svg" alt="" />
                                </div>
                                <div class="position-absolute" style="bottom:-50px;left:10px;">
                                    <p class="mb-0 f-12" style="color:#5D5D5D;">
                                        Bien
                                    </p>
                                </div>
                                <div class="position-absolute" style="top:-20px;left:10px;">
                                    <p
                                    class="mb-0 f-12 text-general"
                                    v-text="`${pedidosMensual.estadisticas.bien}%`"
                                    />
                                </div>
                            </div>
                            <div 
                            class="mx-4 d-flex align-items-end position-relative" 
                            style="width:36px;height:200px;background-color:#F5F5F5;border-radius:12px;"
                            >
                                <div
                                class="w-100 bg-light-f5"
                                style="border-radius:12px;"
                                :style="`height:${pedidosMensual.estadisticas.encanta}%`"
                                />
                                <div class="position-absolute" style="bottom:-25px;left:-8px;">
                                    <img src="/img/icons/emotes/MeEncanta.svg" alt="" />
                                </div>
                                <div class="position-absolute" style="bottom:-50px;left:-5px;">
                                    <p class="mb-0 f-12" style="color:#5D5D5D;width:60px;">
                                        Me gusta
                                    </p>
                                </div>
                                <div class="position-absolute" style="top:-20px;left:10px;">
                                    <p
                                    class="mb-0 f-12 text-center text-general"
                                    v-text="`${pedidosMensual.estadisticas.encanta}%`"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-8">
                    <div class="row mx-5 justify-content-start border-bottom">
                        <p
                        v-for="(item, i) in pedidosAnual.rango"
                        :key="i"
                        class="col-auto cr-pointer p-2 f-14"
                        :class="[agregarClaseTabActiva(pedidosAnual, i), agregarClaseTabDesactivada(item)]"
                        @click="cambiarRangoActivoMensual(item, i)"
                        v-text="i"
                        />
                    </div>
                    <echart width="100" height="500px" :options="configuracionGraficaMensual" />
                </div>
            </div>
        </div>
        <!-- </div> -->
    </section>
</template>
<script>
import Vue from 'vue'
import echarts from 'echarts'
import ServicioApi from '~/services/pedidos/satisfaccion'
import {mapGetters} from 'vuex'

Vue.component('echarts',echarts)

const colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
const colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-general2')

export default {
    name: 'PedidosSatisfaccion',
    middleware:['auth'],
    data: () => ({
        pedidosGlobal: { estadisticas: {} },
        pedidosAnual: { estadisticas: {total:0, mal:0, bien:0,encanta:0}, series: {} },
        pedidosMensual: { estadisticas: {total:0, mal:0, bien:0,encanta:0}, series: {} },
        configuracionGraficaAnual : {
            legend: {
                show: true,
                icon: 'circle',
                top: 25,
                textStyle: {
                    fontSize: 12,
                    color: '#5D5D5D'
                },
            },
            tooltip: {
                trigger: 'axis',
                show: true,
            },
            grid: {
                left: '5%',
                right: '5%',
                top: '15%',
                bottom: '6%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: [],
                axisLine: {
                    show: true,
                    lineStyle : {
                        color: '#5D5D5D;'
                    }
                }
            },
            yAxis: [{
                type: 'value',
                axisLine: {
                    show: false,
                    lineStyle: {
                        color : '#5D5D5D'
                    }
                }
            },
            {
                type: 'category',
                data: ['0%', '25%', '50%', '75%', '100%'],
                axisLine: {
                    show: false,
                    lineStyle: {
                        color : '#5D5D5D'
                    }
                }
            }],
            series: [
                {
                    data: [],
                    name: 'Pedidos',
                    type: 'line',
                    symbol:'circle',
                    symbolSize: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: '#000000' },
                                {offset: 1, color: '#000000' },
                            ]
                        )
                    },
                    lineStyle: {
                        normal: {
                            width: 3,
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: '#000000' },
                                    {offset: 1, color: '#000000' },
                                ]
                            )
                        }

                    },
                },
                {
                    data: [],
                    name: 'Calificaciones de la satisfaccion',
                    type: 'line',
                    symbol:'circle',
                    symbolSize: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: colorGeneral },
                                {offset: 1, color: colorGeneral },
                            ]
                        )
                    },
                    lineStyle: {
                        normal : {
                            width: 3,
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: colorGeneral },
                                    {offset: 1, color: colorGeneral },
                                ]
                            )
                        }

                    },
                }]
        },
        configuracionGraficaMensual : {
            legend: {
                show: true,
                icon: 'circle',
                top: 25,
                textStyle: {
                    fontSize: 12,
                    color: '#5D5D5D'
                },
            },
            tooltip: {
                trigger: 'axis',
                show: true,
            },
            grid: {
                left: '5%',
                right: '5%',
                top: '15%',
                bottom: '6%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['Ene', 'Feb', 'Mar', 'Abr.', 'May', 'Jun.', 'Jul', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'],
                axisLine: {
                    show: true,
                    lineStyle : {
                        color: '#5D5D5D;'
                    }
                }
            },
            yAxis: [
                {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color : '#5D5D5D'
                        }
                    }
                },
                {
                    type: 'category',
                    data: ['0%', '25%', '50%', '75%', '100%'],
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color : '#5D5D5D'
                        }
                    }
                }
            ],
            series: [   
                {
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    name: 'Pedidos',
                    type: 'line',
                    symbol:'circle',
                    symbolSize: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: '#000000' },
                                {offset: 1, color: '#000000' },
                            ]
                        )
                    },
                    lineStyle: {
                        normal: {
                            width: 3,
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: '#000000' },
                                    {offset: 1, color: '#000000' },
                                ]
                            )
                        }

                    },
                },
                {
                    data: [251, 350, 350, 350, 350, 350, 350],
                    name: 'Calificaciones de la satisfaccion',
                    type: 'line',
                    symbol:'circle',
                    symbolSize: 10,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: colorGeneral },
                                {offset: 1, color: colorGeneral },
                            ]
                        )
                    },
                    lineStyle: {
                        normal : {
                            width: 3,
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0.2, color: colorGeneral },
                                    {offset: 1, color: colorGeneral },
                                ]
                            )
                        }

                    }
                }
            ]
        }
    }),
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        'pedidosGlobal.activo'(valor){
            if(valor) this.consultarSatisfaccionAnual(this.generarPayloadRango(this.pedidosGlobal.rango[valor]))
        },
        'pedidosAnual.activo'(valor){
            if(valor) this.consultarSatisfaccionMensual(this.generarPayloadRango(this.pedidosAnual.rango[valor]))
        },
        id_cedis(val){
            const valorAnual = this.pedidosGlobal.activo
            const valorMensual = this.pedidosAnual.activo
            this.consultarSatisfaccionGlobal()
            if(valorAnual){
                this.consultarSatisfaccionAnual(this.generarPayloadRango(this.pedidosGlobal.rango[valorAnual]))
            }
            if(valorMensual) this.consultarSatisfaccionMensual(this.generarPayloadRango(this.pedidosAnual.rango[valorMensual]))
        },
    },

    mounted(){
        this.consultarSatisfaccionGlobal()
    },
    methods: {
        async consultarSatisfaccionGlobal(){
            try {
                const params = {id_cedis: this.id_cedis}
                const { data: { data } } = await ServicioApi.consultarSatisfaccionGlobal(params)
                this.pedidosGlobal = this.cargarDatosPedidos(data)
            } catch (error){
                this.error_catch(error)
            }
        },
        generarPayloadRango: ({ desde, hasta }) => ({ rango: [desde, hasta]}),
        async consultarSatisfaccionAnual(payload){
            try {
                const params = { ...payload, id_cedis: this.id_cedis}
                const { data: { data } } = await ServicioApi.consultarSatisfaccionAnual(params)
                this.pedidosAnual = this.cargarDatosPedidos(data)
                this.cargarGraficaPedidos(this.configuracionGraficaAnual, this.pedidosAnual)
            } catch (error){
                this.error_catch(error)
            }
        },
        async consultarSatisfaccionMensual(payload){
            try {
                const params = { ...payload, id_cedis: this.id_cedis}
                const { data: { data } } = await ServicioApi.consultarSatisfaccionMensual(params)
                this.pedidosMensual = this.cargarDatosPedidos(data)
                this.cargarGraficaPedidos(this.configuracionGraficaMensual, this.pedidosMensual)
            } catch (error){
                this.error_catch(error)
            }
        },
        cargarDatosPedidos({ rango, calificaciones, pedidos, satisfaccion }){
            return {
                rango,
                activo: _.last(Object.keys(rango).filter(key => rango[key].activo)),
                estadisticas: this.cargarEstadisticasCalificaciones(calificaciones),
                ...((satisfaccion && pedidos) && { satisfaccion, pedidos })
            }
        },
        cargarEstadisticasCalificaciones: ({ balance, detalle: { porc_calificados: calificados } }) => ({
            total: balance.porc_calificados,
            mal: calificados[0].porcentaje,
            bien: calificados[1].porcentaje,
            encanta: calificados[2].porcentaje
        }),
        cargarGraficaPedidos(config, datos){
            config.xAxis.data = Object.keys(datos.rango)
            if(datos.pedidos){
                config.series[0].data = datos.pedidos
                config.series[1].data = datos.satisfaccion
            }
        },
        cambiarRangoActivoAnual({ activo }, rango){
            if (activo) this.pedidosGlobal.activo = rango
        },
        cambiarRangoActivoMensual({ activo }, rango){
            if (activo) this.pedidosAnual.activo = rango
        },
        agregarClaseTabActiva: ({ activo }, valorTab) => activo === valorTab ? 'border-bottom-filtro' : '',
        agregarClaseTabDesactivada: ({ activo }) => activo ? 'text-general' : 'text-gris',
        getValidateRoute: () => this.$route.name == 'admin.clientes-estadisticas.satisfaccion' ? '' : 'box'
    }
}

</script>
<style lang="scss">
    .border-bottom-filtro{
        border-bottom: 4px solid var(--text-general);
        border-radius: 1px;
    }
    .text-gr-alternativo{
        background: -webkit-linear-gradient(90deg, #00D3FF, #007F94 90%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bg-gr-alternativo{
        background-image: linear-gradient(to bottom, #00D3FF, #007F94)
    }
    .height-satisfaccion{
        height: calc(100vh - 136px);
    }
</style>

<template>
    <section class="pt-3" style="height: calc(100% - 62px);">
        <div class="row mx-0 h-100">
            <div class="menu-prinicipal border-r-gris h-100">
                <div class="row mx-0 pt-1 border-b-gris" style="height:57px;">
                    <div class="col-12 my-auto">
                        <el-input v-model="word" :placeholder="`Buscar ${$config.vendedor}`" size="small" class="br-20" @input="buscarTiendas" />
                    </div>
                </div>
                <div class="col px-0 overflow-auto custom-scroll" style="width:450px;height:calc(100% - 56px);">
                    <!-- listado pedidos -->
                    <div>
                        <div
                        v-for="(ti, idx) in tiendas" :key="idx"
                        :class="`row mx-0 d-middle cr-pointer border-bottom py-2 ${id_tienda==ti.id?'bg-whitesmoke':''}`"
                        @click="activarCar(ti)"
                        >
                            <div :class="`mx-2 px-1 br-5 ${id_tienda==ti.id?'bg-general':''}`" style="height:89px;" />
                            <div :class="`col-11 px-0 tres-puntos f-15 br-10`">
                                <div class="d-middle mx-0">
                                    <img v-if="ti.logo_mini_firmado===null" class="br-10 p-2 border bg-whitesmoke" width="90" height="90" src="/img/no-imagen/sin_cliente.svg" alt="" />
                                    <img v-else :src="ti.logo_mini_firmado" class="border br-12" style="object-fit:cover;width:90px;height:90px;" alt="" />
                                    <div class="col-9 pr-1 f-15">
                                        <div class="d-middle mx-0">
                                            <div class="col tres-puntos pl-0 f-600 text-general">
                                                {{ ti.propietario_nombre }}
                                            </div>
                                            <estrellas :row="ti" class="ml-auto" />
                                        </div>
                                        <div class="row mx-0">
                                            <el-tooltip placement="bottom" :content="ti.ult_mensaje" effect="light">
                                                <p class="tres-puntos text-general">{{ ti.ult_mensaje ? ti.ult_mensaje : '--' }}</p>
                                            </el-tooltip>
                                        </div>
                                        <div class="d-flex mx-0 align-items-center">
                                            <div class="col-5 px-0">
                                                <p class="ml-auto tres-puntos"> {{ ti.hora_ult_chat_format }}</p>
                                            </div>
                                            <div class="col d-flex align-items-center pl-0 pr-2">
                                                <p class="ml-auto tres-puntos">{{ ti.estado_show }}</p>
                                                <i :class="`f-20 ${ti.chat_admin==1?'icon-message-text-outline text-gr-general':'icon-message-bulleted-off text-gr-red'}`" />
                                                <div class="bg-general3 br-10 text-center text-white ml-1" :class="ti.cant_msg>0?'':'invisible'" style="min-width:30px;">
                                                    {{ ti.cant_msg }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <infinite-loading ref="infiniteLoading" @infinite="listarTiendas">
                        <div slot="spinner">
                            <div v-loading="true">
                                Cargando...
                            </div>
                        </div>
                        <div slot="no-more" class="text-muted">
                            <span>No hay más tiendas</span>
                        </div>
                        <div slot="no-results" class="text-muted">
                            No se encuentran tiendas
                        </div>
                    </infinite-loading>
                    <div v-if="tiendas.length <=0" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/question.svg" alt="" />
                            </div>
                            <p>Aún no has recibido un mensaje de soporte</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 col menu-prinicipal h-100">
                <div v-if="id_tienda!=0" class="row mx-0 px-3 align-items-center border-b-gris" style="height:75px;">
                    <el-tooltip placement="bottom" content="Ver información del leechero" effect="light">
                        <img v-if="detalle.logo_mini_firmado===null" class="br-10 border bg-whitesmoke p-2 cr-pointer" width="44" height="44" src="/img/no-imagen/sin_cliente.svg" @click="informacionTienda" />
                        <img v-else :src="detalle.logo_mini_firmado" class="border br-10 cr-pointer" width="44" height="44" style="object-fit:cover;" @click="informacionTienda" />
                    </el-tooltip>
                    <div class="col ml-2 px-0">
                        <p class="f-500 tres-puntos text-general">{{ detalle.propietario_nombre }}</p>
                        <p class="f-500 tres-puntos text-general2 f-14">{{ detalle.nombre_cedis || 'Sin cedi' }}</p>
                        <p class="f-500 tres-puntos text-general2 f-12">{{ detalle.telefono_celular || 'Sin teléfono' }}</p>
                    </div>
                    <div class="col px-0 d-middle justify-content-end">
                        <div class="btn-general px-2 text-white cr-pointer br-8 f-14" @click="cerrarSoporte">
                            Finalizar soporte
                        </div>
                        <estrellas :row="detalle" />
                        <el-tooltip v-if="detalle.chat_admin!==2" class="item" effect="light" content="Inactivar chat" placement="bottom">
                            <div class="btn-action border-gris ml-2" @click="inactivarChat">
                                <i class="icon-message-text text-general cr-pointer f-20" />
                            </div>
                        </el-tooltip>
                        <el-tooltip v-else class="item" effect="light" content="Activar chat" placement="bottom">
                            <div class="btn-action border-gris ml-2" @click="activarChat">
                                <i class="icon-message-text text-general cr-pointer f-20" />
                            </div>
                        </el-tooltip>
                        <el-tooltip placement="bottom" :content="`Ver Informacion del ${$config.vendedor}`" effect="light">
                            <div class="btn-action border ml-2">
                                <i class="icon-info-circled-alt text-general cr-pointer f-20" @click="informacionTienda" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div style="height: calc(100% - 56px);">
                    <chat v-if="id_tienda!=0" ref="mychat" :tipo="3" :user="detalle.id" @actualizar-lectura="actualizarLectura" />
                    <div v-else class="d-middle-center h-100">
                        <div class="col-4 text-center">
                            <img height="110" src="/img/no-imagen/click.svg" alt="" />
                            <p>Selecciona uno de los soportes abiertos del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-confirmar
        ref="modalInactivarChat"
        titulo="Inactivar chat"
        icon="activar-chat"
        :mensaje="`¿Desea inactivar el chat con el ${$config.vendedor}?`"
        @guardar="accionInactivarActivar"
        >
            <div class="row mx-0 justify-center text-general text-center f-15 mb-1">
                Él solo podrá enviar 3 mensajes máximo, hasta que reciba una respuesta no podrá enviar un nuevo mensaje.
            </div>
        </modal-confirmar>
        <modal-confirmar
        ref="modalActivarChat"
        titulo="Activar chat"
        icon="activar-chat"
        :mensaje="`¿Desea activar el chat con el ${$config.vendedor}?`"
        @guardar="accionInactivarActivar"
        />
        <modalConfirmar ref="modalCerrarSoporte" titulo="Cerrar soporte" icon="checked" mensaje="¿Desea cerrar este caso?" @guardar="finalizarChat">
            <ValidationObserver ref="validacion">
                <div class="row mx-0">
                    <div class="col">
                        <label class="pl-3 text-general f-12">Deja un comentario final sobre este caso</label>
                        <ValidationProvider v-slot="{errors}" rules="required" name="comentario">
                            <el-input v-model="comentario" type="textarea" :rows="3" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modalConfirmar>
        <modalInformacion ref="modalInformacion" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Soporte from '~/services/soporte/tiendas'
import Chat from '~/services/chat/chat'

import InfiniteLoading from 'vue-infinite-loading'
import { log } from 'util'

export default {
    metaInfo: () =>({ title: 'Soporte'}),
    components: {
        modalInformacion: () => import('~/pages/soporte/partials/modalInformacionLeechero'),
        InfiniteLoading
    },
    sockets:{
        chats_tiendas(value){
            try {
                const {chat} = JSON.parse(value)
                if(parseInt(chat.id_tienda) !== parseInt(this.id_tienda)){
                    const index = this.tiendas.findIndex( e => e.id === parseInt(chat.id_tienda) )
                    if(index >= 0){
                        this.tiendas[index].cant_msg +=1
                    }else{// No esta la tienda en el listado, las vuelvo a listar
                        this.buscarTiendas()
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    data(){
        return {
            tiendas: [],
            word: null,
            comentario: '',
            chatActivo:  true,
            detalle: {
                id: 0,
                nombre: null,
                logo_mini_firmado: null,
                estrella: null,
                id_estrella: null,
                chat_admin: null,
            },
            loading: false,
            page:1,
            infoLeechero: {},
        }
    },
    computed:{
        ...mapGetters({
            cant_tiendas: 'soporte/tiendas/cant_tiendas',
            id_tienda: 'soporte/tiendas/id_tienda',
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        id_cedis(){
            this.page = 1
            this.tiendas = []
            this.$store.commit('soporte/tiendas/set_id_tienda', 0)
            this.$store.commit('soporte/tiendas/set_cant_tiendas', 0)
            this.limpiarDetalle()
            this.listarTiendas()
        }
    },
    mounted(){
        this.limpiarDetalle()
        this.$store.commit('soporte/tiendas/set_id_tienda', 0)
        this.$store.commit('soporte/tiendas/set_cant_tiendas', 0)

    },
    methods: {
        async listarTiendas($state= false){
            try {
                const state = $state
                const params = {
                    page: this.page,
                    id_user: this.user,
                    word: this.word // solo para chat de pedidos
                }
                this.loading = true
                const {data} = await Soporte.listarTiendas(params)
                this.$store.commit('soporte/tiendas/set_cant_tiendas', this.cant_tiendas +data.cant_msg_total)
                this.page += 1
                this.loading = false
                let temp = _.union(this.tiendas, data.tiendas.data, 'id')
                let uniqBy = _.uniqBy(temp, 'id')
                this.tiendas = uniqBy
                if (data.tiendas.data.length > 0){
                    if(state){
                        state.loaded()
                    }
                } else {
                    if(state){
                        state.complete()
                    }
                }

            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        cerrarSoporte(){
            this.comentario = ''
            this.$refs.modalCerrarSoporte.toggle()
        },
        async finalizarChat(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const enviar = {
                    id_user:  this.detalle.created_by,
                    comentario: this.comentario

                }
                const {data} = await Soporte.finalizarChat(enviar)
                this.notificacion('Mensaje', 'Chat finalizado', 'success')
                this.loading_chat= true
                this.$refs.mychat.resetear()
                this.loading_chat= false
                this.$refs.modalCerrarSoporte.toggle()


            } catch (e){
                this.error_catch(e)
            }

        },
        async activarCar(tien){
            this.$store.commit('soporte/tiendas/set_id_tienda', tien.id)
            this.detalle = tien
            await this.actualizarUltimaLectura()
            tien.cant_msg = 0
        },
        buscarTiendas(){
            this.page = 1
            this.$store.commit('soporte/tiendas/set_id_tienda', 0)
            this.$store.commit('soporte/tiendas/set_cant_tiendas', 0)
            this.limpiarDetalle()
            this.tiendas = []
            this.delay(this.listarTiendas)
        },
        async actualizarUltimaLectura(){
            try {
                const enviar ={
                    id_dinamico: this.id_tienda,
                    tipo: 3 // tiendas
                }
                const {data} = await Chat.actualizarUltimaLecturaChat(enviar)

            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarLectura(){
            await this.actualizarUltimaLectura()
        },
        limpiarDetalle(){
            this.detalle= {
                id: 0,
                nombre: null,
                logo_mini_firmado: null,
                estrella: null,
                id_estrella: null,
                chat_admin: null,
            }
        },
        inactivarChat(){
            this.$refs.modalInactivarChat.toggle()
        },
        activarChat(){
            this.$refs.modalActivarChat.toggle()
        },
        async accionInactivarActivar(){
            try {
                const enviar ={
                    id_tienda: this.id_tienda,
                    chat_admin: this.detalle.chat_admin
                }
                const {data} = await Soporte.acticarInactivarChat(enviar)
                if(this.detalle.chat_admin!==2){
                    this.notificacion('Mensaje', 'Chat inactivo', 'success')
                    this.$refs.modalInactivarChat.toggle()
                }else{
                    this.notificacion('Mensaje', 'Chat activado', 'success')
                    this.$refs.modalActivarChat.toggle()
                }
                const index = this.tiendas.findIndex( e => e.id === enviar.id_tienda )
                if(index >= 0){
                    this.tiendas[index].chat_admin = this.tiendas[index].chat_admin==1 ? 2 : 1
                }
                this.detalle.chat_admin = data.chat_admin

            } catch (e){
                this.error_catch(e)
            }
        },
        informacionTienda(){
            this.infoLeechero = this.tiendas.filter((item) => item.id === this.id_tienda );
            this.$refs.modalInformacion.toggle(this.detalle.id,this.infoLeechero)
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{
    background: #fff;
}
.text-red{
    color: #FF2525;
}
.text-yellow{
    color: #FF9D32;
}
.text-aqua{
    color: #03D6BC;
}
</style>

<template>
    <section>
        <div class="row mx-0 pt-3">
            <div class="panel-recompensas bg-white br-t-12 custom-scroll overflow-auto">
                <div 
                v-for="(sub, s) in subTabs" :key="s" 
                class="row mx-0 pl-3 align-items-center tab-recompensas border-bottom cr-pointer" 
                @click="recompensActiva = s; $router.push({name: sub.ruta}); tituloRecompensa = sub.tituloNavbar"
                >
                    <div :class="`bar ${ recompensActiva == s ? 'bg-general' : ''} `" />
                    <p class="col f-400" :class="recompensActiva == s ? 'text-general' : 'text-general2'">
                        {{ sub.titulo }}
                    </p>
                </div>
            </div>
            <div class="ml-3 bg-white panel-config">
                <navbar-config :title="tituloRecompensa" />
                <div class="px-4 pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 202px)">
                    <router-view />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    components: {
        navbarConfig: () => import('../components/navbarConfig')
    },
    data(){
        return {
            recompensActiva: null,
            tituloRecompensa: '',
            subTabs: [
                { titulo: 'Conversión a dinero', tituloNavbar: 'Valor de monedas y gemas', ruta: 'admin.gaming.valor-monedas-gemas'  },
                { titulo: 'Registro propio', tituloNavbar: 'Registro propio', ruta: 'admin.gaming.registro-propio'},
                { titulo: 'Completar registro', tituloNavbar: 'Completar registro', ruta: 'admin.gaming.completar-registro' },
                { titulo: 'Verificar cuentas de acceso', tituloNavbar: 'Verificar cuentas de acceso', ruta: 'admin.gaming.verificar-cuentas'},
                { titulo: 'Mi primer compra', tituloNavbar: 'Mi primer compra', ruta: 'admin.gaming.primer-compra'},
                {titulo: 'Compras mías y de mis referidos', tituloNavbar: 'Cada compra', ruta: 'admin.gaming.compras-referidos'},
                {titulo: 'Metas de compra en dinero', tituloNavbar: 'Por metas de compras en dinero', ruta: 'admin.gaming.metas-dinero'},
                {titulo: 'Metas de de venta en dinero', tituloNavbar: 'Por metas de ventas en dinero', ruta: 'admin.gaming.metas-venta-dinero'},
                {titulo: 'Metas de compra en cantidad', tituloNavbar: 'Por metas de compra en cantidad de pedidos', ruta: 'admin.gaming.metas-cantidad'},
                {titulo: 'Metas de venta en cantidad', tituloNavbar: 'Por metas de ventas en cantidad de pedido', ruta: 'admin.gaming.metas-venta'},
                { titulo: 'Visitas diarias', tituloNavbar: 'Visitas diarias', ruta: 'admin.gaming.visitas-diarias'},
                { titulo: 'Nuevos referidos', tituloNavbar: 'Nuevo referido', ruta: 'admin.gaming.nuevo-referido'},
                { titulo: 'Primera compra de referido', tituloNavbar: 'Primera compra de referido', ruta: 'admin.gaming.primera-compra'},
                { titulo: 'Perdida de recompensa', tituloNavbar: 'Perdida de recompensa', ruta: 'admin.gaming.perdida-recompensa'},
                //admin.gaming.perdida-recompensa
                //admin.gaming.metas-cantidad
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.panel-recompensas{
    width: 319px;
    height: calc(100vh - 140px);
    box-shadow: 1px 1px 4px #00000014;
    .tab-recompensas{
        height: 60px;
        .bar{
            width: 8px;
            height: 44px;
            border-radius: 12px;
        }
    }
}
.panel-config{
    width: calc(100vw - 445px);
    border-radius: 12px 12px 0px 0px;
    box-shadow: 1px 1px 4px #00000014;
}
</style>
<template>
    <section>
        <div id="contenedor" class="box">
            <div class="row mx-0 px-3">
                <div class="bg-general d-middle-center text-white" style="width:109px;height:37px;border-radius:30px;">
                    <p v-text="configuracion.totalImagenes" />
                    <i class="icon-image-outline f-22" />
                </div>
                <div class="col-9 d-middle">
                    Sube todas las imágenes que te gustaría que los clientes vean de tu tienda, productos y demás, en la aplicación móvil
                </div>
            </div>
            <div class="px-5 mt-3 mb-3 row" style="max-height: calc(100% - 55px);">
                <div
                class="border br-12 d-middle-center cr-pointer m-2"
                style="width:109px;height:109px;border:1px solid #EBEBEB;background-color:#F8F9FF;"
                @click="abrirModalSubirImagen"
                >
                    <img src="/img/icons/add-image.svg" style="width:48px;" />
                </div>

                <div
                v-infinite-scroll="consultarListadoImagenes"
                infinite-scroll-disabled="scrollBloqueado"
                infinite-scroll-distance="10"
                >
                    <div class="row">
                        <el-popover
                        v-for="(item, i) in imagenes"
                        :key="i"
                        placement="bottom"
                        width="100"
                        trigger="hover"
                        >
                            <div
                            class="border br-12 button d-middle-center f-14 my-1 mx-4 cr-pointer"
                            style="width:72px;"
                            @click="abrirModalEditarImagen(item)"
                            >
                                Editar
                            </div>
                            <div
                            class="border br-12 button d-middle-center f-14 my-1 mx-4 cr-pointer"
                            style="width:72px;"
                            @click="abrirModalEliminarImagen(item)"
                            >
                                Eliminar
                            </div>
                            <div slot="reference" class="m-2 cr-pointer" @click="abrirModalGaleria(item)">
                                <img
                                :src="item.imagen_mini"
                                class="border br-12 obj-cover imagen-mini"
                                />
                            </div>
                        </el-popover>
                    </div>
                    <contentLoader v-show="configuracion.cargando" />
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modalSubirImagen ref="SubirImagen" :imagen="imagenSeleccionada" @cargar="guardarImagen" />
        <modal-galeria-imagenes ref="modalGaleriaImagenes" :imagenes="imagenes" />
        <modalEliminarImagen ref="EliminarImagen" @eliminar="eliminarImagen" />
        <!-- Modales -->
    </section>
</template>

<script>
import ServicioApi from '../../services/tienda/galeria'
import infiniteScroll from 'vue-infinite-scroll'

export default {
    name: 'GaleriaTienda',
    middleware:['auth'],
    components: {
        modalSubirImagen: () => import('./partials/modalSubirImagen'),
        modalEliminarImagen: () => import('./partials/modalEliminarImagen')
    },
    directives: { infiniteScroll },
    data(){
        return{
            imagenSeleccionada: {},
            scrollBloqueado: false,
            imagenes: [],
            configuracion: {
                paginaActual: 0,
                ultimaPagina: 1,
                totalImagenes: 0,
                cargando: false
            }
        }
    },
    mounted(){
        this.consultarContadorImagenes()
    },
    methods: {
        async consultarContadorImagenes(){
            try {
                const { data: { data } } = await ServicioApi.consultarCantidadImagenes()
                this.configuracion.totalImagenes = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async consultarListadoImagenes(){
            try {
                if (this.estaEnLaUltimaPagina()) return
                this.alternarCargando()
                this.alternarBloqueoScroll()
                this.aumentarPagina()
                const { data: { data: { last_page: ultimaPagina, data }  } } = await ServicioApi.consultarGaleriaImagenes({
                    page: this.configuracion.paginaActual
                })

                this.imagenes.push(...data)

                this.configuracion.ultimaPagina = ultimaPagina

                if (!this.estaEnLaUltimaPagina()) this.alternarBloqueoScroll()
            } catch (error){
                this.error_catch(error)
            } finally {
                this.alternarCargando()
            }
        },
        estaEnLaUltimaPagina(){
            return this.configuracion.paginaActual === this.configuracion.ultimaPagina
        },
        aumentarPagina(){
            ++this.configuracion.paginaActual
        },
        alternarBloqueoScroll(){
            this.scrollBloqueado = !this.scrollBloqueado
        },
        alternarCargando(){
            this.configuracion.cargando = !this.configuracion.cargando
        },
        async guardarImagen(payload){
            try {
                this.alternarCargando()
                const { data: { data } } = await ServicioApi.guardarImagen(payload)

                if (payload.id){
                    const indice = this.imagenes.findIndex(item => item.id === payload.id)
                    this.imagenes[indice] = data
                } else {
                    this.aumentarContador()
                    this.imagenes.unshift(data)
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.alternarCargando()
            }
        },
        seleccionarImagen(imagen){
            this.imagenSeleccionada = imagen
        },
        aumentarContador(){
            ++this.configuracion.totalImagenes
        },
        disminuirContador(){
            --this.configuracion.totalImagenes
        },
        abrirModalGaleria(imagen){
            this.seleccionarImagen(imagen)
            this.$refs.modalGaleriaImagenes.toggle()
        },
        abrirModalSubirImagen(){
            this.$refs.SubirImagen.toggle()
        },
        abrirModalEditarImagen(imagen){
            this.seleccionarImagen(imagen)
            this.abrirModalSubirImagen()
        },
        abrirModalEliminarImagen(imagen){
            this.seleccionarImagen(imagen)
            this.$refs.EliminarImagen.toggle()
        },
        async eliminarImagen(){
            try {
                const { id: idImagen } = this.imagenSeleccionada
                await ServicioApi.eliminarImagen(idImagen)
                this.imagenes = this.imagenes.filter(item => item.id !== idImagen)
                this.disminuirContador()
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss">
.br-12{
    border-radius: 12px;
}
.imagen-mini{
    width:109px;
    height:109px;
    transition: border 200ms ease-out;

    &:hover{
        border: 2px solid #03D6BC !important
    }
}
.button{
    &:hover{
        background-color: #F5F5F5;
    }
}
</style>

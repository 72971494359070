import axios from "axios";

const API = "referidos";

const referidos = {
    getListarReferidos(params){
        return axios(`${API}/referidos`,{params});
    },
    getListarGrupos(){
        return axios(`${API}/grupos`);
    },
    getReferidosCliente(id,params){
        return axios(`${API}/${id}/referidos_cliente`,{params});
    },
    postGuardarGrupo(params){
        return axios.post(`${API}/crear_grupo`, params);
    },
    putActualizarGrupo(params,id){
        return axios.put(`${API}/${id}/editar_grupo`,params);
    },
    deleteGrupo(id){
        return axios.delete(`${API}/${id}/delete_grupo`);
    }
};

export default referidos;

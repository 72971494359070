import axios from 'axios'


const api = "nomina/detalle"


const nominaDetalleEstadisticas = {
    grafica12Meses: (id, params) => axios.get(`${api}/grafica-12-meses/${id}`, { params }),
    conMasCompras: params => axios.get(`${api}/con-mas-compras`, { params }),
    detalleAfilidados: params => axios.get(`${api}/detalle-afilidados`, { params }),
    liquidaciones: (id, params) => axios.get(`${api}/liquidaciones/${id}`, { params }),
    datosGenerales: (id, params) => axios.get(`${api}/datos-generales/${id}`, { params }),
    getComprasAfiliados(params){
        return axios.get(`${api}/compras/afiliados`,{params})
    },
    putCupoAfiliados(idUser, model){
        return axios.put(`${api}/compras/${idUser}/afiliado/actualizar-cupo`, model)
    }
}

export default nominaDetalleEstadisticas
<template>
    <div style="height:calc(100vh - 86px);">
        <div class="row mx-0 border-bottom">
            <tabs :tabs="tabs" />
        </div>
        <router-view />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Soporte from '~/services/soporte/tiendas'
import Novedades from '~/services/soporte/novedades'

export default {
    middleware:['auth'],
    metaInfo: () =>({ title: 'Soporte'}),
    computed: {
        tabsReales(){
            return{
                admin: [
                    { titulo: `General ${this.$config.vendedor}`, ruta: 'admin.soporte.tiendas', num: this.cant_tiendas, can : 'tab_soporte_leechero' },
                    { titulo: `General ${this.$config.cliente}`, ruta: 'admin.soporte.general', num: this.cant_users_soportes, can : 'tab_soporte_general' },
                    { titulo: `Pedidos ${this.$config.cliente}`, ruta: 'admin.soporte.reclamo', num: this.cant_reclamos, can : 'tab_soporte_reclamo' },
                    { titulo: `Pedidos ${this.$config.vendedor}`, ruta: 'admin.soporte.reclamos-leecheros', num: this.cant_reclamos_leecheros, can : 'tab_soporte_reclamo_leecheros' },
                    { titulo: 'Chats pedidos vigentes', ruta: 'admin.soporte.chats-vigentes', num: this.cant_vigentes, can : 'tab_soporte_chat_vigentes' },
                    { titulo: 'Deliverys', ruta: 'admin.soporte.novedades-delivery', num: this.cant_novedades, can : 'tab_soporte_novedades_delivery' },
                ]
            }
        },
        tabs(){
            return this.tabsReales[this.$usuario.rol_nombre]
        },
        ...mapGetters({
            cant_tiendas: 'soporte/tiendas/cant_tiendas',
            cant_users_soportes: 'soporte/tiendas/cant_users_soportes',
            cant_reclamos: 'reclamos/reclamos/cant_reclamos',
            cant_reclamos_leecheros: 'reclamos/reclamosLeecheros/cant_reclamos',
            cant_vigentes: 'pedidos/pedidos_admin/cant_reclamos',
            cant_novedades: 'soporte/novedades/cant_novedades',
            id_cedis:'cedis/id_cedis'
        }),
    },
    watch: {
        id_cedis(val,oldVal){
            if(val != null){
                if(oldVal == null) return
                this.listarUsesClientes()
                this.saberCantidadReclamos()
                this.saberCantidadReclamosLeecheros()
                this.saberCantidadVigentes()
                this.listarTiendas()
                this.saberCantidadNovedades()
            }
        }
    },
    mounted(){
        this.listarUsesClientes()
        this.saberCantidadReclamos()
        this.saberCantidadReclamosLeecheros()
        this.saberCantidadVigentes()
        this.listarTiendas()
        this.saberCantidadNovedades()
    },
    methods:{
        async listarUsesClientes(){
            try {
                if(this.$route.name==='admin.soporte.general' || this.$route.name==='admin-vip.soporte.general'){ // no lo tiene que hacer
                    return
                }
                const params = {
                    page: 1
                }
                const {data} = await Soporte.listarUsersClientes(params)
                this.$store.commit('soporte/tiendas/set_cant_users_soportes', +data.cant_msg_total)

            } catch (e){
                this.error_catch(e)
            }
        },
        async saberCantidadReclamos(){
            if(this.$route.name==='admin.soporte.reclamo'){ // no lo tiene que hacer
                return
            }
            const enviar = {
                vista: 4,
                id_cedis: this.id_cedis
            }
            await this.$store.dispatch('reclamos/reclamos/action_listar_pedidos', enviar)

        },
        async saberCantidadReclamosLeecheros(){
            if(this.$route.name==='admin.soporte.reclamos-leecheros'){ // no lo tiene que hacer
                return
            }
            const enviar = {
                vista: 1,
                id_cedis: this.id_cedis
            }
            await this.$store.dispatch('reclamos/reclamosLeecheros/action_listar_pedidos', enviar)
        },
        async saberCantidadVigentes(){
            if(this.$route.name==='admin.soporte.chats-vigentes'){ // no lo tiene que hacer
                return
            }
            await this.$store.dispatch('pedidos/pedidos_admin/listarChatsVigentes',this.id_cedis)
        },
        async saberCantidadNovedades(){
            if(this.$route.name == 'admin.soporte.novedades-delivery'){ // no lo tiene que hacer
                return
            }
            console.log('aui');
            const params = {
                id_cedis: this.id_cedis,
            }
            const {data} = await Novedades.listarNovedades(params)
            this.$store.commit('soporte/novedades/set_cant_novedades', data.cant_msg_total)
        },
        async listarTiendas(){
            try {
                if(this.$route.name==='admin.soporte.tiendas'){ // no lo tiene que hacer
                    return
                }
                const params = {
                    page: 1,
                }
                const {data} = await Soporte.listarTiendas(params)
                this.$store.commit('soporte/tiendas/set_cant_tiendas', data.cant_msg_total)

            } catch (e){
                this.error_catch(e)
            }
        },
    }



}
</script>
<style lang="scss" scoped>
.box{
    border-radius: 12px 12px 0px 0px;
}
</style>

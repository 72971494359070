<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 76px)">
        <div class="pb-5">
            <div class="row mx-0 j-center px-3 px-lg-0  mt-5">
                <div class="col-12 col-lg-10">
                    <p class="text-general f-300 mb-2 f-14">
                        {{ $config.proyecto }}
                    </p>
                    <div class="mb-3">
                        <p class="f-18 f-600">Datos a tener en cuenta</p>
                        <div class="like-bar mb-3" />
                        <p class="mb-2">1) Lo primero que debes saber es que vas a perder todo tu historial de pedidos, es decir que, no volverás a tener las transacciones que has realizado durante el tiempo que tuviste en la plataforma.</p>
                        <p>2) se va a perder tu identificación de usuario. Esto quiere decir que, tu perfil se eliminara por completo como si nunca hubiese existido. Si más adelante quieres volver, entonces tendrás que iniciar desde cero.</p>
                    </div>
                    <p class="text-general f-28 f-500">
                        Pasos para eliminar cuenta de {{ $config.proyecto }}
                    </p>
                    <div class="like-bar mt-2" />
                    <div class="row j-center px-3 px-lg-0 mt-4">
                        <div class="col-12 col-md-8 col-lg-4 my-4">
                            <div class="border br-8 p-2">
                                <p class="text-general my-2 h-48px">1. Ir al perfil del usuario.</p>
                                <div class="text-center">
                                    <img src="/img/deleteAccount/perfil.png" alt="" class="img-step w-100 h-600px border" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-8 col-lg-4 my-4">
                            <div class="border br-8 p-2">
                                <p class="text-general my-2 h-48px">2. Deslizar hasta el final y presionar el boton "Darte de baja".</p>
                                <div class="text-center">
                                    <img src="/img/deleteAccount/finalPerfil.png" alt="" class="img-step w-100 h-600px border" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-8 col-lg-4 my-4">
                            <div class="border br-8 p-2">
                                <p class="text-general my-2 h-48px"> 3. Confirmar acción con el boton "Si, darme de baja". </p>
                                <div class="text-center">
                                    <img src="/img/deleteAccount/eliminar.png" alt="" class="img-step w-100 h-600px border" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    layout: 'informativaLogin',
}
</script>
<style lang="scss" scoped>
.text-answer{
    white-space: pre-line ;
}
.spacer{
    height: 50px !important;
}
.img-step{
	width: 100%;
	max-width: 357px;
}
.h-600px{
	height: 515px;
}

.h-48px{
	height: 48px;
}
</style>

import ServicioApi from '../../services/faqs/faqs'
import moment from 'moment'

const DESTINOS_VALORES = { tendero: 1, cliente: 2}
const DESTINOS_NOMBRES = { 1: 'tendero', 2: 'cliente'}

export default {
    data(){
        return {
            faqs: [],
            fechaActualizado: '',
            destinoSeleccionado: DESTINOS_VALORES.tendero,
            tipo: 1,
            itemSeleccionado: {},
            modoLectura: 1,
            rutas: {
                creacion: ['admin', 'admin-vip', 'tendero-vip'],
                ver: ['admin', 'tendero']
            }
        }
    },
    computed: {
        diferenciaMeses(){
            return this.obtenerDiferenciaFecha(this.fechaActualizado)(true)
        },
        diferenciaDias(){
            return this.obtenerDiferenciaFecha(this.fechaActualizado)(false)
        },
        hayFechaActualizacion(){
            return !_.isNaN(this.diferenciaMeses) && !_.isNaN(this.diferenciaDias)
        },
        tiempoActualizacion(){
            return this.diferenciaMeses > 0
                ? `hace ${this.obtenerPluralSingular(this.diferenciaMeses)('mes')('es')}`
                : this.diferenciaDias > 0 ? `hace ${this.obtenerPluralSingular(this.diferenciaDias)('día')('s')}` : 'hoy'
        },
        textosDestinos(){
            return {
                actual: DESTINOS_NOMBRES[this.destinoSeleccionado],
                cambiar: DESTINOS_NOMBRES[this.encontrarDestinoSeleccionado()]
            }
        },
        estaEditando(){
            return !_.isEmpty(this.itemSeleccionado) && this.itemSeleccionado.hasOwnProperty('id')
        },
        rutaTipo(){
            return this.$route.name.split('.')[0]
        },
        creacionCompleta(){
            return this.rutas.creacion.includes(this.rutaTipo)
        },
        visibilidadCompleta(){
            return this.rutas.ver.includes(this.rutaTipo)
        },
    },
    methods: {
        async consultarFaqs(tipo, modoLectura = this.modoLectura){
            try {
                this.limpiarElementoSeleccionado()
                const { data: { data } } = await ServicioApi.consultarFaqs({ tipo, destino: this.destinoSeleccionado, modoLectura })
                this.faqs = tipo === 1 ? this.mapearPreguntasFrecuentes(data) : this.mapearOtrosFaqs(data.faqs)
                this.fechaActualizado = data.actualizado
                this.tipo = tipo
                this.modoLectura = modoLectura
            } catch (error){
                console.error(error)
            }
        },
        mapearPreguntasFrecuentes: array => array.map(x => ({
            ...x,
            editando: false,
            preguntas: x.preguntas.map(p => ({ ...p, colapsado: true }))
        })),
        mapearOtrosFaqs: array => array.map(x => ({ ...x, colapsado: true })),
        intentarCambiarDestino(){
            if (this.visibilidadCompleta) this.cambiarDestino()
        },
        cambiarDestino(){
            this.destinoSeleccionado = this.encontrarDestinoSeleccionado()
            this.consultarFaqs(this.tipo)
        },
        encontrarDestinoSeleccionado(){
            return this.destinoSeleccionado === DESTINOS_VALORES.tendero ?  DESTINOS_VALORES.cliente : DESTINOS_VALORES.tendero
        },
        seleccionarItemComun(item){
			const { id, titulo, estado, texto } = item
			const { id: id_texto , texto: txt } = texto || {}

            this.limpiarElementoSeleccionado()
            this.itemSeleccionado = { id, titulo, estado, texto: txt , id_texto }
        },
        limpiarElementoSeleccionado(){
            this.itemSeleccionado = {}
        },
        async crearFaq(payload){
            try {
                await ServicioApi.crearFaq(this.generarPayload(payload))
                this.consultarFaqs(this.tipo)
            } catch (error){
                console.error(error)
            }
        },
        actualizarEstado({ id, estado }){
            ServicioApi.actualizarFaq({ id, estado, editar_estado: 1 })
                .then(() => { this.consultarFaqs(this.tipo) })
                .catch(console.error)
        },
        actualizarFaq(payload){
            ServicioApi.actualizarFaq(this.generarPayload(payload))
                .then(() => { this.consultarFaqs(this.tipo) })
                .catch(console.error)
        },
        eliminarFaq(){
            ServicioApi.eliminarFaq(this.itemSeleccionado.id)
                .then(() => {
                    this.alternarModalEliminacion()
                    this.consultarFaqs(this.tipo)
                })
                .catch(console.error)
        },
        generarPayload({ titulo, texto }){
            return {
                destino: !this.creacionCompleta ? this.DESTINOS_VALORES.cliente : this.destinoSeleccionado,
                tipo: this.tipo,
                titulo,
                ...(texto && { texto }),
                ...(this.itemSeleccionado.estado && { estado: this.itemSeleccionado.estado }),
                ...(this.itemSeleccionado.id && { id: this.itemSeleccionado.id }),
                ...(this.itemSeleccionado.id_texto && { id_texto: this.itemSeleccionado.id_texto }),
                ...(this.itemSeleccionado.id_padre && { id_padre: this.itemSeleccionado.id_padre }),
                ...(!this.creacionCompleta && { id_tienda: this.$usuario.tienda.id })
            }
        },
        alternarModalEliminacion(){
            this.$refs.modalEliminarFaq.toggle()
        },
        async actualizar_posicion(event){
            try {
                const datos = {
                    antiguaPosicion: event.moved.oldIndex + 1,
                    nuevaPosicion: event.moved.newIndex + 1,
                    id: event.moved.element.id,
                    tipo: this.tipo,
                    destino: this.destinoSeleccionado
                }
                const {data} = await ServicioApi.actualizarPosicion(datos)
                this.notificacion('','Actualizó','success')
                this.consultarFaqs(this.tipo)
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizar_posicion_hijos(event, id_padre){
            try {
                // console.log('actualizar_posicion_hijos',event.moved.element);
                const datos = {
                    antiguaPosicion: event.moved.oldIndex + 1,
                    nuevaPosicion: event.moved.newIndex + 1,
                    // id_padre: event.moved.element.id,
                    id_padre: id_padre,
                    tipo: this.tipo,
                    destino: this.destinoSeleccionado
                }
                console.log('data', datos);
                const {data} = await ServicioApi.actualizarPosicionHijos(datos)
                this.notificacion('','Actualizó','success')
                this.consultarFaqs(this.tipo)
            } catch(e){
                this.error_catch(e)
            }
        },

        textoEsCoincidente: x => busqueda => x.toLowerCase().trim().includes(busqueda.toLowerCase().trim()),
        colapsar: item => { item.colapsado = !item.colapsado },
        colocarIconoColapsado: estaColapsado => estaColapsado ? 'icon-angle-down' : 'icon-angle-up',
        obtenerDiferenciaFecha: fecha => tipoMes => moment().diff(fecha, tipoMes ? 'month' : 'days'),
        obtenerPluralSingular: contador => singular => (sufijo = 's') => `${contador} ${singular}${contador !== 1 ? sufijo : ''}`
    }
}

<template>
    <section class="custom-scroll overflow-auto pb-2" style="height:calc(100vh - 180px);position:relative;">
        <cargando v-if="loading" />
        <div v-else class="row mx-0 py-2">
            <div class="col-auto d-middle-center text-general">
                <span class="mr-2">Fecha</span>
            </div>
            <div class="col-4">
                <el-date-picker
                v-model="fechas"
                size="small"
                class="w-100"
                type="daterange"
                range-separator="-"
                start-placeholder="Fecha inicio"
                end-placeholder="Fecha fin"
                format="dd MMM yyyy"
                value-format="yyyy-MM-dd"
                :clearable="false"
                />
            </div>
            <div class="col-auto">
                <div class="bg-general shadow cr-pointer text-white br-8 px-4 py-1" @click="buscar()">
                    Mostrar productos
                </div>
            </div>
        </div>
        <div class="row mx-0 py-3" />
        <div v-for="(producto, i) in productos" :key="i" class="d-flex mx-3 border br-4 py-1 px-2 align-items-center mb-3">
            <img :src="producto.foto" width="46" height="46" class="obj-cover br-4" />
            <div class="col-5">
                <p class="text-general f-15 f-600 tres-puntos">{{ producto.nombre }}</p>
                <p class="text-general f-15 tres-puntos">{{ producto.presentacion }}</p>
            </div>
            <div class="col-3">
                <p class="text-general f-15 f-600 tres-puntos">{{ separadorNumero(producto.sum_total) }}</p>
                <p class="text-general f-15 tres-puntos">{{ agregarSeparadoresNumero(producto.sum_cantidad) }} Unidades</p>
            </div>
            <div class="co-4l">
                <div class="row mx-0 text-general align-items-center">
                    <i class="icon-package-variant-closed f-18 text-general" />
                    <b>{{ formatearFecha(producto.ultima_compra) }}</b>
                </div>
                <div class="row mx-0 text-general align-items-center">
                    <i class="icon-shopping f-18 text-general" />
                    Última compra
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div class="bg-general3 px-3 mx-1 py-1 br-20 text-white cr-pointer" @click="fetchData(10)">
                Ver más 10
            </div>
            <div class="bg-general3 px-3 mx-1 py-1 br-20 text-white cr-pointer" @click="fetchData(50)">
                Ver más 50
            </div>
        </div>
    </section>
</template>

<script>
import estadistica from '~/services/clientes'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            url_image: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg',
            value1: '',
            productos: [],
            fechas: [moment().subtract(1, 'months').toDate(), moment().toDate()],
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch:{
        id_moneda(val, oldVal){
            if(oldVal == null) return
            setTimeout(() => {
                this.productos = []
                this.fetchData()
            }, 500);
        }
    },
    mounted(){
        setTimeout(() => {
            this.fetchData()
        }, 500);
    },
    methods: {
        async fetchData(paginate = 10){
            try {
                this.loading = true
                let params = {
                    id: this.$route.params.id_cliente,
                    offset: this.productos.length,
                    paginate,
                    fechas: this.fechas,
                    moneda: this.id_moneda
                }
                const { data } = await estadistica.productosComprados(params)
                this.productos = [...this.productos, ...data]
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        estadoIcono(estado){
            return iconos[estado] ?? iconos[1]
        },
        buscar(){
            this.productos = []
            this.fetchData()
        }
    }
}
</script>
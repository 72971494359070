<template>
    <section class="position-relative">
        <cargando v-if="loading" />
        <tabla-general :data="afiliados" alto="calc(100vh - 397px)" class-header="text-general f-16" :usar-paginacion="false" @filaFuncion="accionesTabla">
            <template #cabecera-izquierda>
                <div class="col pr-0">
                    <div class="row mx-0 align-items-center">
                        <div class="col-auto px-0 text-general f-18 f-500">
                            Afiliados
                        </div>
                        <div class="col-auto d-flex align-items-end ml-auto">
                            <i class="icon-account-check text-green f-20" />
                            <p class="text-general lh-18 ml-1">
                                <span class="f-12 text-general">Activos</span> <br />
                                <span class="text-general f-500 f-22">{{total_activos}}</span>
                            </p>
                        </div>
                        <div class="col-auto d-flex align-items-end">
                            <i class="icon-account-remove-outline text-general-red f-20" />
                            <p class="text-general lh-18 ml-1">
                                <span class="f-12 text-general">No afiliados</span> <br />
                                <span class="text-general f-500 f-22">{{total_inactivos}}</span>
                            </p>
                        </div>
                        <div class="col-auto d-flex align-items-end">
                            <i class="icon-account-wait text-orange f-20" />
                            <p class="text-general lh-18 ml-1">
                                <span class="f-12 text-general">Solicitudes</span> <br />
                                <span class="text-general f-500 f-22">{{total_pendientes}}</span>
                            </p>
                        </div>
                        <div v-if="$can('botones_nomina_liquidar_afiliar') || $usuario.liquidador" class="btn-general f-14 px-3" @click="$router.push({name: 'admin.nomina.empresas.liquidar'})">
                            Liquidar
                        </div>
                        <div v-if="$can('botones_nomina_liquidar_afiliar') || $usuario.liquidador" class="btn-general f-14 px-3 ml-2" @click="afiliarUsuario(true)">
                            Afiliar
                        </div>
                        <div class="btn-action border ml-2" @click="filtrarAfiliados()">
                            <i class="icon-filter-outline f-20" />
                        </div>
                    </div>
                </div>
            </template>
            <template slot="adicionales-izquierda">
                <el-table-column width="50">
                    <template slot-scope="{row}">
                        <div class="row mx-0 justify-center">
                            <i :class="iconEstado(row.estado)" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="Código" min-width="80" />
                <el-table-column prop="nombre" label="Nombre" min-width="160" />
                <el-table-column prop="cedula" label="Documento" min-width="160" />
                <el-table-column prop="pedidos" label="Pedidos" min-width="90" align="center" />
                <el-table-column label="Deuda" min-width="160">
                    <template slot-scope="{row}">
                        <p class="text-general f-14">
                            {{ convertMoney(row.deuda) }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column min-width="80">
                    <template slot="header">
                        <i class="icon-truck-check f-18 text-green text-center" />
                    </template>
                    <template slot-scope="{row}">
                        <p>{{ row.entregados }}</p>
                    </template>
                </el-table-column>

                <el-table-column label="Cupo" min-width="160">
                    <template slot-scope="{row}">
                        <p v-if="row.cupo === 0" class="text-general f-14">
                            Ilimitado
                        </p>
                        <p v-else class="text-general f-14">
                            {{ convertMoney(row.cupo) }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column label="Compras Totales" min-width="160">
                    <template slot-scope="{row}">
                        <p class="text-general f-14">
                            {{ convertMoney(row.total_compras) }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column label="Fecha Vinculación" min-width="160">
                    <template slot-scope="{row}">
                        <p class="text-general f-14">
                            {{ row.fecha_vinculacion | helper-fecha('DD MMM YYYY') }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column label="Fecha Registro" min-width="160">
                    <template slot-scope="{row}">
                        <p class="text-general f-14">
                            {{ row.fecha_registro | helper-fecha('DD MMM YYYY') }}
                        </p>
                    </template>
                </el-table-column>
            </template>
        </tabla-general>
        <div v-show="contador < afiliadosTodos.length" class="row mx-0 justify-center bg-white pb-3 br-b-12 ">
            <div class="br-20 d-middle-center text-white bg-general3 f-14 px-3 cr-pointer mt-3" style="height:28px" @click="contador = contador + 10">
                Ver más 10
            </div>
            <div class="br-20 d-middle-center text-white bg-general3 f-14 px-3 cr-pointer ml-2 mt-3" style="height:28px" @click="contador = contador + 50">
                Ver más 50
            </div>
        </div>
        <!-- Partials -->
        <modal-aceptar-afiliacion ref="modalAfiliacion" :buscar="buscarModal" />
        <modal-filtro-afiliados ref="filtroAfiliados" @filtrar="getAfiliados" />
    </section>
</template>

<script>
import Afiliados from '~/services/nomina/afiliados'
export default {
    components: {
        modalAceptarAfiliacion: () => import('../partials/modalAfiliacion'),
        modalFiltroAfiliados: () => import('../partials/modalFiltroAfiliados')
    },
    data(){
        return {
            checked: false,
            buscarModal: false,
            afiliadosTodos:[],
            loading:false,
            contador:20,
            limiteAlcanzado:false,
            total_activos:0,
            total_inactivos:0,
            total_pendientes:0
        }
    },
    computed:{
        idConvenio(){
            return Number(this.$route.params.id_convenio)
        },
        afiliados(){
            let data = this.afiliadosTodos.slice(0,this.contador)

            return data
        }
    },
    mounted(){
        this.getAfiliados()
    },
    methods: {
        async getAfiliados(filtros = {}){
            try {
                this.loading = true
                const {data} = await Afiliados.getAfiliados(this.idConvenio, filtros)
                this.afiliadosTodos = []
                this.afiliadosTodos = data.afiliados
                this.total_activos = data.total_activos
                this.total_inactivos = data.total_inactivos
                this.total_pendientes = data.total_pendientes

            } catch (error){
                this.error_catch(error)
            }finally {
                this.loading = false
            }
        },
        async getAfiliadosFiltro(){
            try {

                const {data} = await Afiliados.getAfiliados(this.id_convenio)



            } catch (error){
                this.error_catch(error)
            }
        },
        iconEstado(state){
            switch (state){
            case 1:
                return 'icon-account-wait text-orange f-18';
            case 2:
            case 3:
                return 'icon-account-check text-green f-18';
            case 4:
            case 5:
            case 6:
                return 'icon-account-remove-outline text-general-red f-18';
            }
        },
        accionesTabla(row){
            switch (row.estado){
            case 1:
                this.getDetalleSolicitudAfiliado(row.id);
                break;
            case 2:
            case 3:
                this.$router.push({name: 'admin.nomina.empresas.afiliados', params: { id_convenio: this.idConvenio, id_cliente:row.id }});
                break;
            }
        },
        afiliarUsuario(){
            this.$refs.modalAfiliacion.toggle();
        },
        getDetalleSolicitudAfiliado(idUser){
            this.$refs.modalAfiliacion.getSolicitud(idUser);
        },
        filtrarAfiliados(){
            this.$refs.filtroAfiliados.toggle();
        }
    }
}
</script>

<template>
    <section>
        <div class="row mx-0 pt-4">
            <div class="col">
                <graficaComponent
                titulo="Deudas sin liquidar"
                :options="deudasSinLiquidarOptions"
                :total="deudasSinLiquidarTotal"
                rojo
                @verMas="verMas(1)"
                />
            </div>
            <div class="col">
                <graficaComponent 
                :titulo="`Liquidaciones sin verificación de ${$config.proyecto}`"
                :options="liquidacionesSinVerificacionOptions"
                :total="liquidacionesSinVerificacionTotal"
                @verMas="verMas(2)"
                />
            </div>
        </div>
        <div class="row mx-0 pt-4">
            <div class="col">
                <graficaComponent
                titulo="Cupo otorgado a los afiliados"
                :options="cupoOtorgadoOptions"
                :total="cupoOtorgadoTotal"
                @verMas="verMas(3)"
                />
            </div>
            <div class="col">
                <graficaComponent 
                titulo="Solicitudes de afiliación pendientes"
                :options="solicitudesOptions"
                tipo-valor
                rojo
                :total="solicitudesTotal"
                @verMas="verMas(4)"
                />
            </div>
        </div>
        <div class="row mx-0 justify-content-start mt-5 mb-3">
            <div class="col-auto d-middle text-general f-17 f-500">
                Rango de análisis
            </div>
            <div class="col-3">
                <el-select v-model="rango" placeholder="Seleccionar" size="small" class="w-100">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-3 px-0 mx-2 date-picker-edit">
                <el-date-picker
                v-model="fecha"
                class="w-100"
                size="small"
                :disabled="disable_fecha"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="daterange"
                unlink-panels
                start-placeholder="Fecha inicio"
                end-placeholder="Fecha fin"
                :clearable="false"
                />
            </div>
            <div class="col-auto">
                <div class="btn-general bg-general text-white p-1 px-3 f-14 br-8" @click="graficasConFechas()">
                    Mostrar informe
                </div>
            </div>
        </div>
        <div class="row mx-0 pt-4">
            <div class="col">
                <graficaComponent
                titulo="Ventas por convenio vs pago de contado"
                :options="comprasConvenioOptions"
                :total="comprasConvenioTotal"
                @verMas="verMas(5)"
                />
            </div>
            <div class="col">
                <graficaComponent 
                titulo="Calificación de los pedidos"
                :options="calificacionPedidosOptions"
                star
                :total="calificacionPedidosTotal"
                @verMas="verMas(6)"
                />
            </div>
        </div>
        <modal-progress-gris ref="modalProgressGris" :fecha="fecha" />
    </section>
</template>

<script>
import estadisticas from '~/services/nomina/estadisticas'
import moment from 'moment'

const getRich = arr => {
    return arr.reduce((acc, el) => {
        acc[el.nombre] = {
            height: 40,
            align: 'center',
            borderRadius: 15,
            backgroundColor: {
                image: el.imagen || 'img/no-imagen/default.jpg'
            }
        }
        return acc
    }, {})
}
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')

export default {
    components:{
        graficaComponent: () => import('./components/graficaComponent.vue'),
        modalProgressGris: () => import('./partials/modalProgressGris.vue'),

    },
    data(){
        return {
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            deudasSinLiquidar: [],
            liquidacionesSinVerificacion: [],
            cupoOtorgado: [],
            solicitudes: [],
            comprasConvenio: [],
            calificacionPedidos: [],
        }   
    },
   
   
    computed: {
        deudasSinLiquidarTotal(){
            return _.sumBy(this.deudasSinLiquidar, 'suma')
        },
        liquidacionesSinVerificacionTotal(){
            return _.sumBy(this.liquidacionesSinVerificacion, 'suma')
        },
        cupoOtorgadoTotal(){
            return _.sumBy(this.cupoOtorgado, 'cupo')
        },
        solicitudesTotal(){
            return _.sumBy(this.solicitudes, 'count')
        },
        comprasConvenioTotal(){
            return _.sumBy(this.comprasConvenio, (el) => Number(el.sum_convenio) + Number(el.sum_sin_convenio))
        },
        calificacionPedidosTotal(){
            return _.meanBy(this.calificacionPedidos, (el) => Number(el.promedio))
        },
        deudasSinLiquidarOptions(){
            const serieData = this.deudasSinLiquidar.map(el => el.suma)
            const conveniosData = this.deudasSinLiquidar.map(el => el.nombre)
            //const rich = getRich(this.deudasSinLiquidar)

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: conveniosData,
                    axisLabel: {
                        show: true,
                        fontSize: 14,
                        borderRadius: 12,
                        formatter: function(value){
                            //return '{' + value + '| }';
                            return value;
                        },
                        //rich: {
                        //    value: {
                        //        lineHeight: 30,
                        //        borderRadius: 12,
                        //        align: 'center'
                        //    },
                        //    ...rich,
                        //}
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#556677'
                        },
                        formatter: '$ {value}'
                    },
                    
                },
                series: [
                    {
                        data: serieData,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: '#DFE4E8'
                        }
                    }
                ]   
            }
        },
        liquidacionesSinVerificacionOptions(){
            const serieData = this.liquidacionesSinVerificacion.map(el => el.suma)
            const conveniosData = this.liquidacionesSinVerificacion.map(el => el.nombre)
            //const rich = getRich(this.liquidacionesSinVerificacion)

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: conveniosData,
                    axisLabel: {
                        show: true,
                        fontSize: 14,
                        borderRadius: 12,
                        formatter: function(value){
                            //return '{' + value + '| }';
                            return value;
                        },
                        //rich: {
                        //    value: {
                        //        lineHeight: 30,
                        //        borderRadius: 12,
                        //        align: 'center'
                        //    },
                        //    ...rich,
                        //}
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#556677'
                        },
                        formatter: '$ {value}'
                    },
                    
                },
                series: [
                    {
                        data: serieData,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: '#DFE4E8'
                        }
                    }
                ]   
            }
        },
        cupoOtorgadoOptions(){
            const barData = this.cupoOtorgado.map(el => el.cupo)
            const lineData = this.cupoOtorgado.map(el => el.count)
            const conveniosData = this.cupoOtorgado.map(el => el.nombre)
            //const rich = getRich(this.cupoOtorgado)

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: conveniosData,
                    axisLabel: {
                        show: true,
                        fontSize: 14,
                        borderRadius: 12,
                        formatter: function(value){
                            //return '{' + value + '| }';
                            return value;
                        },
                        //rich: {
                        //    value: {
                        //        lineHeight: 30,
                        //        borderRadius: 12,
                        //        align: 'center'
                        //    },
                        //    ...rich,
                        //}
                    }
                },
                yAxis: [{
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#556677'
                        },
                        formatter: '$ {value}'
                    },
                    
                },
                {
                    type: 'value',
                    position: 'right',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#556677'
                        },
                        formatter: '{value}'
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#DCE2E8'
                        }
                    },
                    splitLine: {
                        show: false
                    }
                    
                }],
                series: [
                    {
                        data: barData,
                        type: 'bar',
                        showBackground: true,
                        stack: 'stack',
                        backgroundStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    {
                        data: lineData,
                        type: 'line',
                        yAxisIndex: 1,
                        symbol:'circle',
                        symbolSize: 10,
                        showBackground: true,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]   
            }
        },
        solicitudesOptions(){
            const serieData = this.solicitudes.map(el => el.count)
            const conveniosData = this.solicitudes.map(el => el.nombre)
            //const rich = getRich(this.solicitudes)

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
               
                },
              
                xAxis: {
                    data: conveniosData,
                    type: 'category',
                    axisLabel: {
                        show: true,
                        fontSize: 14,
                        borderRadius: 12,
                        formatter: function(value){
                            //return '{' + value + '| }';
                            return value;
                        },
                        //rich: {
                        //    value: {
                        //        lineHeight: 30,
                        //        borderRadius: 12,
                        //        align: 'center'
                        //    },
                        //    ...rich,
                        //}
                    }
                },
                yAxis: {
                    type: 'value',
                    
                },
                series: [
                    {
                        data: serieData,
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: '#DFE4E8'
                        },
                        tooltip: {
                            formatter: param => {
                                const data = param.data
                                return [
                                    `<span style="color:${param.color};"></span> ${moment(moment()).format('DD MMM YYYY')} <br/> ${this.separadorNumero(123123)}<br/> `
                                ].join('')
                            }
                        }
                    }
                ]   
            }
        },
        comprasConvenioOptions(){
            //const rich = getRich(this.comprasConvenio)
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['Convenio', 'De contado', 'Pedidos por convenio', 'Pedido de contado']
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        show: true,
                        fontSize: 14,
                        borderRadius: 12,
                        formatter: function(value){
                            //return '{' + value + '| }';
                            return value;
                        },
                        //rich: {
                        //    value: {
                        //        lineHeight: 30,
                        //        borderRadius: 12,
                        //        align: 'center'
                        //    },
                        //    ...rich,
                        //}
                    }
                },
                dataset:{
                    dimensions:['nombre', 'sum_convenio', 'sum_sin_convenio', 'count_convenio', 'count_sin_convenio'],
                    source: this.comprasConvenio
                },
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        min: 0,
                        axisLabel: {
                            formatter: '$ {value}'
                        }
                    },
                    {
                        type: 'value',
                        position: 'right',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                        },
                       
                    }
                ],
                series: [
                    {
                        name: 'Convenio',
                        type: 'bar',
                        barWidth: 50,
                        stack: 'stack',
                        itemStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    {
                        name: 'De contado',
                        type: 'bar',
                        barWidth: 50,
                        stack: 'stack',
                        itemStyle: {
                            color: '#637381'
                        }
                    },
                    {
                        name: 'Pedidos por convenio',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Pedido de contado',
                        type: 'line',
                        symbol:'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        itemStyle: {
                            color: '#000000'
                        },
                    },
                ]
            }
               
        },
        calificacionPedidosOptions(){
            const barData = this.calificacionPedidos.map(el => el.promedio)
            const lineData = this.calificacionPedidos.map(el => el.count)
            const conveniosData = this.calificacionPedidos.map(el => el.nombre)
            //const rich = getRich(this.calificacionPedidos)

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: conveniosData,
                    axisLabel: {
                        show: true,
                        fontSize: 14,
                        borderRadius: 12,
                        formatter: function(value){
                            //return '{' + value + '| }';
                            return value;
                        },
                        //rich: {
                        //    value: {
                        //        lineHeight: 30,
                        //        borderRadius: 12,
                        //        align: 'center'
                        //    },
                        //    ...rich,
                        //}
                    }
                },
                yAxis: [{
                    type: 'value',
                    
                },
                {
                    type: 'value',
                    position: 'right',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#556677'
                        },
                        formatter: '{value}'
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#DCE2E8'
                        }
                    },
                    splitLine: {
                        show: false
                    }
                    
                }],
                series: [
                    {
                        data: barData,
                        type: 'bar',
                        showBackground: true,
                        stack: 'stack',
                        backgroundStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    {
                        data: lineData,
                        type: 'line',
                        symbol:'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        showBackground: true,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]   
            }
        },

    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;
            default:
                break;
            }
        },
    },
    async mounted(){
        await this.index()
        await this.graficasConFechas()
    },
    methods:{
        verMas(tipo){
            this.$refs.modalProgressGris.toggle(tipo)
        },
        async index(){
            try {
                const { data } = await estadisticas.index();
                ///img/no-imagen/default.jpg
                this.deudasSinLiquidar = data.deudasSinLiquidar
                this.liquidacionesSinVerificacion = data.liquidacionesSinVerificacion
                this.cupoOtorgado = data.cupoOtorgado
                this.solicitudes = data.solicitudes

            } catch (error){
                this.error_catch(error)
            }
        },
        async graficasConFechas(){
            try {
                const params = {
                    fechas: this.fecha
                }
                const { data } = await estadisticas.graficasConFechas(params);
                this.comprasConvenio = data.comprasConvenio
                this.calificacionPedidos = data.calificacionPedidos
                console.log({data});
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style scoped>


</style>
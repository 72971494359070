<template>
    <section v-if="!_.isEmpty(producto.producto)" class="pt-4">
        <div class="custom-scroll overflow-auto px-4" style="height:calc(100vh - 263px)">
            <div class="row mx-0">
                <img class="border cr-pointer styles-cropper" :src="producto.producto.imagen" @click="abrirModalCambiarImagen" />
                <div class="col pt-3">
                    <p class="f-14 text-general f-500">Creado:</p>
                    <p class="f-14 text-general">{{ producto.producto.created_by || 'No registra' }}</p>
                    <p class="f-14 text-general">{{ formatearFecha(producto.producto.created_at,'DD MMM YYYY h:m a') }} </p>
                    <p class="f-14 text-general f-500">Actualizado</p>
                    <p class="f-14 text-general">{{ producto.producto.updated_by || 'No registra' }}</p> 
                    <p class="f-14 text-general">{{ formatearFecha(producto.producto.updated_at,'DD MMM YYYY h:m a') }}</p>
                </div>
            </div>
            <div class="row mx-0 py-3" />
            <ValidationObserver ref="form">
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <p class="label-mini">
                            Nombre
                        </p>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:60" name="nombre">
                            <el-input v-model="producto.producto.nombre" placeholder="Nombre del premio" class="w-100" size="small" />
                            <span class="text-danger f-12">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <p class="label-mini">
                            Descripción
                        </p>
                        <ValidationProvider v-slot="{ errors }" rules="required|max:350" name="descripción">
                            <el-input v-model="producto.producto.descripcion" type="textarea" :rows="5" placeholder="Descripción" class="w-100" size="small" />
                            <span class="text-danger f-12">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <p class="label-mini">
                            Categoría
                        </p>
                        <el-select v-model="producto.producto.id_categoria" placeholder="Seleccionar" class="w-100" size="small">
                            <ValidationProvider v-slot="{ errors }" rules="required" name="categoría">
                                <el-option
                                v-for="(cat,index) in producto.categorias"
                                :key="index"
                                :label="cat.nombre"
                                :value="cat.id"
                                />
                                <span class="text-danger f-12">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </el-select>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="row mx-0 py-2 border-top justify-center">
            <div class="btn-action border">
                <i class="icon-delete-outline f-20" @click="abrirModalEliminarProducto" />
            </div>
            <div class="col-5">
                <div class="btn-general f-14" @click="actualizarProducto">
                    Guardar
                </div>
            </div>
        </div>
        <modal ref="modalCambiarImagen" titulo="Imagen del premio" adicional="Actualizar" :cargado-modal="cargando" no-aceptar @adicional="actualizarImagen">
            <div class="row justify-center mx-0">
                <slim-cropper ref="fotoProducto" :options="slimOptions" class="border cr-pointer styles-cropper" />
            </div>
        </modal>
        <modal-eliminar ref="modalEliminarProducto" titulo="Eliminar premio" mensaje="¿Desea eliminar este premio?" @eliminar="eliminarProducto" />
    </section>
    <section v-else class="col pr-0 px-0 mx-0 my-auto h-90">
        <div class="row mx-0 justify-center align-content-center h-100 f-60">
            <div class="col-5 text-center">
                <img src="/img/no-imagen/canasta.svg" style="width: 115px; height: 115px;" />
                <p class="mb-0 text-muted2 mt-2 f-17 f-600">Selecciona un producto del panel de la izquierda.</p>
            </div> 
        </div>
    </section>
</template>

<script>
import GamingProductos from '~/services/gaming/gamingProductos'
export default {
    components: {
        modalEliminar: () => import('@/components/modal/eliminar.vue')
    },
    props: {
        producto: {
            type: Object,
            default: ()=>({})
        }
    },
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            descripcion: '',
            nombre: '',
            categoria: null,
            cargando: false,
        }
    },
    methods: {
        abrirModalEliminarProducto(){
            this.$refs.modalEliminarProducto.toggle()
        },
        abrirModalCambiarImagen(){
            if(this.producto.producto.imagen != "/img/no-imagen/mercado.svg"){

                this.$refs.fotoProducto.instanciaCrop.load(this.producto.producto.imagen)

            }else {

                this.$refs.fotoProducto.instanciaCrop.remove()
            }

            this.$refs.modalCambiarImagen.toggle()
        },

        async actualizarImagen(){
            try {
                this.cargando = true

                let params = {
                    foto: this.$refs.fotoProducto.instanciaCrop.dataBase64.output.image,
                    id_gaming_producto: this.producto.producto.id
                }
                const { data } = await GamingProductos.cambiarImagenGamingProducto(params)

                this.notificacion("","Imagen del premio actualizada correctamente","success")
                this.$emit('update',this.producto.producto.id,true)
                this.$refs.modalCambiarImagen.toggle()
            } catch (error){
                this.error_catch(error)
            } finally {
                this.cargando = false
            }
        },

        async actualizarProducto(){
            try {
                let validate = await this.$refs.form.validate()

                if(!validate) return this.notificacion("","Campos incompletos","warning")

                let params = {
                    id_gaming_producto: this.producto.producto.id,
                    nombre: this.producto.producto.nombre,
                    descripcion: this.producto.producto.descripcion,
                    id_categoria: this.producto.producto.id_categoria
                }

                const { data } = await GamingProductos.editarGamingProducto(params)

                this.notificacion("","Premio actualizado correctamente","success")
                this.$emit('update',this.producto.producto.id,true)

            } catch (e){
                this.error_catch(e)
            }
        },

        async eliminarProducto(){
            try {
                const { data } = await GamingProductos.eliminarGamingProducto(this.producto.producto.id)

                this.$emit('recargar')
                this.$refs.modalEliminarProducto.toggle()
                this.notificacion('','Premio eliminado correctamente','success')
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.styles-cropper{
    height:162px;
    width:162px;
    border-radius:6px;
    background-color:#F5F5F5;
}
.label-mini{
    font-size: 12px;
    color: #000000;
    padding-left: 12px;
}
</style>
<template>
    <section>
        <p class="text-general f-400 mb-4   ">
            Define la cantidad de monedas y gemas que recibirá cada usuario por el valor de cada compra propia o de sus referidos nivel 1° y nivel 2°
        </p>
        
        <div class="row mx-0 border-bottom my-4">
            <div class="col-auto cr-pointer p-2" :class="vista_activa == 1 ? 'border-b-black text-general' : 'text-general2'" @click="vista_activa = 1">
                {{$config.cliente + 's'}}
            </div>
            <div class="col-auto cr-pointer p-2" :class="vista_activa == 2 ? 'border-b-black text-general' : 'text-general2'" @click="vista_activa = 2">
                {{$config.vendedor + 's'}}
            </div>
        </div>
        <div v-for="(cedi1,index1) in datos" v-show="vista_activa==1" :key="`${index1}+1`" class="row mx-0 mb-4">
            <div class="col-4 px-0 f-15 text-general f-500 pt-4">
                {{ cedi1.nombre }}
            </div>
            <div class="col px-0">
                <div v-for="(recom,ind1) in cedi1.recompensas " :key="ind1" class="row mx-0 mb-3 align-items-end">
                    <div class="px-0 pb-1 text-general2 f-500" style="width:100px;">
                        {{ tipoRecompensa(recom.tipo) }}
                    </div>
                    <div class="col-auto text-general pb-1">
                        <img src="/img/gaming/i_moneda.svg" />
                    </div>
                    <div class="col">
                        <p class="label-mini">
                            Monedas
                        </p>
                        <el-input v-model="recom.cliente_monedas" placeholder="Cantidad" class="w-100" size="small" @change="actualizarRecompensa(cedi1.id,recom.cliente_monedas,1,recom.tipo)" />
                    </div>
                    <div class="col-auto text-general pb-1">
                        <img src="/img/gaming/i_gema.svg" />
                    </div>
                    <div class="col">
                        <p class="label-mini">
                            Gemas
                        </p>
                        <el-input v-model="recom.cliente_gemas" placeholder="Cantidad" class="w-100" size="small" @change="actualizarRecompensa(cedi1.id,recom.cliente_gemas,2,recom.tipo)" />
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(cedi2,index2) in datos" v-show="vista_activa==2" :key="`${index2}+2`" class="row mx-0 mb-4">
            <div class="col-4 px-0 f-15 text-general f-500 pt-4">
                {{ cedi2.nombre }}
            </div>
            <div class="col px-0">
                <div v-for="(recom,ind2) in cedi2.recompensas " :key="ind2" class="row mx-0 mb-3 align-items-end">
                    <div class="px-0 pb-1 text-general2 f-500" style="width:100px;">
                        {{ tipoRecompensa(recom.tipo) }}
                    </div>
                    <div class="col-auto text-general pb-1">
                        <img src="/img/gaming/i_moneda.svg" />
                    </div>
                    <div class="col">
                        <p class="label-mini">
                            Monedas
                        </p>
                        <el-input v-model="recom.tienda_monedas" placeholder="Cantidad" class="w-100" size="small" @change="actualizarRecompensa(cedi2.id,recom.tienda_monedas,3,recom.tipo)" />
                    </div>
                    <div class="col-auto text-general pb-1">
                        <img src="/img/gaming/i_gema.svg" />
                    </div>
                    <div class="col">
                        <p class="label-mini">
                            Gemas
                        </p>
                        <el-input v-model="recom.tienda_gemas" placeholder="Cantidad" class="w-100" size="small" @change="actualizarRecompensa(cedi2.id,recom.tienda_gemas,4,recom.tipo)" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import GamingRecompensas from '~/services/gaming/gamingrecompensas'

export default {
    data(){
        return {
            datos: [
                {   cedi2: '',
                    recompensas:{
                        cliente_monedas:0,
                        cliente_gemas:0,
                        tiendas_monedas:0,
                        tiendas_gemas:0
                    }},
                {   cedi1: '',
                    recompensas:{
                        cliente_monedas:0,
                        cliente_gemas:0,
                        tiendas_monedas:0,
                        tiendas_gemas:0
                    }},
            ],
            vista_activa: 1,
        }
    },
    mounted(){
        this.listarCedisRecompensas()
    },
    methods:{
        async listarCedisRecompensas(){
            try {
                const { data } = await GamingRecompensas.listarCedisRecompensasReferidos()
                this.datos = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarRecompensa(idCedis,valor,campo,tipo){
            try {
                if(valor>=65535) return this.notificacion("","El valor debe ser igual o menor a 65.535")
                let variable
                if(campo == 1) variable = "cliente_monedas"
                if(campo == 2) variable = "cliente_gemas"
                if(campo == 3) variable = "tienda_monedas"
                if(campo == 4) variable = "tienda_gemas"
                let params = {
                    id_cedis: idCedis,
                    valor: valor,
                    campo: variable,
                    tipo: tipo
                }
                const { data } = await GamingRecompensas.actualizarRecompensa(params)
                this.notificacion("","Recompensa actualizada","success")
                params = null
            } catch (e){
                this.error_catch(e)
            }
        },
        tipoRecompensa(tipo){
            switch (tipo){
            case 32:
                return "Propia"
            case 33:
                return "Referido 1°"
            case 34:
                return "Referido 2°"
            default:
                break;
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.label-mini{
    font-size: 12px;
    padding-left: 15px;
    color: #000000;
}
</style>
<template>
    <reclamos-lista-admin-pedidos :vista="modulo" />
</template>

<script>

export default {
    data(){
        return{
            modulo: 2
        }
    },
}
</script>


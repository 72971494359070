<template>
    <section class="pt-3">
        <div class="row mx-0 bg-white br-t-12">
            <cargando v-if="cargando" />
            <div class="col-12 py-3 d-middle">
                <div class="col-auto text-general f-18 f-500 mr-auto">
                    Liquidaciones
                </div>
                <div class="pill-pendientes border">
                    <span class="text-gris2 ml-2 f-18">
                        Pendientes:
                    </span>
                    <div class="mn-pill bg-general3 text-white mx-2">
                        {{ pendientes.total }}
                    </div>
                    <span class="text-general f-18">
                        {{ separadorNumeroDecimales(pendientes.valor) }}
                    </span>
                </div>
                <div class="pill-pendientes border ml-3">
                    <span class="text-gris2 ml-2 f-18">
                        En revisión:
                    </span>
                    <div class="mn-pill bg-general3 text-white mx-2">
                        {{ enRevision.total }}
                    </div>
                    <span class="text-general f-18">
                        {{ separadorNumeroDecimales(enRevision.valor) }}
                    </span>
                </div>
            </div>
            <div class="col-12 px-0">
                <el-table
                :data="liquidaciones"
                style="width: 100%;"
                height="calc(100vh - 230px)"
                header-row-class-name="text-general f-500 f-17 text-center"
                @row-click="verLiquidacion"
                >
                    <el-table-column
                    class-name="text-center"
                    width="80"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0 justify-center">
                                <el-tooltip v-if="scope.row.estado === 3" content="Urgente" placement="bottom" effect="light">
                                    <i class="icon-attention-alt f-17 text-general-red" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="id"
                    label="No."
                    class-name="text-center"
                    width="80"
                    />
                    <el-table-column
                    prop="estado"
                    label="Estado"
                    class-name="text-center"
                    width="180"
                    >
                        <template slot-scope="scope"> 
                            <div class="row mx-0 justify-center">
                                <div class="br-20 f-14" :class="determinarColorEstado(scope.row.estado)">
                                    {{ determinarEstado(scope.row.estado) }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="convenio"
                    label="Empresa"
                    class-name="text-center"
                    min-width="150"
                    />
                    <el-table-column
                    prop="fecha_corte"
                    label="Fecha de corte"
                    class-name="text-center"
                    min-width="180"
                    >
                        <template slot-scope="scope"> 
                            <div class="row mx-0 justify-center">
                                <div class="br-20 f-14">
                                    {{ scope.row.fecha_corte | helper-fecha('DD MMM YYYY') }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="fecha_cobro"
                    label="Fecha de cobro"
                    class-name="text-center"
                    min-width="180"
                    >
                        <template slot-scope="scope"> 
                            <div class="row mx-0 justify-center">
                                <div class="br-20 f-14">
                                    {{ scope.row.fecha_cobro | helper-fecha('DD MMM YYYY') }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="total_usuarios"
                    label="Usuarios"
                    min-width="150"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="total_pedidos"
                    label="Pedidos"
                    min-width="150"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="valor_pedidos"
                    label="Valor"
                    class-name="text-center"
                    min-width="180"
                    >
                        <template slot-scope="scope"> 
                            <div class="row mx-0 justify-center">
                                <div class="br-20 f-14">
                                    {{ separadorNumeroDecimales(scope.row.valor_pedidos) }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="fecha_liquidacion"
                    label="Fecha de liquidación"
                    class-name="text-center"
                    min-width="220"
                    >
                        <template slot-scope="scope"> 
                            <div class="row mx-0 justify-center">
                                <div class="br-20 f-14">
                                    {{ scope.row.fecha_liquidacion | helper-fecha('DD MMM YYYY') }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    min-width="100"
                    class-name="text-center"
                    >
                        <template slot="header">
                            <i class="icon-truck-check f-20 text-green" />
                        </template>
                        <template slot-scope="scope">
                            {{ scope.row.porcentaje_entrega }}%
                        </template>
                    </el-table-column>
                    <el-table-column
                    min-width="100"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <div class="bg-green text-white f-14 px-2 br-20 d-middle-center" style="height:31px;min-width:50px;">
                                <i class="icon-message-text-outline f-18" />
                                <span class="ml-2">{{ scope.row.total_comentarios }}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="col-12">
                <div v-if="totalLiquidaciones > liquidaciones.length" class="row mx-0 justify-center bg-white py-3 br-b-12">
                    <div class="br-20 d-middle-center text-white bg-general3 f-14 px-3 cr-pointer" style="height:28px" @click="mostrarMas(10)">
                        Ver más 10
                    </div>
                    <div class="br-20 d-middle-center text-white bg-general3 f-14 px-3 cr-pointer ml-2" style="height:28px" @click="mostrarMas(50)">
                        Ver más 50
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-filtro ref="modalFiltro" />
    </section>
</template>

<script>
import Service from '~/services/nomina/liquidaciones'
import moment from 'moment'
export default {
    components: {
        modalFiltro: () => import('./partials/modalFiltro')
    },
    data(){
        return {
            cargando: false,
            liquidaciones: [],
            pendientes: {},
            enRevision: {},
            totalLiquidaciones: 0,
            mostrarDesde: 0,
            limite: 20,
        }
    },
    mounted(){
        this.getLiquidaciones();
    },
    methods: {
        async getLiquidaciones(){
            try {
                this.cargando = true;
                let params = {
                    limite: this.limite,
                    mostrarDesde: this.liquidaciones.length
                };
                const {data} = await Service.getLiquidaciones(params)
                this.liquidaciones = data.liquidaciones;
                this.totalLiquidaciones = data.total_liquidaciones;
                this.pendientes = data.tab_pendientes;
                this.enRevision = data.tab_en_revision;
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        async asignarMasLiquidaciones(){
            try {
                this.cargando = true;
                let params = {
                    limite: this.limite,
                    mostrarDesde: this.liquidaciones.length
                };
                const {data} = await Service.getLiquidaciones(params)
                if(data.liquidaciones.length < 1)return;
                // Actualización de liquidaciones
                this.totalLiquidaciones = data.total_liquidaciones;
                let cantidad = data.liquidaciones.length;
                for(let i = 0; i < cantidad; i++){
                    this.liquidaciones.push(data.liquidaciones[i]);
                }
                // Actualización de tabs
                this.pendientes.total = this.pendientes.total + data.tab_pendientes.total;
                this.pendientes.valor = this.pendientes.valor + data.tab_pendientes.valor;
                this.enRevision.total = this.enRevision.total + data.tab_en_revision.total;
                this.enRevision.valor = this.enRevision.valor + data.tab_en_revision.valor;
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        async mostrarMas(cantidad){
            this.limite = cantidad;
            this.asignarMasLiquidaciones();
        },
        determinarColorEstado(state){
            switch (state){
            case 1:
                return 'pill-pendiente';
            case 2:
                return 'pill-verificado';
            case 3:
                return 'pill-aplicado';
            }
        },
        determinarEstado(state){
            switch (state){
            case 1:
                return 'Pendiente';
            case 2:
                return 'Verificado';
            case 3:
                return 'En revisión';
            }
        },
        verLiquidacion(row){
            this.$router.push({name: 'admin.nomina.liquidacion', params: {id_convenio: row.id_convenio, id_liquidacion: row.id}});
        },
        abrirFiltro(){
            this.$refs.modalFiltro.toggle();
        }
    },
}
</script>
<style lang="scss" scoped>
.pill-pendiente{
    min-width: 90px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background-color: #000000;
}

.pill-verificado{
    min-width: 90px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background-color: #29D884;
}

.pill-aplicado{
    min-width: 90px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background-color: #FF5806;
}

.pill-pendientes{
    height: 36px;
  min-width: 23px;
  font-size: 14px;
  border-radius: 20px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
}
</style>
<template>
    <section class="px-4 pt-3 custom-scroll overflow-auto" style="height:calc(100vh - 63px)">
        <cargando v-if="cargando" />
        <ValidationObserver ref="validacion">
            <div class="row mx-0 align-items-center mb-3">
                <i class="icon-back f-20 text-general cr-pointer" @click="irAtras" />
                <div class="col-auto text-general f-18">
                    Crear empresa
                </div>
                <div class="btn-general f-14 px-5 ml-auto ml-auto" @click="postEmpresa">
                    Guardar
                </div>
            </div>
            <div class="row mx-0 bg-white br-8 py-4 px-3">
                <p class="col-12 text-general f-18 mb-3">
                    Datos de la empresa
                </p>
                <div class="col-auto px-4" />
                <div class="col-auto px-2">
                    <slim-cropper ref="imgEmpresa" :options="slimOptions" class="border cr-pointer br-12 slim-styles" />
                    <p class="text-gris2 mt-2 f-14 text-center">
                        Logo
                    </p>
                </div>
                <div class="col px-3">
                    <div class="row mx-0">
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Nombre de la empresa
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Nombre de la empresa">
                                <el-input v-model="nombre" class="w-100" size="small" maxlength="40" placeholder="Nombre" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Nit
                            </p>
                            <el-input v-model="nit" class="w-100" size="small" maxlength="15" placeholder="Nit" />
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Pais
                            </p>
                            <el-select v-model="idPais" placeholder="Seleccionar" class="w-100" size="small" :clearable="true" @clear="limpiarPais">
                                <el-option
                                v-for="item in paises"
                                :key="item.id"
                                :label="item.pais"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Ciudad
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Ciudad">
                                <el-select
                                v-model="idCiudad"
                                filterable
                                remote
                                :remote-method="getCiudades"
                                :loading="loading"
                                placeholder="Seleccionar"
                                class="w-100"
                                size="small"
                                :clearable="true"
                                @clear="limpiarCiudad"
                                >
                                    <el-option
                                    v-for="item in ciudades"
                                    :key="item.id"
                                    :label="item.ciudad + ' ('+item.estado+')'"
                                    :value="item.id"
                                    />
                                </el-select>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-4 bg-white br-8 py-4 px-3">
                <p class="col-12 text-general f-18 mb-3">
                    Asociación con {{$config.proyecto}}
                </p>
                <div class="col-auto px-3" />
                <div class="col">
                    <div class="row mx-0 mb-3">
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Código de afiliación de {{$config.cliente}}
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Código">
                                <el-input v-model="codigoAfiliacion" class="w-100" size="small" maxlength="15" placeholder="Código" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-6">
                            <p class="text-general pl-3 f-12">
                                Mensaje de bienvenida para las solicitudes de afiliación
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Mensaje">
                                <el-input v-model="mensajeBienvenida" type="textarea" :rows="3" class="w-100" size="small" maxlength="500" show-word-limit placeholder="Mensaje" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mb-3">
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                Cupo
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required" name="Cupo">
                                <el-input v-model="porcentajeCupo" class="w-100 soloNumeros" size="small" maxlength="3" placeholder="%" @focus="soloNumeros" @input="validarInputCupo" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                {{$config.vendedor}}
                            </p>
                            <el-select
                            v-model="leechero"
                            placeholder="Seleccionar"
                            filterable remote
                            :remote-method="filtrarLeecheros"
                            :loading="loading"
                            class="w-100 mb-3" size="small"
                            :clearable="true"
                            @clear="limpiarLeechero"
                            @change="seleccionarLeechero"
                            >
                                <el-option
                                v-for="item in leecheros"
                                :key="item.id"
                                :label="item.leechero +' - '+ item.cedi"
                                :value="item.id"
                                />
                            </el-select>
                            <div class="row mx-0 align-items-center">
                                <template v-if="leecherosSeleccionados.length > 0">
                                    <div v-for="(select,index) in leecherosSeleccionados" :key="index">
                                        <img :src="select.logo" width="46" height="46" class="obj-cover br-4" />
                                        <div class="col px-2">
                                            <p class="text-general f-15 f-500">
                                                {{ select.leechero }}
                                            </p>
                                            <p class="text-general f-14">
                                                {{ select.cedi }}
                                            </p>
                                        </div>
                                        <i class="icon-cancel f-20 text-general cr-pointer" @click="eliminarLeecheroSeleccionado(select.id)" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col px-2">
                                        <small>No ha seleccionado ningún {{$config.vendedor}}</small>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-4">
                            <p class="text-general pl-3 f-12">
                                {{$config.vendedor}} de desvinculación
                            </p>
                            <el-select v-model="leecheroDesv"
                                       filterable
                                       remote
                                       :remote-method="filtrarLeecherosDesv"
                                       :loading="loading"
                                       placeholder="Seleccionar"
                                       class="w-100 mb-3"
                                       size="small"
                                       :clearable="true"
                                       @clear="limpiarLeecheroDesv"
                                       @change="seleccionarLeecheroDesv"
                            >
                                <el-option
                                v-for="item in leecherosDesv"
                                :key="item.id"
                                :label="item.leechero +' - '+ item.cedi"
                                :value="item.id"
                                />
                            </el-select>
                            <div class="row mx-0 align-items-center">
                                <template v-if="leecherosSeleccionadosDesv.length > 0">
                                    <div v-for="(select,index) in leecherosSeleccionadosDesv" :key="index">
                                        <img :src="select.logo" width="46" height="46" class="obj-cover br-4" />
                                        <div class="col px-2">
                                            <p class="text-general f-15 f-500">
                                                {{ select.leechero }}
                                            </p>
                                            <p class="text-general f-14">
                                                {{ select.cedi }}
                                            </p>
                                        </div>
                                        <i class="icon-cancel f-20 text-general cr-pointer" @click="eliminarLeecheroSeleccionadoDesv(select.id)" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col px-2">
                                        <small>No ha seleccionado ningún {{$config.vendedor}} desvinculación</small>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-4 bg-white br-8 py-4 px-3">
                <p class="col-12 text-general f-18 mb-4">
                    Datos solicitados a los usuarios para afiliación
                </p>
                <div class="col-12">
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3" />
                        <div class="col-2 text-general f-16 text-center">
                            Obligatorio
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            Opcional
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            No solicitar
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3 text-general f-16">
                            Salario
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="salarioRequerido" class="radio-green" :label="2">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="salarioRequerido" class="radio-green" :label="1">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="salarioRequerido" class="radio-green" :label="0">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3 text-general f-16">
                            No. de documento identidad
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="documentoIdentidad" class="radio-green" :label="2">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="documentoIdentidad" class="radio-green" :label="1">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="documentoIdentidad" class="radio-green" :label="0">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3 text-general f-16">
                            Adjunto del soporte
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="adjuntoSoporte" class="radio-green" :label="2">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="adjuntoSoporte" class="radio-green" :label="1">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="adjuntoSoporte" class="radio-green" :label="0">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                    </div>
                </div>
                <div class="col-12 my-3 border" />
                <div class="col-12">
                    <div class="row mx-0 align-items-center mb-3">
                        <div class="col-3 text-general f-16">
                            Nombre del soporte solicitado
                        </div>
                        <div class="col-3">
                            <el-input v-model="nombreSoporte" class="w-100" size="small" placeholder="Nombre" />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-3 text-general f-16">
                            Tipo de archivo de soporte
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="tipoSoporte" class="radio-green position-relative" :label="1">
                                <span class="position-absolute text-general" style="top:3px" v-text="`Imagen`" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="tipoSoporte" class="radio-green position-relative" :label="2">
                                <span class="position-absolute text-general" style="top:3px" v-text="`PDF`" />
                            </el-radio>
                        </div>
                        <div class="col-2 text-general f-16 text-center">
                            <el-radio v-model="tipoSoporte" class="radio-green position-relative" :label="3">
                                <span class="position-absolute text-general" style="top:3px" v-text="`CUALQUIERA`" />
                            </el-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-4 bg-white br-8 py-4 px-3">
                <p class="col-12 text-general f-18 mb-4">
                    Beneficios del convenio
                </p>
                <div class="col-auto px-3" />
                <div class="col text-general px-0 f-16">
                    Defina el porcentaje de descuento por cada monto de compra que realicen los clientes
                </div>
                <div class="col-12 mb-3" />
                <div class="col-auto" />
                <div class="col d-middle pl-4">
                    <span class="text-general f-18 mr-2">Descuentos</span>
                    <el-tooltip placement="bottom" content="Crear descuento" effect="light">
                        <div class="btn-red f-14 cr-pointer" @click="pushDescuento">
                            <i class="icon-plus f-18" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-12 mb-4" />
                <div class="col-auto" />
                <div class="col pl-4">
                    <div v-for="(item,index) in descuentos" :key="index" class="row mx-0 align-items-center mt-1">
                        <div class="col-auto px-0 text-general f-18">
                            Desde
                        </div>
                        <div class="col-2">
                            <money v-model="item.desde" class="input-money w-100 soloNumeros" placeholder="Valor" size="small" v-bind="money" @focus="soloNumeros" />
                        </div>
                        <div class="col-auto px-0 text-general f-18 ml-3">
                            Descuento
                        </div>
                        <div class="col-2">
                            <el-input v-model="item.descuento" placeholder="%" class="w-100 soloNumeros" size="small" maxlength="2" @focus="soloNumeros" />
                        </div>
                        <el-tooltip placement="bottom" content="Eliminar" effect="light">
                            <div class="btn-action border cr-pointer" @click="deleteDescuento(index)">
                                <i class="icon-delete-trash f-18" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import Service from '~/services/nomina/nomina'
import {Money} from 'v-money'
export default {
    components:{Money},
    data(){
        return {
            cargando: false,
            loading: false,
            leecheros: [],
            leecherosDesv: [],
            leecherosSeleccionados: [],
            leecherosSeleccionadosDesv: [],
            paises: [],
            ciudades: [],
            descuentos: [
                {
                    'desde': '',
                    'descuento': ''
                }
            ],
            nombre: '',
            nit: '',
            leechero: '',
            leecheroDesv: '',
            idPais: '',
            idCiudad: '',
            salarioRequerido: 2,
            documentoIdentidad: 2,
            adjuntoSoporte: 2,
            nombreSoporte: '',
            tipoSoporte: 1,
            codigoAfiliacion: '',
            mensajeBienvenida: '',
            porcentajeCupo: '',
            slimOptions: {
                ratio: '1:1',
                label:'Subir logo',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
        }
    },

    created(){
        this.getPaises();
    },

    methods: {
        async getPaises(){
            try {
                const {data} = await Service.getPaises()
                this.paises = data.paises
            } catch (e){
                this.error_catch(e)
            }
        },
        async getCiudades(query){
            try {
                if (!this.idPais){
                    this.notificacion('Mensaje','Debe seleccionar un país', 'warning');
                    return this.ciudades = [];
                }
                if (query.length < 3) return this.ciudades = [];
                const params = {
                    buscador: query,
                };
                const {data} = await Service.getCiudades(this.idPais,params)
                this.ciudades = data.ciudades
            } catch (e){
                this.error_catch(e)
            }
        },
        async filtrarLeecheros(query){
            try {
                if (query.length < 3) return this.leecheros = [];
                const params = {
                    buscador: query,
                };
                const {data} = await Service.getLeecheros(params)
                this.leecheros = data.leecheros
            } catch (e){
                this.error_catch(e)
            }
        },
        async filtrarLeecherosDesv(query){
            try {
                if (query.length < 3) return this.leecherosDesv = [];
                const params = {
                    buscador: query,
                };
                const {data} = await Service.getLeecheros(params)
                this.leecherosDesv = data.leecheros
            } catch (e){
                this.error_catch(e)
            }
        },
        async postEmpresa(){
            try {
                // ---------------- Validaciones ----------------
                // Código
                let validCodigo = this.codigoAfiliacion.split(' ');
                if (validCodigo.length > 1) return this.notificacion('Mensaje', 'El código no puede tener espacios', 'warning');
                // General
                const valid = await this.$refs.validacion.validate()
                if (!valid) return
                // Descuentos
                let descuentosAceptados = [];
                this.cargando = true;
                if (this.descuentos.length > 0){
                    let desde = 0;
                    for(let i = 0; i < this.descuentos.length; i++){
                        if (this.descuentos[i].desde == 0 && this.descuentos[i].descuento === ''){
                            continue;
                        }
                        if (this.descuentos[i].desde != 0 && this.descuentos[i].descuento === ''){
                            this.cargando = false;
                            return this.notificacion('Beneficios del convenio', 'Información incompleta', 'warning');
                        }
                        if (this.descuentos[i].descuento != '' && this.descuentos[i].desde === 0){
                            this.cargando = false;
                            return this.notificacion('Beneficios del convenio', 'Información incompleta', 'warning');
                        }
                        if (parseInt(desde) === parseInt(this.descuentos[i].desde)){
                            this.cargando = false;
                            return this.notificacion('Beneficios del convenio', 'Valores "desde" repetidos', 'warning');
                        }
                        if (this.descuentos[i].descuento > 99){
                            this.cargando = false;
                            return this.notificacion('Beneficios del convenio', 'Descuentos no pueden ser mayores al 99%', 'warning');
                        }
                        desde = this.descuentos[i].desde;
                        descuentosAceptados.push(this.descuentos[i]);
                    }
                }
                // Cupo
                if (this.porcentajeCupo > 200){
                    this.cargando = false;
                    return this.notificacion('Alerta', 'El cupo no puede ser mayor a 200', 'warning');
                }
                // Vendedores
                if(this.leecherosSeleccionados.length < 1 || this.leecherosSeleccionadosDesv.length < 1){
                    this.cargando = false;
                    return this.notificacion('Alerta', 'Debe asignar al menos un vendedor en vinculación y desvinculación', 'warning');
                }
                // --------------------- Fin validaciones -----------------------
                const logo = this.$refs.imgEmpresa.instanciaCrop.dataBase64.output.image
                const form = {
                    estado: 1,
                    nombre: this.nombre,
                    nit: this.nit,
                    logo: logo,
                    idm_ciudad: this.idCiudad,
                    codigo: this.codigoAfiliacion,
                    mensaje: this.mensajeBienvenida,
                    porcentaje_cupo: this.porcentajeCupo,
                    leecheros: this.leecherosSeleccionados,
                    leecherosDesv: this.leecherosSeleccionadosDesv,
                    salario_requerido: this.salarioRequerido,
                    cedula_requerida: this.documentoIdentidad,
                    documento_requerido: this.adjuntoSoporte,
                    documento_label: this.nombreSoporte,
                    documento_tipo: this.tipoSoporte,
                    descuentos: descuentosAceptados
                };
                const {data} = await Service.postEmpresa(form)
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.limpiar();
                this.$router.push({name: 'admin.nomina.ver.empresa', params: {id_convenio: data.id_convenio }});
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        seleccionarLeechero(){
            if (!this.leechero) return
            let existe = this.leecherosSeleccionados.filter((item) => item.id === this.leechero);
            let existe2 = this.leecherosSeleccionadosDesv.filter((item) => item.id === this.leechero);
            if (existe.length > 0 || existe2.length > 0){
                this.leechero = '';
                this.leecheros = [];
                return;
            }
            let dataFilter = this.leecheros.find((item) => item.id === this.leechero);
            this.leecherosSeleccionados.push(dataFilter);
            this.leechero = '';
            this.leecheros = [];
        },
        seleccionarLeecheroDesv(){
            if (!this.leecheroDesv) return
            let existe = this.leecherosSeleccionados.filter((item) => item.id === this.leecheroDesv);
            let existe2 = this.leecherosSeleccionadosDesv.filter((item) => item.id === this.leecheroDesv);
            if (existe.length > 0 || existe2.length > 0){
                this.leecheroDesv = '';
                this.leecherosDesv = [];
                return;
            }
            let dataFilter = this.leecherosDesv.find((item) => item.id === this.leecheroDesv);
            this.leecherosSeleccionadosDesv.push(dataFilter);
            this.leecheroDesv = '';
            this.leecherosDesv = [];
        },
        eliminarLeecheroSeleccionado(idLeechero){
            let index = this.leecherosSeleccionados.findIndex(e => e.id === idLeechero)
            this.leecherosSeleccionados.splice(index, 1);
        },
        eliminarLeecheroSeleccionadoDesv(idLeechero){
            let index = this.leecherosSeleccionadosDesv.findIndex(e => e.id === idLeechero)
            this.leecherosSeleccionadosDesv.splice(index, 1);
        },
        limpiarPais(){
            this.idPais = '';
            this.idCiudad = '';
            this.ciudades = [];
        },
        limpiarCiudad(){
            this.idCiudad = '';
            this.ciudades = [];
        },
        limpiarLeechero(){
            this.leechero = '';
        },
        limpiarLeecheroDesv(){
            this.leecheroDesv = '';
        },
        limpiar(){
            this.nombre = '';
            this.nit = '';
            this.idPais = '';
            this.idCiudad = '';
            this.codigoAfiliacion = '';
            this.mensajeBienvenida = '';
            this.porcentajeCupo = '';
            this.leecherosSeleccionados = [];
            this.leecherosSeleccionadosDesv = [];
            this.leecheros = [];
            this.leecherosDesv = [];
            this.salarioRequerido = 2;
            this.documentoIdentidad = 2;
            this.adjuntoSoporte = 2;
            this.nombreSoporte = '';
            this.tipoSoporte = 1;
            this.descuentos = [{
                'desde': '',
                'descuento': ''
            }];
            this.$refs.validacion.reset();
            this.$refs.imgEmpresa.instanciaCrop.remove()
        },
        soloNumeros(){
            $('.soloNumeros').keypress(function(event){
                // Bloqueo de letras y caracteres especiales
                let char = String.fromCharCode(event.keyCode);
                let pattern = '[a-zA-Z|°!"#$%&/()=?¿¡{}]';
                if (char.match(pattern)){
                    return false;
                }
            });
        },
        validarInputCupo(){
            if (this.porcentajeCupo > 200){
                this.notificacion('Cupo no valido','El cupo no puede ser mayor a  200%','warning');
            }
        },
        pushDescuento(){
            let index = this.descuentos.length;
            if(this.descuentos.length < 1){
                this.descuentos.push({
                    'desde': 0,
                    'descuento': ''
                });
            } else if (this.descuentos[index-1].desde != 0 && this.descuentos[index-1].desde != ''){
                this.descuentos.push({
                    'desde': 0,
                    'descuento': ''
                });
            }
        },
        deleteDescuento(index){
            this.descuentos.splice(index, 1);
        },
        irAtras(){
            this.$router.push({name:'admin.nomina.empresas-asociadas'});
        },
    },
}
</script>

<style lang="scss" scoped>
.slim-styles{
    width:162px;
    height:162px;
    border-radius:6px;
    background-color:#F5F5F5;
}
</style>

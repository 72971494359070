<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <router-view />
    </section>
</template>

<script>

export default {
    computed: {
        rutas(){
            let rutas = [
                { titulo: 'Estadísticas' , ruta: 'tendero.metricas.estadisticas'},
            ]
            if(this.$tienda && this.$tienda.lider_team == 1 && this.$tienda.id_team){
                rutas.push({ titulo: 'Estadísticas del equipo' , ruta: 'tendero.metricas.estadisticas-equipo'})
            }
            return rutas
        }
    }
}
</script>

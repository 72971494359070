<template>
    <div style="height:calc(100vh - 81px);">
        <tabs :tabs="tabs" class="border-bottom" />
        <router-view />
    </div>
</template>

<script>
export default {
    middleware:['auth'],

    data(){
        return {
            tabs: [
                { titulo: 'Estadisticas', ruta: 'admin.clientes-estadisticas.estadisticas' },
                { titulo: 'Satisfacción', ruta: 'admin.clientes-estadisticas.satisfaccion', can:'tab1_estadisticas_clientes_satisfaccion' },
                { titulo: 'Calificaciones', ruta: 'admin.clientes-estadisticas.calificaciones', can:'tab1_estadisticas_clientes_calificaciones' },
                { titulo: 'Clientes', ruta: 'admin.clientes-estadisticas.clientes', can:'tab1_estadisticas_clientes_clientes' },
                { titulo: 'Referidos', ruta: 'admin.clientes-estadisticas.referidos', can:'tab1_estadisticas_clientes_clientes' },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
</style>

<template>
    <section class="h-100">
        <div class="row mx-0 px-5 py-3 border-bottom">
            <p class="mb-0 text-general f-600 f-18">
                Quiero contactar con la App
            </p>
        </div>
        <div style="height:calc(100vh - 155px);">
            <!-- :limitar-mensajes => si es falso limita los mensajes chat.admin = =2 limita -->
            <chat ref="contacto" :user="$usuario.tienda.id" :limitar-mensajes="$usuario.tienda.chat.admin !== 2" :tipo="3" />
        </div>
    </section>
</template>

<script>
export default {
    sockets:{
        activar_chat(value){
            const { tienda } = JSON.parse(value)
            if (this.$usuario.tienda.id == tienda.id){
                this.$store.dispatch('auth/consultarUsuario')
                console.log(this.$usuario);
            }
        },
        finalizar_chat(value){
            const { tienda } = JSON.parse(value)
            console.log(tienda);
            this.$refs.contacto.resetear()
        },
    },
    data(){
        return {
            id_chat: 0,
            limitar: true,
        }
    },
}
</script>

<style lang="css" scoped>
</style>


import axios from 'axios'

const API = "nomina/liquidadores"

const ENDPOINTS = {
    getLiquidadores(id){
        return axios.get(`${API}/${id}/listado`)
    },
    postLiquidador(form){
        return axios.post(`${API}/crear`, form)
    },
    deleteLiquidador(id){
        return axios.delete(`${API}/${id}/eliminar`)
    },
    putEstadoLiquidador(id, form){
        return axios.put(`${API}/${id}/estado`,form)
    },
    puLiquidador(id, form){
        return axios.put(`${API}/${id}/actualizar`,form)
    }
}
export default ENDPOINTS

import axios from 'axios'


const Inventarios = {
    getInfoGeneral(){
        return axios(`inventario/lecheros`)
    },
    getCategoriaGeneral(idCategoria){
        return axios(`inventario/lecheros/categoria/${idCategoria}/get-productos`)
    },
    getCategoriaLechero(idCategoria, idLechero){
        return axios(`inventario/lechero/${idLechero}/categoria/${idCategoria}/get-productos`)
    },
    getInfoXLechero(idLechero){
        return axios(`inventario/lechero/${idLechero}`)
    },
    getHistorialProducto(idProducto, idLechero){
        return axios(`inventario/lechero/${idLechero}/producto/${idProducto}/historial`)
    },
    buscarProductos(params, idLechero){
        return axios(`inventario/lechero/${idLechero}/busqueda-productos`,{params})
    },
    gestionarInventario(idLechero, form){
        return axios.post(`inventario/lechero/${idLechero}/gestionar`,form)
    },

}

export default Inventarios

<template>
    <section class="pt-3 position-relative">
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <div class="panel-folders bg-white br-t-8">
                <div class="row mx-0 border-bottom py-2 pr-2">
                    <div class="col">
                        <el-input v-model="buscador" placeholder="Buscar evento" class="w-100 br-20" size="small" />
                    </div>
                    <el-tooltip placement="bottom" content="Nueva Carpeta" effect="light">
                        <div class="btn-red text-white" @click="crearCarpeta">
                            <i class="icon-folder-plus f-20" />
                        </div>
                    </el-tooltip>
                </div>
                <template v-if="carpetas.length > 0">
                    <div v-for="(carpeta,index) in carpetas.filter(carpeta => !buscador || JSON.stringify(carpeta).toLowerCase().includes(buscador.toLowerCase()))" :key="index" class="row mx-0 selection-row cr-pointer border-bottom" @click="seleccionarCarpeta(carpeta.id)">
                        <div :class="idActive == carpeta.id ? 'bg-general' : ''" class="bar-option ml-2 br-20 " />
                        <div class="col pl-2 f-500" :class="idActive == carpeta.id ? 'text-general' : 'text-general2'">
                            {{ carpeta.nombre }}
                        </div>
                        <i v-if="idActive === carpeta.id && eventosEnFecha > 0" class="icon-ok-circled-outline text-green f-20 mr-2" />
                        <div class="pill d-middle-center px-1">
                            {{ carpeta.eventos_totales }}       
                        </div>
                        <i class="icon-angle-right" />
                    </div>
                </template>
                <template v-else>
                    <div class="row mx-0 selection-row cr-pointer">
                        <div class="col text-center">
                            <small class="text-general2">
                                Sin registros
                            </small>
                        </div>
                    </div>
                </template>
            </div>
            <div class="ml-4 panel-table br-t-8">
                <div class="row mx-0 align-items-center py-2 pr-2" style="height:49px;">
                    <div class="col-auto ml-3 text-general f-16 f-500">
                        Clientes nuevos
                    </div>
                    <el-tooltip placement="bottom" content="Eliminar Carpeta" effect="light">
                        <div class="btn-action border ml-auto" @click="confirmarEliminarCarpeta">
                            <i class="icon-delete-outline f-20" />
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Cambiar Nombre" effect="light">
                        <div class="btn-action border ml-2" @click="irActualizarCarpeta">
                            <i class="icon-pencil-outline f-20" />
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" effect="light">
                        <div slot="content">
                            Nuevo evento <br /> de recompensa
                        </div>
                        <div class="btn-red ml-2" @click="irCrearEvento">
                            <i class="icon-plus f-18" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="row mx-0">
                    <div v-if="eventos.length > 0" class="col-12 px-0">
                        <el-table
                        :data="eventos"
                        header-row-class-name="text-general f-16"
                        style="width: 100%"
                        @row-click="irDetalle"
                        >
                            <el-table-column
                            class-name="text-center"
                            width="50"
                            >
                                <template slot-scope="scope">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto px-0">
                                            <el-tooltip v-if="scope.row.estado === 1" content="Activo" placement="bottom" effect="light">
                                                <i v-if="scope.row.fecha_inicio <= fechaHoy && scope.row.fecha_fin >= fechaHoy" class="icon-ok-circled-outline text-green f-20" />
                                            </el-tooltip>
                                            <el-tooltip v-if="scope.row.estado === 0" content="Inactivo" placement="bottom" effect="light">
                                                <i class="icon-lock text-general text-green f-20" />
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Evento"
                            class-name="text-center"
                            min-width="150"
                            >
                                <template slot-scope="scope">
                                    <div class="cr-pointer">
                                        {{ scope.row.nombre }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Usuario"
                            class-name="text-center"
                            width="180"
                            >
                                <template slot-scope="scope">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto px-0">
                                            {{ scope.row.usuario === 1 ? $config.cliente : $config.vendedor }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Tipo"
                            class-name="text-center"
                            width="180"
                            >
                                <template slot-scope="scope">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto px-0">
                                            {{ tipoEvento(scope.row.tipo) }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Fecha Inicio"
                            class-name="text-center"
                            min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto px-0">
                                            {{ scope.row.fecha_inicio | helper-fecha('D MMM YYYY') }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Fecha Fin"
                            class-name="text-center"
                            min-width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto px-0">
                                            {{ scope.row.fecha_fin | helper-fecha('D MMM YYYY') }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="ganadores"
                            min-width="120"
                            class-name="text-center"
                            label="Ganadores"
                            />
                            <el-table-column
                            prop="cedis"
                            min-width="120"
                            class-name="text-center"
                            label="Cedis"
                            />
                            <el-table-column
                            prop="monedas"
                            min-width="120"
                            class-name="text-center"
                            label="Monedas"
                            />
                            <el-table-column
                            prop="gemas"
                            min-width="120"
                            class-name="text-center"
                            label="Gemas"
                            />
                        </el-table>
                    </div>
                    <div v-else>
                        <div class="mt-3 ml-5">
                            <small class="text-general2">
                                Actualmente, esta carpeta no tiene eventos creados.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalNuevaCarpeta" icon="carpeta_nueva" titulo="Crear carpeta de cupones" no-aceptar adicional="Guardar" @adicional="postCarpeta">
            <ValidationObserver ref="validacionCrearCarpeta">
                <div class="row mx-0 justify-center">
                    <div class="col-8">
                        <p class="text-general f-12 pl-3">
                            Nombre
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="Nombre">
                            <el-input v-model="nombreCarpeta" class="w-100 br-12" placeholder="Clientes nuevos" maxlength="50" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal ref="modalActualizarCarpeta" icon="carpeta_nueva" titulo="Editar carpeta de cupones" no-aceptar adicional="Editar" @adicional="putCarpeta">
            <ValidationObserver ref="validacionCarpeta">
                <div class="row mx-0 justify-center">
                    <div class="col-8">
                        <p class="text-general f-12 pl-3">
                            Nombre
                        </p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="Nombre">
                            <el-input v-model="nombreCarpeta" class="w-100 br-12" maxlength="50" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal ref="modalEliminarCarpeta" icon="trash" titulo="Eliminar Carpeta" no-aceptar adicional="Eliminar" @adicional="eliminarCarpeta">
            <div class="row mx-0 justify-center">
                <div class="col-9">
                    ¿Desea eliminar la carpeta de cupones?
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Service from "~/services/gaming/eventos/eventos";
import moment from 'moment';

export default {
    data(){
        return{
            cargando: false,
            carpetas: [],
            eventos: [],
            idActive: 0,
            indexIdActive: '',
            eventosEnFecha: 0,
            nombreCarpeta: '',
            fechaHoy: '',
            buscador: '',
        }
    },
    created(){
        this.getCarpetas();
    },
    methods: {
        async getCarpetas(){
            try {
                const {data} = await Service.getCarpetas()
                this.carpetas = data.carpetas;
                if (this.carpetas.length > 0){
                    this.idActive = this.carpetas[0].id;
                    this.getEventos(this.idActive);
                }
            } catch(e){
                this.error_catch(e)
            }
        },
        async postCarpeta(){
            try {
                const valid = await this.$refs.validacionCrearCarpeta.validate()
                if(!valid)return
                this.cargando = true;
                const form = {
                    nombre: this.nombreCarpeta
                };
                const {data} = await Service.postCarpeta(form)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.carpetas = data.carpetas;
                this.limpiarCarpeta();
            } catch (e){
                this.cargando = false;
                this.error_catch(e)
            }
        },
        seleccionarCarpeta(id){
            if(id === this.idActive)return
            this.idActive = id;
            this.cargando = true;
            this.eventosEnFecha = 0;
            this.getEventos();
        },
        async getEventos(){
            try {
                const {data} = await Service.getEventos(this.idActive)
                this.eventos = data.eventos;
                this.fechaHoy = data.fechaHoy;
                this.eventosEnFecha = data.eventosEnFecha;
                this.cargando = false;
            } catch (e){
                this.cargando = false;
                this.error_catch(e)
            }
        },
        confirmarEliminarCarpeta(){
            this.$refs.modalEliminarCarpeta.toggle();
        },
        async eliminarCarpeta(){
            try {
                if (this.eventos.length > 0)return this.notificacion('¡No es posible!','La carpeta que intenta eliminar contiene eventos asociados','warning')
                const {data} = await Service.deleteCarpeta(this.idActive);
                this.notificacion(data.titulo, data.mensaje, data.tipo);
                if (data.exito === true){
                    this.carpetas = data.carpetas;
                    if (this.carpetas.length > 0){
                        this.idActive = "";
                        this.idActive = this.carpetas[0].id;
                        this.getEventos(this.idActive);
                    }
                }
                this.$refs.modalEliminarCarpeta.toggle();
            } catch(e){
                this.error_catch(e)
            }
        },
        irActualizarCarpeta(){
            this.nombreCarpeta = "";
            if(this.idActive === 0 || this.idActive === '')return
            this.indexIdActive = this.carpetas.findIndex(e => e.id === this.idActive)
            this.nombreCarpeta = this.carpetas[this.indexIdActive].nombre;
            this.$refs.modalActualizarCarpeta.toggle();
        },
        async putCarpeta(){
            try {
                const valid = await this.$refs.validacionCarpeta.validate()
                if(!valid)return
                this.cargando = true;
                const form = {
                    nombre: this.nombreCarpeta
                };
                const {data} = await Service.putCarpeta(this.idActive, form);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.carpetas[this.indexIdActive].nombre = this.nombreCarpeta;
                this.limpiarCarpetaUpdate();
            } catch(e){
                this.error_catch(e)
            }
        },
        limpiarCarpeta(){
            this.nombreCarpeta = "";
            this.$refs.validacionCrearCarpeta.reset();
            this.$refs.modalNuevaCarpeta.toggle();
            this.cargando = false;
        },
        limpiarCarpetaUpdate(){
            this.nombreCarpeta = "";
            this.indexIdActive = "";
            this.$refs.validacionCarpeta.reset();
            this.$refs.modalActualizarCarpeta.toggle();
            this.cargando = false;
        },
        crearCarpeta(){
            this.nombreCarpeta = "";
            this.$refs.modalNuevaCarpeta.toggle();
        },
        irCrearEvento(){
            this.$router.push({name: 'admin.gaming.crear-evento', params:{ id_carpeta: this.idActive }});
        },
        tipoEvento(tipo){
            if(tipo === 11)return 'Compras Dinero'
            if(tipo === 12)return 'Compras Cantidad'
            if(tipo === 21)return 'Ventas Dinero'
            if(tipo === 22)return 'Ventas Cantidad'
        },
        irDetalle(row){
            this.$router.push({name: 'admin.gaming.ver-evento', params:{id_evento:row.id}})
        }
    }
}
</script>
<style lang="scss" scoped>
.panel-folders{
    width: 439px;
    height: calc(100vh - 144px);
    .selection-row{
        height: 50px;
        align-items: center;
        .bar-option{
            width: 8px;
            height: 30px;
            border-radius: 20px;
        }
        .pill{
            height: 24px;
            min-width: 33px;
            font-size: 14px;
            color: #FFFFFF;
            border-radius: 20px;
            background-color: #000000;
        }
    }
}
.panel-table{
    width: calc(100vw - 572px);
    background-color: #FFFFFF;
}
</style>
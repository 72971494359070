<template>
    <section class="position-relative">
        <cargando v-if="cargando" />
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col-8">
                    <div class="row mx-0 align-items-center">
                        <div class="btn-back" @click="irAtras">
                            <i class="icon-left f-12" />
                        </div>
                        <div class="col-auto text-general f-18">
                            Editar evento de recompensa
                        </div>
                        <div class="btn-general px-3 f-14 ml-auto" @click="validaciones">
                            Guardar
                        </div>
                    </div>
                    <div class="mt-3 pt-4 px-5 br-t-12 pb-3 bg-white custom-scroll overflow-auto" style="height:calc(100vh - 150px)">
                        <div class="row mx-0">
                            <div class="col-12 mb-3">
                                <p class="label-mini">
                                    Nombre del evento
                                </p>
                                <ValidationProvider v-slot="{errors}" rules="required" name="Nombre del evento">
                                    <el-input v-model="nombreEvento" size="small" class="w-100" placeholder="Nombre" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 mb-3">
                                <p class="label-mini">
                                    Descripción
                                </p>
                                <ValidationProvider v-slot="{errors}" rules="required" name="Descripción">
                                    <el-input v-model="descripcion" type="textarea" :rows="3" size="small" class="w-100" placeholder="Descripción del evento" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="row mx-0">
                                    <div class="col pl-0">
                                        <p class="label-mini">
                                            Seleccionar fecha de inicio y fin
                                        </p>
                                        <ValidationProvider v-slot="{errors}" rules="required" name="Fecha inicio y fin">
                                            <el-date-picker
                                            v-model="fechas"
                                            type="daterange"
                                            class="w-100"
                                            size="small"
                                            range-separator=""
                                            value-format="yyyy-MM-dd"
                                            value="yyyy-MM-dd"
                                            />
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                        <p class="label-mini mt-4">
                                            Mostrar evento de manera anticipada en días
                                        </p>
                                        <ValidationProvider v-slot="{errors}" rules="required" name="Anticipo">
                                            <el-input v-model="anticipo" class="w-100" size="small" />
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col">
                                        <p class="label-mini mb-3">
                                            Seleccionar imagen del banner
                                        </p>
                                        <slim-cropper 
                                        ref="bannerEvento" 
                                        :options="slimOptions" 
                                        class="border" 
                                        style="background:#F5F5F5;height:101px;width:100%;border-radius:4px;" 
                                        />
                                        <p class="text-general2 mt-1 f-12 text-right">
                                            Relación 3:1
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-5">
                                <div class="row mx-0 mb-2">
                                    <div class="col px-0">
                                        <p class="label-mini">
                                            Cedis
                                        </p>
                                        <el-select v-model="idCedi" class="w-100" size="small" @change="seleccionarCedi">
                                            <el-option
                                            v-for="cedi in cedis"
                                            :key="cedi.id"
                                            :label="cedi.nombre"
                                            :value="cedi.id"
                                            />
                                        </el-select>
                                    </div>
                                    <div class="col" />
                                </div>
                                <div class="row mx-0">
                                    <template v-if="cedisSeleccionados.length > 0">
                                        <div v-for="(cedi,index) in cedisSeleccionados" :key="index" class="tag-cedis px-2 f-14">
                                            {{ cedi.nombre }}
                                            <el-tooltip content="Eliminar cedis" placement="bottom" effect="light">
                                                <i class="icon-cancel f-14 ml-2 text-general cr-pointer" @click="removerCedi(cedi.id,index)" />
                                            </el-tooltip>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="px-2 f-14">
                                            <small class="text-general2">
                                                No hay cedis seleccionados
                                            </small>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="col-12 mb-4">
                                <div class="row mx-0 mb-4">
                                    <div class="col-3 text-general f-15">
                                        Usuarios
                                    </div>
                                    <div class="col">
                                        <div class="row mx-0 align-items-center mb-2">
                                            <input v-model="usuario" :value="2" type="radio" class="option-input radio mx-1" /> 
                                            <span class="text-general f-14">{{$config.vendedor}}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row mx-0 align-items-center mt-2">
                                            <input v-model="usuario" :value="1" type="radio" class="option-input radio mx-1 ml-3" @change="cambioTipoUsuario" /> 
                                            <span class="text-general f-14">{{$config.cliente}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-4">
                                <div class="row mx-0 mb-4">
                                    <div class="col-3 text-general f-15">
                                        Tipo de evento
                                    </div>
                                    <div class="col">
                                        <div class="row mx-0 align-items-center mb-2">
                                            <input v-model="tipo" :value="11" type="radio" class="option-input radio mx-1" />
                                            <span class="text-general f-14">Compras en dinero</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row mx-0 align-items-center mt-2">
                                            <input v-model="tipo" :value="12" type="radio" class="option-input radio mx-1 ml-3" />
                                            <span class="text-general f-14">Compras en pedidos</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="usuario != 1" class="row mx-0 mb-4">
                                    <div class="col-3 text-general f-15" />
                                    <div class="col">
                                        <div class="row mx-0 align-items-center mb-2">
                                            <input v-model="tipo" :value="21" type="radio" class="option-input radio mx-1" :disabled="usuario == 1" />
                                            <span class="text-general f-14">Ventas en dinero</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row mx-0 align-items-center mt-2">
                                            <input v-model="tipo" :value="22" type="radio" class="option-input radio mx-1 ml-3" :disabled="usuario == 1" /> 
                                            <span class="text-general f-14">Ventas en pedidos</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row mx-0 align-items-center mb-3">
                                    <p class="col-auto text-general f-18 f-500">
                                        Recompensas por metas
                                    </p>
                                    <el-tooltip placement="bottom" content="Añadir meta" effect="light">
                                        <div class="btn-red" @click="pushRecompensa">
                                            <i class="icon-plus f-18" />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div v-for="(data,index) in recompensas" :key="index" class="row mx-0 align-items-end mb-3">
                                    <div class="col">
                                        <div class="row mx-0 align-items-end">
                                            <span class="text-general f-15 pb-1">
                                                Más de
                                            </span>
                                            <div class="col">
                                                <p class="label-mini">
                                                    {{ tipo == 11 || tipo == 21 ? 'Valor de la compra' : 'Pedidos' }}
                                                </p>
                                                <template v-if="tipo == 11 || tipo == 21">
                                                    <money v-model="data.valor" size="small" class="input-money w-100" placeholder="Cantidad" v-bind="money" />
                                                </template>
                                                <template v-else>
                                                    <el-input v-model="data.valor" size="small" class="w-100 soloNumeros" placeholder="Cantidad" @focus="soloNumeros" />
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row mx-0 align-items-end">
                                            <img src="/img/gaming/i_moneda.svg" class="mb-1" height="20" />
                                            <div class="col">
                                                <p class="label-mini">
                                                    Monedas
                                                </p>
                                                <el-input v-model="data.moneda" size="small" class="w-100 soloNumeros" placeholder="Cantidad" @focus="soloNumeros" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row mx-0 align-items-end">
                                            <img src="/img/gaming/i_gema.svg" class="mb-1" height="20" />
                                            <div class="col">
                                                <p class="label-mini">
                                                    Gemas
                                                </p>
                                                <el-input v-model="data.gema" size="small" class="w-100 soloNumeros" placeholder="Cantidad" @focus="soloNumeros" />
                                            </div>
                                        </div>
                                    </div>
                                    <el-tooltip placement="bottom" content="Eliminar meta" effect="light">
                                        <div class="btn-action border" @click="removerRecompensa(data.id, index)">
                                            <i class="icon-delete-outline f-18" />
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import Service from "~/services/gaming/eventos/eventos";
import {Money} from 'v-money'

export default {
    components:{
        Money,
    },
    data(){
        return {
            cargando: false,
            cedis: [],
            cedisSeleccionados: [],
            tipo: 11,
            usuario: 2,
            anticipo: 0,
            nombreEvento: '',
            fechas: [],
            descripcion: '',
            idCedi: '',
            recompensas: [{
                'id': null,
                'valor': 0,
                'moneda': '',
                'gema': '',
            }],
            recompensasValidas: [],
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },

            slimOptions: {
                ratio: '3:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            value: '',
            
            valorCompra: '',
            moneda: '',
            gema: ''
        }
    },
    computed: {
        id_evento(){
            return this.$route.params.id_evento
        },
    },
    created(){
        this.cargando = true;
        this.getEvento();
    },
    methods: {
        async getEvento(){
            try {
                const {data} = await Service.getEventoEditar(this.id_evento)
                this.cedis = data.cedis;
                this.tipo =  data.evento.tipo;
                this.usuario =  data.evento.usuario;
                this.anticipo =  data.evento.dias_anticipo;
                this.nombreEvento = data.evento.nombre;
                this.descripcion = data.evento.descripcion;
                this.fechas.push(data.evento.fecha_inicio);
                this.fechas.push(data.evento.fecha_fin);
                this.cedisSeleccionados = data.evento.cedis;
                if (data.evento.recompensas.length > 0){
                    this.recompensas = data.evento.recompensas;
                }
                if (data.evento.banner !== null){
                    this.$refs.bannerEvento.set_image(data.evento.bannerUrl)
                } else {
                    this.$refs.bannerEvento.instanciaCrop.remove()
                }
                this.cargando = false;
            } catch(e){
                this.cargando = false;
                this.error_catch(e)
            }
        },
        async validaciones(){
            // Validación Id Carpeta
            if(!this.id_evento)return
            // Validación General
            const valid = await this.$refs.validacion.validate()
            if(!valid)return
            // Validación cedis
            if(this.cedisSeleccionados.length < 1)return this.notificacion('Alerta', 'Debe seleccionar al menos un cedis', 'warning')
            // Validación recompensas
            if(this.recompensas.length < 1)return this.postEvento();
            let validacion = 0;
            this.recompensas.forEach(element => {
                if (element.valor != 0 || element.moneda != '' || element.gema != ''){
                    if (element.valor === 0 || element.moneda === '' || element.gema === ''){
                        this.notificacion('Alerta', 'Recompensas por metas: información incompleta', 'warning')
                        return validacion = 1;
                    }
                    if (element.valor != 0 && element.moneda != '' && element.gema != ''){
                        this.recompensasValidas.push(element);
                    }
                }
            });
            if(validacion === 1)return
            // Todo ok
            this.putEvento();
        },

        async putEvento(){
            try {
                if(this.anticipo === ""){this.anticipo = 0;}
                // Asignamos el valor del archivo
                let archivo = this.$refs.bannerEvento.instanciaCrop.dataBase64.output
                this.banner = archivo.image
                this.cargando = true;
                const form = {
                    tipo: this.tipo,
                    usuario: this.usuario,
                    nombre: this.nombreEvento,
                    descripcion: this.descripcion,
                    fecha_inicio: this.fechas[0],
                    fecha_fin: this.fechas[1],
                    dias_anticipo: this.anticipo,
                    id_carpeta: this.id_carpeta,
                    foto: this.banner,
                    cedis: this.cedisSeleccionados,
                    recompensas: this.recompensasValidas
                };
                const {data} = await Service.putEvento(this.id_evento, form)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                if (data.exito === true){
                    this.$router.push({name: 'admin.gaming.ver-evento', params:{id_evento: this.id_evento}})
                }
                this.limpiar();
                this.cargando = false;
            } catch(e){
                this.cargando = false;
                this.error_catch(e)
            }
        },

        seleccionarCedi(){
            let existe = this.cedisSeleccionados.filter((item) => item.id === this.idCedi);
            if (existe.length > 0){
                return this.idCedi = "";
            }
            let data = this.cedis.find((item) => item.id === this.idCedi);
            this.cedisSeleccionados.push({
                'id': null,
                'id_cedis': data.id,
                'nombre': data.nombre,
            });
            this.idCedi = "";
        },

        async removerCedi(id, index){
            if (id === null) return this.cedisSeleccionados.splice(index,1);
            try {
                const {data} = await Service.deleteCediEvento(id)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                if (data.exito === true){
                    this.cedisSeleccionados.splice(index,1);
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        pushRecompensa(){
            this.recompensas.push({
                'id': null,
                'valor': 0,
                'moneda': '',
                'gema': '',
            });
        },

        async removerRecompensa(id, index){
            if (id === null) return this.recompensas.splice(index,1);
            try {
                const {data} = await Service.deleteRecompensaEvento(id)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                if (data.exito === true){
                    this.recompensas.splice(index,1);
                }
            } catch(e){
                this.error_catch(e)
            }
        },
        cambioTipoUsuario(){
            if(this.usuario == 1){
                if(this.tipo != 11 && this.tipo != 12){
                    if(this.tipo == 22){
                        return this.tipo = 12;
                    }
                    this.tipo = 11;
                }
            }
        },
        soloNumeros(){
            $('.soloNumeros').keypress(function(event){
                // Bloqueo de letras y caracteres especiales
                let char = String.fromCharCode(event.keyCode);
                let pattern = '[a-zA-Z|°!"#$%&/()=?¿¡]';
                if (char.match(pattern)){
                    return false;
                }
            });
        },
        limpiar(){
            this.tipo = 11;
            this.usuario = 2;
            this.nombreEvento = "";
            this.descripcion = "";
            this.fechas = [];
            this.anticipo = 0;
            this.banner = "";
            this.cedisSeleccionados = [];
            this.recompensas = [
                {
                    'id': null,
                    'valor': 0,
                    'moneda': '',
                    'gema': '',
                }
            ];
            this.recompensasValidas = [];
            this.$refs.validacion.reset();
            this.$refs.bannerEvento.instanciaCrop.remove();
        },
        irAtras(){
            this.$router.push({name: 'admin.gaming.ver-evento', params:{id_evento:this.id_evento}})
        },
    },
}
</script>
<style lang="scss" scoped>
.label-mini{
    font-size: 12px;
    padding-left: 15px;
    color: #000000;
}
.tag-cedis{
    height: 26px;
    border-radius: 6px;
    color: #707070;
    background-color: #F6F9FB;
    display: flex;
    align-items: center;
    border: 1px solid #DBDBDB;
}
.btn-back{
    border-radius: 50%;
    background-color: #000000;
    color: #FFFFFF;
    cursor: pointer !important;
    width: 20px !important;
    height: 20px !important;
}
</style>
<template>
    <section>
        <div class="row mx-0 py-3 px-5 justify-content-lg-center border-bottom align-items-center">
            <div class="col-xl col-lg-12 col-md-12">
                <p class="mb-0 text-general text-xl-left text-lg-center text-md-center text-sm-center f-18 f-600">
                    Consultas más frecuentes {{ textosDestinos.actual }}
                </p>
            </div>
            <div class="col-xl-4 col-lg-8 col-md-12 px-0 ml-xl-auto ml-lg-0 ml-md-0 ml-sm-0 buscar-preguntas">
                <el-input v-model="textoBusqueda" placeholder="Buscar pregunta" class="w-100" />
            </div>
            <!-- <p v-if="visibilidadCompleta" class="mb-0 ml-auto f-15 cr-pointer" style="color:#0892DD;" @click="intentarCambiarDestino">
                Ver los términos y condiciones {{ textosDestinos.cambiar }}
            </p> -->
        </div>
        <div v-if="!_.isEmpty(faqsFiltradas)" class="overflow-auto custom-scroll" style="height: calc(100vh - 169px)">
            <div v-for="(f, i) in faqsFiltradas" v-show="isTrue(f)" :key="i">
                <div class="row mx-4 mt-3 pt-3">
                    <p class="mb-0 text-muted2 f-600" v-text="f.titulo" />
                </div>
                <div v-for="(p, ix) in f.preguntas" v-show="p.estado" :key="`pre-${ix}`" class="my-2">
                    <div class="row mx-4 p-0 border bg-whitesmoke2 cr-pointer" style="border-radius:12px;" @click="colapsar(p)">
                        <p class="mb-0 f-600 mx-4 py-2" v-text="p.titulo" />
                        <i class="ml-auto m-2" :class="colocarIconoColapsado(p.colapsado)" />
                    </div>
                    <div v-show="!p.colapsado" class="row m-3 px-3">
                        <p style="white-space: break-spaces;" v-text="p.texto" />
                    </div>
                </div>
            </div>
        </div>
        <!-- No hay coincidencias -->
        <div v-else class="row mx-0 align-content-center justify-center overflow-auto custom-scroll content-consultas" style="">
            <div class="col-auto ">
                <p class="mb-0 text-muted2 f-17 f-600">
                    No hemos encontrado coincidencias con tu búsqueda
                </p>
                <div class="row mx-0 justify-center f-60">
                    <!-- Cambiar icono -->
                    <img src="/img/icons/product-1.svg" alt="" />
                    <!-- Cambiar icono -->
                </div>
            </div>
        </div>
        <!-- No hay coincidencias -->
    </section>
</template>
<script>
import faqsMixin from '~/mixins/faqs/gestion'

const TIPO = 1

export default {
    name: 'AyudaPreguntasFrecuentes',
    mixins: [ faqsMixin ],
    data(){
        return {
            textoBusqueda: ''
        }
    },
    computed: {
        faqsFiltradas(){
            return this.faqs.reduce((array, x) => {
                if (!_.isEmpty(this.textoBusqueda)){
                    const preguntasIncluidas = x.preguntas.filter(p =>
                        this.textoEsCoincidente(p.titulo)(this.textoBusqueda) ||
                        this.textoEsCoincidente(p.texto)(this.textoBusqueda)
                    )
                }

                if (_.isEmpty(this.textoBusqueda)
                    || (this.textoEsCoincidente(x.titulo)(this.textoBusqueda) || !_.isEmpty(preguntasIncluidas))
                ) array.push(x)

                return array
            }, [])
        }
    },
    mounted(){
        this.consultarFaqs(TIPO)
    },
    methods: {
        isTrue(array){
            let cont = 0
            for (var i = 0; i < array.preguntas.length; i++){
                if (array.preguntas[i].estado){
                    cont = cont +1
                }else{ cont = cont -1 }
            }
            if (cont < 0){
                return false
            }else { return true }
        }
    }
}
</script>
<style lang="scss">
.buscar-preguntas{
    .el-input{
        .el-input__inner{
            text-align: center;
            border-radius: 30px;
        }
    }
}

@media only screen  and (min-width : 1224px) {
    .content-consultas{
        height:calc(100vh - 140px) !important;
    }
}
@media only screen  and (max-width : 1224px) {
    .content-consultas{
        height:calc(100vh - 163px) !important;
    }
}
@media only screen and (min-width : 768px) and (max-width : 1024px) {
    .content-consultas{
        height:calc(100vh - 165px) !important;
    }
}
</style>

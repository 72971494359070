<template>
    <section>
        <p class="text-general f-400 mb-5">
            Define la cantidad de monedas y gemas que recibirá cada usuario, cada día que ingrese a la aplicación ya sea con una recompensa fija o a través de una ruleta de la suerte.
        </p>
        <div class="row mx-0">
            <i class="icon-cedis text-general" />
            <div class="col-4">
                <el-select v-model="cediSelected" size="small" class="w-100" @change="onChangeCedis()">
                    <el-option
                    v-for="cedi in cedis"
                    :key="cedi.id"
                    :label="cedi.nombre"
                    :value="cedi.id"
                    />
                </el-select>
            </div>
            <el-tooltip placement="bottom" effect="light">
                <div slot="content">
                    <p class="text-center">
                        Duplicar configuración <br /> a otro cedis
                    </p>
                </div>
                <div class="btn-action border" @click="duplicar()">
                    <i class="icon-content-duplicate f-17" />
                </div>
            </el-tooltip>
        </div>
        <div class="row mx-0 border-bottom my-4">
            <div class="col-auto cr-pointer p-2" :class="tipoUsuario == 1 ? 'border-b-black text-general' : 'text-general2'" @click="onChangeTipoUsuario(1)">
                {{$config.cliente}}
            </div>
            <div class="col-auto cr-pointer p-2" :class="tipoUsuario == 2 ? 'border-b-black text-general' : 'text-general2'" @click="onChangeTipoUsuario(2)">
                {{$config.vendedor}}
            </div>
        </div>
        <div class="row mx-0 align-items-center mb-5">
            <div class="col-auto px-3" />
            <div class="col-autof 18 text-general f-500">
                Días de los {{ tipoUsuario == 1 ? $config.cliente: $config.vendedor }}
            </div>
            <div class="btn-red ml-2">
                <i class="icon-plus f-18" @click="crearRecompensaDia()" />
            </div>
        </div>
        <draggable :list="datos" class="list-group" handle=".handle" @change="onDrag">
            <div v-for="(vis, v) in datos" :key="v" class="row mx-0">
                <div class="col-12 px-0 d-middle mb-3">
                    <i class="icon-drag handle f-20 text-muted" />
                    <p class="col-auto px-2 text-general f-15">
                        Día {{ vis.dia }}
                    </p>
                    <div class="col-auto cr-pointer" @click="vis.showDias = !vis.showDias">
                        <i class="f-17 text-general" :class="vis.showDias ? 'icon-angle-up' : 'icon-angle-down'" />
                    </div>
                    <div class="col-auto">
                        <el-tooltip placement="bottom" content="Eliminar día" effect="light">
                            <div class="btn-action border" @click="eliminarDia(vis)">
                                <i class="icon-delete-outline f-20" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <template v-if="vis.showDias">
                    <div class="col-auto px-3" />
                    <div class="col px-0">
                        <div class="row mx-0 align-items-end mb-3">
                            <div class="col-3 px-0 text-general f-15 pb-2">
                                Recompensa fija
                            </div>
                            <div class="col px-0">
                                <div class="row mx-0">
                                    <div class="col-2 px-0" />
                                    <div class="col-5">
                                        <div class="row mx-0 align-items-end">
                                            <div style="width:27px;">
                                                <img src="/img/gaming/i_moneda.svg" class="mb-1 mr-1" height="27" />
                                            </div>
                                            <div class="col px-0">
                                                <p class="label-mini">
                                                    Monedas
                                                </p>
                                                <ValidationProvider v-slot="{errors, valid}" rules="numeric|max_value:32767" name="monedas" slim>
                                                    <el-input v-model="vis.monedas" placeholder="Cantidad" size="small" class="w-100" @change="onChangeInput(vis, $event, valid, 'monedas')" />
                                                    <span class="text-danger f-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="row mx-0 align-items-end">
                                            <img :src="`/img/gaming/i_gema.svg`" class="mb-1 mr-1" height="27" />
                                            <div class="col px-0">
                                                <p class="label-mini">
                                                    Gemas
                                                </p>
                                                <ValidationProvider v-slot="{errors, valid}" rules="numeric|max_value:32767" name="gemas" slim>
                                                    <el-input v-model="vis.gemas" placeholder="Cantidad" size="small" class="w-100" @change="onChangeInput(vis, $event, valid, 'gemas')" />
                                                    <span class="text-danger f-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-for="(rec, r) in vis.recompensas" :key="r" class="row mx-0 align-items-end mb-3">
                            <div class="col-3 px-0 text-general f-15 pb-2">
                                {{ r == 0 ? 'Recompensa al azar (Ruleta)' : '' }}
                            </div>                                
                            <div class="col px-0">
                                <div class="row mx-0">
                                    <div class="col-2 px-0 d-flex justify-center align-items-end pb-2">
                                        Opción {{ rec.posicion }}
                                    </div>
                                    <div class="col-5">
                                        <div class="row mx-0 align-items-end">
                                            <div class="col px-0">
                                                <p class="label-mini">
                                                    Tipo
                                                </p>
                                                <el-select v-model="rec.tipo" class="w-100" size="small" @change="onChangeRecompensa(vis, rec)">
                                                    <el-option
                                                    v-for="item in tipos"
                                                    :key="item.tipo"
                                                    :label="item.nombre"
                                                    :value="item.tipo"
                                                    />
                                                </el-select>
                                            </div>
                                            <div v-if="rec.id" style="width:27px;">
                                                <i class="icon-cancel f-18 text-general cr-pointer" @click="eliminarRecompensaDia(vis, rec)" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="row mx-0 align-items-end">
                                            <img :src="`/img/gaming/${hasTable[rec.tipo].icon}.svg`" class="mb-1 mr-1" height="27" />
                                            <div class="col px-0">
                                                <p class="label-mini">
                                                    {{ hasTable[rec.tipo].nombre }}
                                                </p>
                                                <ValidationProvider v-if="rec.tipo != 4" v-slot="{errors, valid}" rules="numeric|max_value:8388607" name="cantidad" slim>
                                                    <el-input v-model="rec.cantidad" placeholder="Cantidad" size="small" class="w-100" @change="onChangeRecompensa(vis, rec, valid)" />
                                                    <span class="text-danger f-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                                <el-select v-else v-model="rec.id_gaming_producto" class="w-100" size="small" @change="onChangeRecompensa(vis, rec)">
                                                    <el-option
                                                    v-for="item in productos"
                                                    :key="item.id"
                                                    :label="item.nombre"
                                                    :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                            <el-tooltip v-if="showIcon(rec)" placement="bottom" content="Este producto no está disponible" effect="light">
                                                <i class="icon-alert-triangle f-16 mb-1 ml-1" />
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </draggable>
        <!-- Partials -->
        <modal-duplicar-parametros ref="duplicarParametros" :cedi="cediSelected" :tipo="tipoUsuario" />
    </section>
</template>

<script>
import visitasDiarias from '~/services/gaming/recompensas/visitasDiarias'

const hasTable = {
    1: { icon: 'i_moneda', nombre: 'Monedas' },
    2: { icon: 'i_gema', nombre: 'Gemas' },
    3: { icon: 'i_cupon', nombre: 'Cupón' },
    4: { icon: 'i_box', nombre: 'Producto' },
}

const recompensaModelDefault = {
    tipo: 1,
    cantidad:0,
    posicion: 1
}

const recompensasDefault = [...new Array(8)].map((el, index) => {
    const newModel = {...recompensaModelDefault}
    newModel.posicion = index + 1
    return newModel
})


export default {
    components: {
        modalDuplicarParametros: () => import('../partials/modalDuplicarParametros')
    },
    data(){
        return {
            moneda: '',
            gemas: '',
            tipoUsuario: 1,
            value: '',
            visitasDiarias: [
                {
                    dia: 1,
                    recompensas: [
                        {  titulo: 'Recompensas al azar (Ruleta)', tipo: 1 },
                        {  titulo: 'Recompensas al azar (Ruleta)', tipo: 2  }
                    ]
                }
            ],
            cedis: [],
            cediSelected: '',
            datos: [],
            productos: [],
            tipos: [
                { tipo: 1, nombre: 'Monedas' },
                { tipo: 2, nombre: 'Gemas' },
                { tipo: 3, nombre: 'Cupón' },
                { tipo: 4, nombre: 'Producto' },
            ],
            hasTable,
        }
    },
    async mounted(){
        await this.listarCedis()

    },
    methods:{
        iconosRight(state){
            switch (state){
            case 1:
                return 'i_gema';
            case 2:
                return 'i_moneda';
            case 3:
                return 'i_cupon';
            case 4:
                return 'i_box';
            }
        },
        duplicar(){
            if(!this.cediSelected) return this.notificacion('', 'Debes seleccionar un cedis', 'error')
            this.$refs.duplicarParametros.toggle();
        },
        async listarCedis(){
            try {
                const { data } = await visitasDiarias.listarCedis();
                this.cedis = data
            } catch (error){
                this.error_catch(error)
            }
        },
        onChangeTipoUsuario(tipo){
            this.tipoUsuario = tipo
            this.onChangeCedis()
        },
        async onChangeCedis(){
            try {
                if(!this.tipoUsuario || !this.cediSelected) return
                let params = {
                    tipo: this.tipoUsuario,
                    cedi: this.cediSelected
                }
                const { data } = await visitasDiarias.recompensasCedis(params);
                this.productos = data.productos
                
                this.datos = data.data.map(el => {
                    let newRecompensas = [...recompensasDefault]
                    if(!_.isEmpty(el.recompensas) && el.recompensas.length != 8){
                        el.recompensas.forEach(elm => {
                            const find = newRecompensas.findIndex(ele => ele.posicion == elm.posicion)
                            newRecompensas[find] = elm
                        })
                    } else if(!_.isEmpty(el.recompensas) && el.recompensas.length == 8){
                        newRecompensas = el.recompensas
                    }
                    el.recompensas = _.cloneDeep(newRecompensas)
                    el.showDias = false
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async onChangeInput(vis, event, valid, campo){
            try {
                if(!valid) return 

                const valor = Number(event)
    
                let params = {
                    campo,
                    valor,
                    id: vis.id
                }
    
                const { data } = await visitasDiarias.cambiarRecompensaFija(params)
                if (data.exito){
                    vis[campo] = valor
                    this.notificacion('', 'Guardado correctamente', 'success')
                }
                
            } catch (error){
                this.error_catch(error)
            }
        },
        async onChangeRecompensa(recom, ele, valid = true){
            try {
                if(!valid) return 
                const { id, tipo, cantidad, posicion, id_gaming_producto } = ele
                if(tipo != 4 && !cantidad) return 
                if(tipo == 4 && !id_gaming_producto) return 
                const { id: id_recompensa_diaria } = recom
                let params = { id, tipo, cantidad: Number(cantidad), posicion, id_recompensa_diaria, id_gaming_producto }
                const { data } = await visitasDiarias.cambiarRecompensaDia(params)
                if (data.exito){
                    this.$set(recom.recompensas, posicion - 1, _.cloneDeep(data.model))
                    this.notificacion('', 'Guardado correctamente', 'success')
                }
                
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarRecompensaDia(recom, ele){
            try {
                const { data } = await visitasDiarias.eliminarRecompensaDia(ele.id)
                if (data.exito){
                    let newModel = _.cloneDeep(recompensaModelDefault)
                    newModel.posicion = ele.posicion
                    this.$set(recom.recompensas, ele.posicion - 1, _.cloneDeep(newModel))
                    this.notificacion('', 'Eliminado correctamente', 'success')
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async crearRecompensaDia(){
            try {
                if(!this.cediSelected) return this.notificacion('', 'Debes seleccionar un cedis', 'error')
                let params = {
                    cedi: this.cediSelected,
                    tipo: this.tipoUsuario
                }
                const { data } = await visitasDiarias.crearRecompensaDia(params);
                if(data.exito){
                    this.notificacion('', 'Guardado correctamente', 'success')
                    await this.onChangeCedis()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarDia(ele){
            try {
                const { data } = await visitasDiarias.eliminarDia(ele.id)
                if (data.exito){
                    this.notificacion('', 'Eliminado correctamente', 'success')
                    await this.onChangeCedis()

                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async onDrag(datos){
            try {
                let obj = {
                    newIndex: datos.moved.newIndex + 1,
                    oldIndex: datos.moved.oldIndex + 1,
                    id: datos.moved.element.id
                }
                const { data } = await visitasDiarias.cambiarPosicion(obj)
                if(data.exito){
                    this.onChangeCedis()
                }
            } catch (error){
                
                this.error_catch(error)
            }

        },
        showIcon(ele){
            if(ele.tipo == 4){
                const producto = this.productos.find(el => el.id == ele.id_gaming_producto)
                if(!producto) return false
                return !producto.existe
            }
            return false
        }
    }
}
</script>
<style lang="scss" scoped>
.label-mini{
    font-size: 12px;
    padding-left: 15px;
    color: #000000;
}
</style>
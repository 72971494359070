<template>
    <section class="pt-4 position-relative">
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center">
            <div class="col-auto pl-0 text-general f-500 f-18">
                Lista de empresas afiliadas
            </div>
            <div class="br-20 d-middle-center f-500 bg-general3 text-white f-14" style="height:30px;min-width:58px;">
                {{ totalActivas }}
            </div>
            <div v-if="$can('boton_nomina_gestionar_empresa')" class="btn-general f-14 px-4 ml-auto" @click="$router.push({name: 'admin.nomina.empresas.crear'})">
                Crear Empresa
            </div>
            <div class="col-3">
                <el-input v-model="buscarEmpresa" placeholder="Buscar Empresa" class="w-100 br-20" size="small" @input="buscadore" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto mt-2 pt-4" style="height:calc(100vh - 169px)">
            <div class="row mx-0">
                <template v-if="totalActivas > 0">
                    <div v-for="(activa,index) in empresasActivas.filter(activa => !buscarEmpresa || activa.nombre.toLowerCase().includes(buscarEmpresa.toLowerCase()))" :key="index" class="card-empresas d-middle px-3 bg-white mr-3 mb-3 cr-pointer" @click="$router.push({name: 'admin.nomina.ver.empresa', params: {id_convenio: activa.id }})">
                        <img :src="activa.logo" class="obj-cover br-4" width="86" height="86" />
                        <div class="col pr-0">
                            <p class="text-general f-16">
                                {{ activa.nombre }}
                            </p>
                            <p class="text-gris2 f-14">
                                {{ activa.ciudad }}, {{ activa.pais }}
                            </p>
                            <div class="row mx-0 mt-2">
                                <div class="col-auto  px-0">
                                    <p class="text-gris2 f-12">
                                        Afiliados
                                    </p>
                                    <div class="d-middle text-gris2">
                                        <i class="icon-cliente" />
                                        <span class="f-600 ml-2">
                                            {{ activa.cantidad_afiliados }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-auto ml-3 px-0">
                                    <p class="text-gris2 f-12">
                                        Pedidos
                                    </p>
                                    <div class="d-middle text-gris2">
                                        <i class="icon-pedidos" />
                                        <span class="f-600 ml-2">
                                            {{ activa.cantidad_pedidos }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col ml-3 px-0">
                                    <p class="text-gris2 f-12">
                                        Deuda
                                    </p>
                                    <div class="d-flex text-gris2">
                                        <i class="icon-wallet" />
                                        <div class="col px-0 f-600 ml-1">
                                            {{ separadorNumero(activa.cantidad_deuda) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col">
                        <small class="text-general">
                            Sin resultados
                        </small>
                    </div>
                </template>
            </div>
            <div class="row mx-0 align-items-center mt-5 mb-3">
                <div class="col-auto pl-0 text-general f-500 f-18">
                    Lista de empresas inactivas
                </div>
                <div class="br-20 d-middle-center f-500 bg-general3 text-white f-14" style="height:30px;min-width:58px;">
                    {{ totalInactivas }}
                </div>
            </div>
            <div class="row mx-0">
                <template v-if="totalInactivas > 0">
                    <div v-for="(inactiva, index) in empresasInactivas.filter(inactiva => !buscarEmpresa || inactiva.nombre.toLowerCase().includes(buscarEmpresa.toLowerCase()))" :key="index" class="card-empresas d-middle px-3 bg-white mr-3 mb-3 cr-pointer" @click="$router.push({name: 'admin.nomina.ver.empresa', params: {id_convenio: inactiva.id }})">
                        <img :src="inactiva.logo" class="obj-cover br-4" width="86" height="86" />
                        <div class="col">
                            <p class="text-general f-16">
                                {{ inactiva.nombre }}
                            </p>
                            <p class="text-gris2 f-14">
                                {{ inactiva.ciudad }}, {{ inactiva.pais }}
                            </p>
                            <div class="row mx-0 mt-2">
                                <div class="col px-0">
                                    <p class="text-gris2 f-12">
                                        Afiliados
                                    </p>
                                    <div class="d-middle text-gris2">
                                        <i class="icon-cliente" />
                                        <span class="f-600 ml-2">
                                            {{ inactiva.cantidad_afiliados }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col px-0">
                                    <p class="text-gris2 f-12">
                                        Pedidos
                                    </p>
                                    <div class="d-middle text-gris2">
                                        <i class="icon-pedidos" />
                                        <span class="f-600 ml-2">
                                            {{ inactiva.cantidad_pedidos }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col px-0">
                                    <p class="text-gris2 f-12">
                                        Deuda
                                    </p>
                                    <div class="d-middle text-gris2">
                                        <i class="icon-wallet" />
                                        <span class="f-600 ml-2">
                                            {{ separadorNumero(inactiva.cantidad_deuda) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col">
                        <small class="text-general">
                            Sin resultados
                        </small>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import Service from '~/services/nomina/nomina'

export default {
    data(){
        return {
            cargando: false,
            empresasActivas: [],
            empresasInactivas: [],
            buscarEmpresa: '',
            totalActivas: 0,
            totalInactivas: 0,

        }
    },
    created(){
        this.cargando = true;
        this.getEmpresas();
        this.$store.commit('nomina/nomina/setConvenio', {})
    },
    methods: {
        async getEmpresas(){
            try {
                const {data} = await Service.getEmpresas()
                this.empresasActivas = data.activas;
                this.empresasInactivas = data.inactivas;
                this.totalActivas = this.empresasActivas.length;
                this.totalInactivas = this.empresasInactivas.length;
                this.cargando = false;
            } catch (e){
                this.error_catch(e)
            }
        },
        buscadore(){
            let tabActividad = this.empresasActivas.filter((item) => item == !this.buscarEmpresa ||  item.nombre.toLowerCase().includes(this.buscarEmpresa.toLowerCase()));
            let tabInactivas = this.empresasInactivas.filter((item) => item == !this.buscarEmpresa ||  item.nombre.toLowerCase().includes(this.buscarEmpresa.toLowerCase()));
            this.totalActivas = tabActividad.length;
            this.totalInactivas = tabInactivas.length;
        },
    },
}
</script>
<style lang="scss" scoped>
.card-empresas{
    box-shadow: 1px 2px 8px #6B707414;
    border-radius: 8px;
    width: 480px;
    height: 160px;
}
</style>

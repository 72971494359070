<template>
    <section style="height:100vh;">
        <div class="row mx-0 bg-general justify-content-between align-items-center px-5" style="height:80px;border-radius:0 0 0 20px;">
            <img class="ml-3" width="50" src="/img/modales/yourmarket_blanco.svg" alt="" />
            <p class="cr-pointer text-white">Cerrar sesión</p>
        </div>
        <div class="row mx-0" style="height:calc(100% - 80px);">
            <div class="col d-middle-center">
                <div class="text-center" style="max-width:350px;">
                    <img width="250" src="/img/ilustraciones/eliminada.svg" alt="" />
                    <div class="row mx-0 my-3 f-600 f-20 justify-center">
                        Tu tienda fue eliminada
                    </div>
                    <div class="row mx-0 f-18 my-3 justify-center">
                        Puedes volver a registrarte o contactarnos para cualquier inquietud
                    </div>
                </div>
                <div class="row mx-0 mt-auto justify-center position-absolute" style="bottom: 56px;">
                    <div type="button" class="bg-gr-general text-white p-2 br-10 px-5" name="button" @click="cancelarProstulacion">
                        Registrarme de nuevo
                    </div>
                </div>
            </div>
            <div class="h-100" style="width:28vw">
                <chat fondo />
            </div>
        </div>
        <modalCancelarPost ref="modalCancelarPost" />
    </section>
</template>

<script>
export default {
    components: {
        modalCancelarPost: () => import('./modalCancelarPost')
    },
    layout: 'login',
    methods: {
        cancelarProstulacion(){
            this.$refs.modalCancelarPost.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>

import axios from 'axios'

const API_PREFIX = 'faqs'

const ENDPOINTS = {
    consultarFaqs: params => axios(`${API_PREFIX}/listado-faqs`, { params }),
    crearFaq: payload => axios.post(`${API_PREFIX}/crear-faq`, payload),
    actualizarFaq: payload => axios.put(`${API_PREFIX}/actualizar-faq`, payload),
    eliminarFaq: payload => axios.delete(`${API_PREFIX}/${payload}/eliminar-faq`),
    actualizarPosicion: payload => axios.put(`${API_PREFIX}/actualizar-posicion`,payload),
    actualizarPosicionHijos: payload => axios.put(`${API_PREFIX}/actualizar-posicion-hijos`, payload)
}

export default ENDPOINTS
